import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    TextFilterProps
} from "@amzn/awsui-components-react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {
    AUDIT_COLUMN_DEFINITIONS,
    AUDIT_DEFAULT_PREFERENCES, auditPaginationLabels,
    EmptyState, getMatchesCountText
} from "src/components/bartAuditHistoryPage/table-config";
import {AuditHistory} from "src/components/bartAuditHistoryPage/index";

interface AuditTableProps {
    auditList: any[]
}

const HistoryTable = ({auditList}: AuditTableProps) => {
    const [allItems, setAllItems] = useState<AuditHistory[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [preferences, setPreferences] = useState(AUDIT_DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadItems = async () => {
        setLoading(true);
        setFetchError(null);
        try {
            let reverseArray = [...auditList].reverse()
            setAllItems(reverseArray as AuditHistory[]);
        } catch (error: unknown) {
            setFetchError(error instanceof Error ? error.message : String(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No history"
                        subtitle="No history to display."
                        action={null}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {},
            selection: {}
        }
    );

    return (
        <SpaceBetween size="m">
            <Table
                {...collectionProps}
                visibleColumns={preferences.visibleContent}
                wrapLines={preferences.wrapLines}
                loading={loading}
                loadingText="Loading history"
                stickyHeaderVerticalOffset={1}
                variant='full-page'
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        actions={null}
                    >
                        Audit History
                    </Header>
                }
                columnDefinitions={AUDIT_COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={auditPaginationLabels} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        ref={textFilterRef}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringPlaceholder="Search history"
                        filteringAriaLabel="Filter history"
                    />
                }
            />
        </SpaceBetween>
    );
}

export default HistoryTable;
