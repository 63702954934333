import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";

export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>Get Workflow Status Resource</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Get workflow status with external id and workflow type.</p>
            <ul>
                <li>
                    Please input the external id and workflow type.
                </li>
                <li>
                    The resulting status can be either SUCCESSFUL, ERRORED, or OPENED.
                </li>
            </ul>
        </HelpPanel>
    );
}
