import React, { forwardRef } from 'react';
import { Alert, AppLayout, AppLayoutProps, Badge, Box, Button, Link, SpaceBetween } from '@amzn/awsui-components-react';
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
export * from './index';

export function TableNoMatchState(props: { onClearFilter: () => void }) {
  return (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit">
        <SpaceBetween size="xxs">
          <div>
            <b>No matches</b>
            <Box variant="p" color="inherit">
              We can't find a match.
            </Box>
          </div>
          <Button onClick={props.onClearFilter}>Clear filter</Button>
        </SpaceBetween>
      </Box>
  );
}

export function TableEmptyState({ resourceName, resourceHref }: { resourceName: string; resourceHref?: string }) {
  return (
      <Box margin={{ vertical: 'xs' }} textAlign="center" color="inherit" data-testid="empty-state">
        <SpaceBetween size="xxs">
          <div>
            <b>No {resourceName}s</b>
            <Box variant="p" color="inherit">
              No {resourceName}s found
            </Box>
          </div>
          {resourceHref && <Button href={resourceHref}>Create {resourceName}</Button>}
        </SpaceBetween>
      </Box>
  );
}

export const CustomAppLayout = forwardRef<AppLayoutProps.Ref, AppLayoutProps>((props, ref) => (
    <AppLayout ref={ref} ariaLabels={appLayoutAriaLabels} {...props} />
));