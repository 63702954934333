import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import {
    Alert,
    Box,
    Button, CodeEditor,
    Container, DatePicker,
    Form,
    FormField, Grid,
    Header,
    Input, Modal, NonCancelableCustomEvent,
    RadioGroup,
    Select,
    SpaceBetween
} from '@amzn/awsui-components-react';
import "src/index.scss";
import {Fuseplan} from "src/model/fuse-plan";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Loader} from "src/components/updateFusePlanPage/loader";
import GetPlan from "src/components/updateFusePlanPage/get-plan";
import {InitDomain} from "src/context/domain/domainContext";
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import Collapsible from "src/pages/commons/collapsible";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {inputValidation} from "src/commons/input-validation";

interface PutRequestParameters {
    planIdKey: string;
    glLineDescriptionPartnerName: string;
    fusenEnabled: boolean;
    fusenEnableDate?: string;
    migrationTargetStage?: string;
}

export class AppPutPlan extends React.Component <{}, {
    ldap: string;
    id: string;
    fusenEnabled: boolean;
    storeFusenEnabled: boolean | null;
    glLineDescriptionPartnerName: string;
    storeGlLineDescriptionPartnerName: string;
    fusenEnableDate: string;
    storeFusenEnableDate?: string;
    migrationTargetStage: string;
    storeMigrationTargetStage?: string | null;
    keyword: string;
    searched: boolean,
    isModalOpen: boolean,
    reasoning: string;
    payload: any }> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            ldap: "",
            fusenEnabled: false,
            storeFusenEnabled: null,
            glLineDescriptionPartnerName: "",
            storeGlLineDescriptionPartnerName: "",
            fusenEnableDate: "",
            storeFusenEnableDate: "",
            migrationTargetStage: "",
            storeMigrationTargetStage: null,
            id: "",
            keyword: "",
            searched: false,
            isModalOpen: false,
            reasoning: "",
            payload: "init"
        };
        this.put = this.put.bind(this);
        this.setEnabledBooleanValue = this.setEnabledBooleanValue.bind(this);
        this.setFusenEnableDate = this.setFusenEnableDate.bind(this);
        this.setInputValue = this.setInputValue.bind(this);
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
        this.callback = this.callback.bind(this);
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    search(domain:Domain) {
        this.setState({
            searched: true,
            payload: "init"
        });
        const payload = {"methodName": "GET", "path": "/fuseplan/{planId}",
            "parameter": {"planIdKey":this.state.keyword}}
        return invokeBartArdLambda(payload, this.callback,domain)
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }
    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    callback (response: any) {
        if(JSON.parse(response).plan) {
            let planData: Fuseplan = JSON.parse(response).plan
            let dateWithMSeconds = planData.fusenEnableDate || "";
            this.setState({
                ...this.state,
                ...planData,
                fusenEnableDate: dateWithMSeconds,
                storeGlLineDescriptionPartnerName: planData.glLineDescriptionPartnerName,
                storeFusenEnabled: planData.fusenEnabled,
                storeFusenEnableDate: planData.fusenEnableDate,
                storeMigrationTargetStage: planData.migrationTargetStage,
                payload: planData
            });
        } else if (response == "\"invalid-fusePlan\"") {
            this.setState({
                ...this.state,
                payload: "invalid"
            });
        } else {
            this.setState({
                ...this.state,
                payload: "error"
            });
        }
    }

    setInputValue(event: NonCancelableCustomEvent<any>) {
        const name = 'event.';
        const value = 'event.currentTarget.value';
        this.setState({[name]: value} as any)
    }
    setStageValue(event: NonCancelableCustomEvent<any>) {
        this.setState({['migrationTargetStage']: event.detail.selectedOption.value} as any)
    }

    setPartnerValue(event: NonCancelableCustomEvent<any>) {
        this.setState({['glLineDescriptionPartnerName']: event.detail.value} as any)
    }

    setFusenEnable(event: NonCancelableCustomEvent<any>) {
        this.setState({['fusenEnabled']: event.detail.value === 'true'} as any)
    }

    setEnabledBooleanValue(event: React.ChangeEvent<HTMLInputElement>) {
        let booleanValue = false;
        if (event.currentTarget.value == "true") {
            booleanValue = true;
        }
        this.setState({fusenEnabled:booleanValue});
    }

    setFusenEnableDate(e: NonCancelableCustomEvent<any>) {
        const value = new Date(e.detail.value).toISOString();
        this.setState({fusenEnableDate: value})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        this.setState({['reasoning']: event.detail.value} as any)
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({ldap: this.authObj.ldap});
        }
        fetchData();
    }

    put(domain: Domain) {
        this.setCloseModal();
        this.setState({
            payload: "init"
        })
        const regex = /\.\d{3}Z$/; // match the last 3 digits after the dot and the trailing Z
        let formattedDate = this.state.fusenEnableDate;
        if(regex.test(formattedDate)){
            formattedDate = formattedDate.replace(regex, `.00Z`); // replace the last 3 digits with the last 2 digits
        }
        const parameters: PutRequestParameters = {
            "planIdKey":this.state.id,
            "glLineDescriptionPartnerName":this.state.glLineDescriptionPartnerName,
            "fusenEnabled":this.state.fusenEnabled,
            "migrationTargetStage": this.state.migrationTargetStage
        };
        if(formattedDate){
            parameters.fusenEnableDate = formattedDate;
        }
        const payload = {"methodName": "PUT", "path": "/fuseplan/{planId}", "parameter":
            parameters};
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "Update Plan",
                        additionalInfo: "Plan Id: " + this.state.id,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })
        return invokeBartArdLambda(payload, this.callback,domain)
    }

    render() {
        if (!(this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Update Plan'} description={'Update Plan info'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can update Fuse Plan Information!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        if (!this.state.searched) {
            //Calling get plan page first
            return (
                    <GetPlan keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search} />
            )
        }
        if (this.state.payload == "init") {
            return (
                <Loader />);
        }
        if(this.state.payload == "invalid"){
            return (
                <GetPlan error ="invalid" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search} />);
        }
        if(this.state.payload == "error"){
            return (
                    <GetPlan error ="error" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search} />);
        }

        const {id, fusenEnabled, glLineDescriptionPartnerName, migrationTargetStage, fusenEnableDate} = this.state
        //This is useful for showing results on confirmation page
        const checkDifference = this.state.storeFusenEnabled !== this.state.fusenEnabled ||
            this.state.storeGlLineDescriptionPartnerName !== this.state.glLineDescriptionPartnerName ||
            this.state.storeMigrationTargetStage !== this.state.migrationTargetStage ||
            this.state.storeFusenEnableDate?.replace(".000Z", "Z") !== this.state.fusenEnableDate.replace(".000Z", "Z")

        //Calling update page plan after searching for a specific plan Id on Get Plan page
        return (
            <InitDomain.Consumer>
                {context =>(
                    <>
                        <FullPageTitle title={'Update Plan'} description={'Update Plan info'}/>
                        <SpaceBetween size="l">
                        <form onSubmit={event => event.preventDefault()}>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant="primary"
                                                onClick={this.setOpenModal}
                                        >Update Plan</Button>
                                    </SpaceBetween>
                                }
                                errorIconAriaLabel="Error"
                            >
                                <SpaceBetween size="l">
                                    <Container
                                        id="origin-panel"
                                        className="custom-screenshot-hide"
                                        header={<Header variant="h2">Update Fuse Plan Info</Header>}
                                    >
                                        <SpaceBetween size="l">
                                            <FormField
                                                label="Plan Id"
                                                description="The Fuse Plan ID"
                                                i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                            >
                                                <Input
                                                    placeholder="Please enter plan Id"
                                                    ariaRequired={true}
                                                    value={id}
                                                    disabled={true}
                                                    onChange={()=>this.setInputValue}
                                                />
                                            </FormField>
                                            <FormField
                                                label="Partner Name"
                                                description="The GL Line Description Partner Name "
                                                i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                            >
                                                <Input
                                                    placeholder="Please enter partner name"
                                                    ariaRequired={true}
                                                    value={glLineDescriptionPartnerName}
                                                    onChange={(event)=>this.setPartnerValue(event)}
                                                />
                                            </FormField>
                                            <FormField
                                                label="Stage Name"
                                                description="Choose stage name"
                                                i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                            >
                                                <Select
                                                    data-testid="idType-filter"

                                                    options={[
                                                        {
                                                            "value": "DEVO"
                                                        },
                                                        {
                                                            "value": "UAT"
                                                        },
                                                        {
                                                            "value": "PROD"
                                                        }
                                                    ]}
                                                    selectedAriaLabel="Selected"
                                                    selectedOption={{
                                                        "value": migrationTargetStage || "DEVO"
                                                    }}
                                                    onChange={(e)=>this.setStageValue(e)}
                                                    ariaDescribedby={''}
                                                    expandToViewport={true}
                                                />
                                            </FormField>
                                            <FormField label="Fusen Enable" stretch={true}>
                                                <RadioGroup
                                                    items={[
                                                        { label: 'True', value: 'true' },
                                                        { label: 'False', value: 'false' },
                                                    ]}
                                                    value={fusenEnabled?'true':'false'}
                                                    onChange={(e)=>this.setFusenEnable(e)}
                                                    ariaRequired={true}
                                                />
                                            </FormField>
                                            <FormField stretch={true} label={<span id="certificate-expiry-label">Fusen Enable Date</span>}>
                                                <SpaceBetween size="s" direction="horizontal">
                                                    <FormField
                                                        stretch={true}
                                                        description="Specify fusen enabled date"
                                                        className="date-time-container"
                                                        constraintText={'Use YYYY/MM/DD format.'}
                                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                    >
                                                        <DatePicker
                                                            ariaLabelledby="certificate-expiry-label"
                                                            placeholder="YYYY/MM/DD"
                                                            previousMonthAriaLabel="Previous month"
                                                            nextMonthAriaLabel="Next month"
                                                            todayAriaLabel="Today"
                                                            value={fusenEnableDate}
                                                            onChange={(e) => this.setFusenEnableDate(e)}
                                                            openCalendarAriaLabel={selectedDate =>
                                                                'Choose fusen Enable date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                                                            }
                                                        />
                                                    </FormField>
                                                </SpaceBetween>
                                            </FormField>
                                            <FormField
                                                label="Update Reasoning"
                                                description="Why you are performing this update"
                                                i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                            >
                                                <Input
                                                    placeholder="Please enter your reason to perform a update"
                                                    ariaRequired={true}
                                                    value={this.state.reasoning}
                                                    onChange={(event)=>this.setReasoning(event)}
                                                />
                                            </FormField>
                                        </SpaceBetween>
                                    </Container>
                                </SpaceBetween>
                            </Form>
                            <Modal
                                visible={this.state.isModalOpen}
                                onDismiss={this.setCloseModal}
                                header={'Review changes'}
                                closeAriaLabel="Close dialog"
                                footer={
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="xs">
                                            {inputValidation(this.state.reasoning) || inputValidation(this.state.glLineDescriptionPartnerName) ?
                                                <Button variant="primary" onClick={this.setCloseModal}>Update Inputs</Button> :
                                                (<div>
                                                    <Button variant="link" onClick={this.setCloseModal}>
                                                    Cancel
                                                    </Button>
                                                    <Button variant="primary" onClick={() => this.put(context.domain)} data-testid="submit">
                                                    Update
                                                    </Button>
                                                </div>)
                                            }
                                        </SpaceBetween>
                                    </Box>
                                }
                            >
                                <SpaceBetween size="m">
                                {inputValidation(this.state.reasoning) || inputValidation(this.state.glLineDescriptionPartnerName) ?
                                    <Box variant="span">
                                        <Alert type="error" header="Invalid Input! Partner name and reasoning fields cannot be empty"></Alert>
                                    </Box>
                                    : <Box variant="span">
                                        Permanently delete distribution{' '}
                                        <Box variant="span" fontWeight="bold">
                                            <section>
                                                {this.state.storeFusenEnabled !== this.state.fusenEnabled && <p>
                                                    Fusen Enabled has been changed from {this.state.storeFusenEnabled? "true": "false"}&nbsp;
                                                    to &nbsp; {this.state.fusenEnabled? "true": "false"}
                                                </p>}</section>
                                            <section>
                                                {this.state.storeGlLineDescriptionPartnerName !== this.state.glLineDescriptionPartnerName && <p>
                                                    Description has been changed from {this.state.storeGlLineDescriptionPartnerName}&nbsp;
                                                    to {this.state.glLineDescriptionPartnerName}
                                                </p>}
                                            </section>
                                            <section>
                                                {this.state.storeFusenEnableDate?.replace(".000Z", "Z") !==
                                                    this.state.fusenEnableDate.replace(".000Z", "Z")

                                                    && <p>
                                                        Fusen Date has been changed as: {this.state.fusenEnableDate}
                                                    </p>}
                                            </section>
                                            <section>
                                                {this.state.storeMigrationTargetStage !== this.state.migrationTargetStage && <p>
                                                    Migration stage has been changed from {this.state.storeMigrationTargetStage}&nbsp;
                                                    to {this.state.migrationTargetStage}
                                                </p>}
                                            </section>
                                        </Box>
                                        <Alert statusIconAriaLabel="Info">
                                            You can’t undo this action.
                                        </Alert>
                                    </Box>
                                }
                                </SpaceBetween>
                            </Modal>
                        </form>
                        <Collapsible title={"Plan Info Preview"} open = {false}>
                            <pre className = "sourceInfo ">{JSON.stringify(this.state.payload, null, 2)}</pre>
                        </Collapsible>
                        </SpaceBetween>
                    </>
               )}

            </InitDomain.Consumer>

        );
    }
}

export default AppPutPlan;
