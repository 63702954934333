import React, {useContext} from 'react';
import {InitDomain} from "src/context/domain/domainContext";
import {useNavigate} from "react-router-dom";
import {Button, Input} from "@amzn/awsui-components-react";


interface Props {
    keyword: string;
    setValue: Function;
    onClickSearch: Function;
}

const ArdSearchBar = ({keyword, setValue, onClickSearch}: Props) => {
     const domainData = useContext(InitDomain);
     const navigate = useNavigate();
     const onClick = ()=> {
       const id = keyword.replace(/\./g,':');
       navigate(`/getard/${keyword}`);
        onClickSearch(domainData.domain);
      }
    return (
        <div style={{paddingLeft: '10px'}}>
          <div className="input-container">
            <div className="input-filter">
              <Input
                  data-testid="input-filter"
                  type="search"
                  value={keyword}
                  onChange={(e: any)=> {
                    if (e.detail!=null) {
                      setValue(e.detail.value);
                    }
                  }
                  }
                  placeholder="Please enter ARD ID"
                  clearAriaLabel="clear"
                  ariaDescribedby={''}
              />
            </div>
            <Button onClick = {onClick}>Get ARD</Button>
          </div>
        </div>
    );
}

export default ArdSearchBar;
