export const URLS = {
  MAIN_PAGE: '/transactionsearch',
  GET_PLAN: '/getplan',
  GET_ARD: '/getard',
  UPDATE_ARD: '/updateard',
  UPDATE_PLAN: '/updateplan',
  LIST_ALL_ARDs: '/viewallards',
  RAGA_AVAILABILITIES: '/ragaavailabilities',
  REDRIVE_WORKFLOW: '/redriveworkflow',
  BULK_REDRIVE_WORKFLOW: '/bulkredriveworkflow',
  BULK_REDRIVE_STATUS: '/bulkredrivestatus',
  BART_AUDIT_HISTORY: '/bartaudithistory',
  DLQ_MOVER: '/bartdlqmoverworkflow',
  GET_WORKFLOW_STATUS: '/getworkflowstatus',
  LINK_ARD_TO_A_PLAN: '/remappingardtoplan',
  ARD_NEW_PLAN_MAPPING: '/ardnewplanmapping',
};
