export const addToColumnDefinitions = (columnDefinitions: any, propertyName: any, columns: any) =>
    columnDefinitions.map((colDef: any) => {
        const column = (columns || []).find((col: any) => col.id === colDef.id);
        return {
            ...colDef,
            [propertyName]: (column && column[propertyName]) || colDef[propertyName],
        };
    });

export const mapWithColumnDefinitionIds = (columnDefinitions: any, propertyName: any, items: any) =>
    columnDefinitions.map(({ id }: any, i: any) => ({
        id,
        [propertyName]: items[i],
    }));
