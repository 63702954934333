import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import ManyArdSearchBar from '../fuseManyArdsPage/many-ard-search-bar';
import PayloadDisplayManyArd from "src/lambda/manyArdServiceLambda/payload-display-many-ard";
import {Alert, Grid, SpaceBetween} from '@amzn/awsui-components-react';
import "src/index.scss";
import {BaseArd} from "src/model/ard";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Domain} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";

export class AppManyArd extends React.Component <{}, { authenticating: boolean, isLoggedIn: boolean, show: boolean, keyword: string, searched: boolean, payload: any, ldap: string}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            authenticating: true,
            isLoggedIn: false,
            show: false,
            keyword: "",
            searched: false,
            payload: "init",
            ldap: "",
        };
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    search(domain:Domain) {
        this.setState({
            searched: true,
            payload: "init"
        });
        // let payload: string;
        const payload = {"methodName": "GET", "path": "/ard"}
        invokeBartArdLambda(payload, this.callback,domain)
    }

    callback (response: any) {
        let ardData: BaseArd = JSON.parse(response);
        this.setState({
            payload: ardData
        });
    }

    render() {
        if (!(this.state.ldap.includes("bart-read-only") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'View All ARDs Info'} description={'Check the detail of all ARD information'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can view all ARDs!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        return (
            <>
            <FullPageTitle title={'View All ARDs Info'} description={'Check the detail of all ARD information'}/>
            <SpaceBetween size = "l">
                        <ManyArdSearchBar setValue = {this.setValue} onClickSearch = {this.search}/>
                            {this.state.searched ?
                                <PayloadDisplayManyArd searchResults = {this.state.payload}/>:
                                null
                            }
                    </SpaceBetween>
            </>
        );
    }
}

export default AppManyArd;

