import React from 'react';
import AuthService from "src/auth/auth-service";
import {
    Alert, Box,
    Button,
    Container, DatePicker,
    Form, FormField,
    Grid, Header, Input, Modal,
    NonCancelableCustomEvent, RadioGroup, Select,
    SpaceBetween, Spinner
} from "@amzn/awsui-components-react";
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import {InitDomain} from "src/context/domain/domainContext";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {inputValidation} from "src/commons/input-validation";

interface PutRequestParameters {
    planIdKey: string;
    ardIdKey: string;
    glLineDescriptionPartnerName: string;
    fusenEnabled: boolean;
    fusenEnableDate?: string;
    migrationTargetStage?: string;
}

export class AppArdNewPlanMapping extends React.Component<{},{
    ldap: string;
    planId: string;
    ardId: string;
    fusenEnabled: boolean;
    glLineDescriptionPartnerName: string;
    fusenEnableDate: string;
    migrationTargetStage: string;
    isModalOpen: boolean;
    reasoning: string;
    payload: any;
    invalidInputs: boolean;
}> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            ldap: "",
            planId: "",
            ardId: "",
            fusenEnabled: false,
            glLineDescriptionPartnerName: "",
            fusenEnableDate: "",
            migrationTargetStage: "",
            isModalOpen: false,
            reasoning: "",
            payload: "init",
            invalidInputs: false,
        };
        this.setPlanId = this.setPlanId.bind(this);
        this.setArdId = this.setArdId.bind(this);
        this.setEnabledBooleanValue = this.setEnabledBooleanValue.bind(this);
        this.setGlLineDescriptionPartnerName= this.setGlLineDescriptionPartnerName.bind(this);
        this.setFusenEnabledDate = this.setFusenEnabledDate.bind(this);
        this.setMigrationTargetStage = this.setMigrationTargetStage.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
        this.setReasoning = this.setReasoning.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({
                ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setPlanId(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({planId: value})
    }

    setArdId(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({ardId: value})
    }

    setFusenEnable(event: NonCancelableCustomEvent<any>) {
        this.setState({['fusenEnabled']: event.detail.value === 'true'} as any)
    }

    setEnabledBooleanValue(event: React.ChangeEvent<HTMLInputElement>) {
        let booleanValue = false;
        if (event.currentTarget.value == "true") {
            booleanValue = true;
        }
        this.setState({fusenEnabled:booleanValue});
    }

    setGlLineDescriptionPartnerName(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({glLineDescriptionPartnerName: value})
    }

    setFusenEnabledDate(event: NonCancelableCustomEvent<any>) {
        const value = new Date(event.detail.value).toISOString();
        this.setState({fusenEnableDate: value})
    }

    setMigrationTargetStage(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({migrationTargetStage: value})
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }

    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({reasoning: value})
    }

    post(domain: Domain) {
        this.setState({
            invalidInputs: false,
            payload: "init",
        });
        if (inputValidation(this.state.planId) || inputValidation(this.state.ardId)
            || inputValidation(this.state.glLineDescriptionPartnerName) || inputValidation(this.state.fusenEnableDate)) {
            this.setState({
                invalidInputs: true,
            });
        } else if (inputValidation(this.state.reasoning)) {
            this.setOpenModal();
        } else {
            this.setState({
                payload: "post"
            })
            const regex = /\.\d{3}Z$/; // match the last 3 digits after the dot and the trailing Z
            let formattedDate = this.state.fusenEnableDate;
            if (regex.test(formattedDate)) {
                formattedDate = formattedDate.replace(regex, `.00Z`); // replace the last 3 digits with the last 2 digits
            }
            const parameters: PutRequestParameters = {
                "planIdKey": this.state.planId,
                "ardIdKey": this.state.ardId,
                "glLineDescriptionPartnerName": this.state.glLineDescriptionPartnerName,
                "fusenEnabled": this.state.fusenEnabled,
                "migrationTargetStage": this.state.migrationTargetStage
            };
            if (formattedDate) {
                parameters.fusenEnableDate = formattedDate;
            }
            const payload = {
                "methodName": "POST", "path": "/fuseplan", "parameter":
                parameters
            };
            Auth.currentCredentials().then(credentials => {
                const client = new DynamoDBClient({
                    credentials: Auth.essentialCredentials(credentials),
                    region: getBartLambdaConfig(domain).awsRegion
                });

                const docClient = DynamoDBDocumentClient.from(client);
                const moment = require("moment");
                const ddbPut = async () => {
                    const command = new PutCommand({
                        TableName: "BARTAuditHistoryTable",
                        Item: {
                            userId: this.authObj.userId,
                            requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                            operationType: "ARD New Plan Mapping",
                            additionalInfo: `Plan Id: ${this.state.planId}, ARD Id: ${this.state.ardId}`,
                            reasoning: this.state.reasoning,
                        }
                    });
                    await docClient.send(command);
                }
                ddbPut();
            })
            return invokeBartArdLambda(payload, this.callback, domain)
        }
    }

    callback(response: any) {
        if(JSON.parse(response).plan) {
            this.setState({
                ...this.state,
                payload: "successful"
            });
        } else if (response == "\"invalid-fusePlan\"") {
            this.setState({
                ...this.state,
                payload: "invalidFusePlan"
            })
        } else if (response == "\"invalid-ard\"") {
            this.setState({
                ...this.state,
                payload: "invalidArd"
            })
        } else if (response == "\"nonExistent-fusePlan\"") {
            this.setState({
                ...this.state,
                payload: "nonExistentFusePlan"
            })
        } else {
            this.setState({
                ...this.state,
                payload: "error"
            })
        }
    }

    render() {
        if (!(this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'ARD New Plan Mapping'} description={'Creates a new fuse plan with ARD mapping'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can perform ARD New Plan Mappings!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }

        return (
            <InitDomain.Consumer>
                {context =>(
                    <div>
                        <FullPageTitle title={'ARD New Plan Mapping'} description={'Creates a new fuse plan with ARD mapping'}/>
                        <SpaceBetween size="l">
                            <form onSubmit={event => event.preventDefault()}>
                                <Form
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button variant="primary"
                                                    onClick={() => this.post(context.domain)}>
                                                ARD New Plan Mapping
                                            </Button>
                                        </SpaceBetween>
                                    }
                                    errorIconAriaLabel="Error"
                                >
                                    <SpaceBetween size="l">
                                        <Container
                                            id="origin-panel"
                                            className="custom-screenshot-hide"
                                            header={<Header variant="h2">Ard New Plan Mapping Info</Header>}
                                        >
                                           <SpaceBetween size="l">
                                               <FormField
                                                   label="Plan Id"
                                                   description="The Fuse Plan ID"
                                                   i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                               >
                                                   <Input
                                                       placeholder="Please enter plan id"
                                                       ariaRequired={true}
                                                       value={this.state.planId}
                                                       onChange={(event)=>this.setPlanId(event)}
                                                   />
                                               </FormField>
                                               <FormField
                                                   label="Ard Id"
                                                   description="The ARD ID"
                                                   i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                               >
                                                   <Input
                                                       placeholder="Please enter ard id"
                                                       ariaRequired={true}
                                                       value={this.state.ardId}
                                                       onChange={(event)=>this.setArdId(event)}
                                                   />
                                               </FormField>
                                               <FormField
                                                   label="Partner Name"
                                                   description="The GL Line Description Partner Name "
                                                   i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                               >
                                                   <Input
                                                       placeholder="Please enter partner name"
                                                       ariaRequired={true}
                                                       value={this.state.glLineDescriptionPartnerName}
                                                       onChange={(event)=>this.setGlLineDescriptionPartnerName(event)}
                                                   />
                                               </FormField>
                                               <FormField
                                                   label="Stage Name"
                                                   description="Choose stage name"
                                                   i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                               >
                                                   <Select
                                                       data-testid="idType-filter"
                                                       options={[
                                                           {
                                                               "value": "DEVO"
                                                           },
                                                           {
                                                               "value": "UAT"
                                                           },
                                                           {
                                                               "value": "PROD"
                                                           }
                                                       ]}
                                                       selectedAriaLabel="Selected"
                                                       selectedOption={{
                                                           "value": this.state.migrationTargetStage || "DEVO"
                                                       }}
                                                       onChange={(e)=>this.setMigrationTargetStage(e)}
                                                       ariaDescribedby={''}
                                                       expandToViewport={true}
                                                   />
                                               </FormField>
                                               <FormField label="Fusen Enable" stretch={true}>
                                                   <RadioGroup
                                                       items={[
                                                           { label: 'True', value: 'true' },
                                                           { label: 'False', value: 'false' },
                                                       ]}
                                                       value={this.state.fusenEnabled?'true':'false'}
                                                       onChange={(e)=>this.setFusenEnable(e)}
                                                       ariaRequired={true}
                                                   />
                                               </FormField>
                                               <FormField stretch={true} label={<span id="certificate-expiry-label">Fusen Enable Date</span>}>
                                                   <SpaceBetween size="s" direction="horizontal">
                                                       <FormField
                                                           stretch={true}
                                                           description="Specify fusen enabled date"
                                                           className="date-time-container"
                                                           constraintText={'Use YYYY/MM/DD format.'}
                                                           i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                       >
                                                           <DatePicker
                                                               ariaLabelledby="certificate-expiry-label"
                                                               placeholder="YYYY/MM/DD"
                                                               previousMonthAriaLabel="Previous month"
                                                               nextMonthAriaLabel="Next month"
                                                               todayAriaLabel="Today"
                                                               value={this.state.fusenEnableDate}
                                                               onChange={(e) => this.setFusenEnabledDate(e)}
                                                               openCalendarAriaLabel={selectedDate =>
                                                                   'Choose fusen Enable date' + (selectedDate ? `, selected date is ${selectedDate}` : '')
                                                               }
                                                           />
                                                       </FormField>
                                                   </SpaceBetween>
                                               </FormField>
                                               <FormField
                                                   label="Add New Plan Mapping Reasoning"
                                                   description="Why you are adding this new plan mapping"
                                                   i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                               >
                                                   <Input
                                                       placeholder="Please enter your reason to add a new plan mapping"
                                                       ariaRequired={true}
                                                       value={this.state.reasoning}
                                                       onChange={(event)=>this.setReasoning(event)}
                                                   />
                                               </FormField>
                                           </SpaceBetween>
                                        </Container>
                                    </SpaceBetween>
                                </Form>
                                <Modal
                                    visible={this.state.isModalOpen}
                                    onDismiss={this.setCloseModal}
                                    header={'Review Changes'}
                                    closeAriaLabel="Close dialog"
                                    footer={
                                        <Box float="right">
                                            <SpaceBetween direction="horizontal" size="xs">
                                            {inputValidation(this.state.reasoning) ?
                                                <Button variant="primary" onClick={this.setCloseModal}>Input New ARD Plan Mapping Reasoning</Button>
                                                : null
                                            }
                                            </SpaceBetween>
                                        </Box>
                                    }
                                >
                                    <SpaceBetween size="m">
                                        {inputValidation(this.state.reasoning) ?
                                            <Box variant="span">
                                                <Box variant="span" fontWeight="bold">
                                                    <section>
                                                        {this.state.reasoning === "" && <p>New ARD Plan Mapping reasoning cannot be empty</p>}
                                                    </section>
                                                </Box>
                                                <Alert statusIconAriaLabel="Info">
                                                    You must input your reason for a new ARD plan mapping.
                                                </Alert>
                                            </Box>
                                        : null}
                                    </SpaceBetween>
                                </Modal>
                                {this.state.invalidInputs ? <div>
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Invalid input! Plan id, ARD id, partner name, and fusen enable date fields cannot be empty" type="error"></Alert>
                                    </Grid>
                                </div>: null}
                                {this.state.payload === "post" ? (
                                    <Spinner size = "large"></Spinner>
                                ) : null}
                                {this.state.payload === "invalid-ard" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="No workflow was found with these inputs!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "invalidFusePlan" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="FusePlan already exists with this id!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "invalidArd" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="Inputted Ard id does not exist!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "nonExistentFusePlan" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="FusePlan does not exist with this id!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "error" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="New plan mapping timed out, please try again!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "successful" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="New plan mapping created!" type="info"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                            </form>
                        </SpaceBetween>
                    </div>
                )}
            </InitDomain.Consumer>
        );
    }
}

export default AppArdNewPlanMapping;
