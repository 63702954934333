import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import {
    Alert,
    Button,
    Container,
    Form,
    FormField, Grid,
    Header,
    Input,
    NonCancelableCustomEvent,
    Select,
    SpaceBetween, Spinner
} from '@amzn/awsui-components-react';
import {Domain} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import {InitDomain} from "src/context/domain/domainContext";
import {ReminderBlock} from "src/components/reminder-block";
import invokeFuseBartBillingGatewayServiceAPIInvokeLambda from "src/lambda/fbgsAPIInvokeLambda/invoke-lambda";
import {inputValidation} from "src/commons/input-validation";

export class AppWorkflowStatus extends React.Component<{}, {externalId: string, workflowType:string, searched: boolean,
    payload: any, status: any, ldap: string, invalidInputs: boolean}> {
    authObj:AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            externalId: "",
            workflowType: "FuseBillingWorkflow",
            searched: false,
            payload: "init",
            status: "",
            ldap: "",
            invalidInputs: false,
        };
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
        this.setWorkflowType = this.setWorkflowType.bind(this);
        this.setExternalId = this.setExternalId.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({
                ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setExternalId(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({externalId: value})
    }

    setWorkflowType(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({workflowType: value})
    }

    search (domain: Domain){
        this.setState({
            invalidInputs: false,
            payload: "init",
            searched: false,
        });
        if(inputValidation(this.state.externalId)) {
            this.setState({
                invalidInputs: true,
            });
        } else {
            this.setState({
                searched: true,
                payload: "init"
            });
            const payload = {
                "methodName": "GET", "path": "/WorkflowStatus", "parameter": {
                    "externalId": this.state.externalId,
                    "workflowType": this.state.workflowType
                }
            }
            invokeFuseBartBillingGatewayServiceAPIInvokeLambda(payload, domain, this.callback)
        }
    }

    callback (response: any){
        console.log(response)
        if (response === "invalid") {
            this.setState({
                ...this.state,
                payload: "invalid"
            });
        } else if (response === "error") {
            this.setState({
                ...this.state,
                payload: "error"
            })
        } else {
            this.setState({
                ...this.state,
                payload: "result",
                status: response["status"]
            });
        }
    }

    render() {
        if (!(this.state.ldap.includes("bart-redrives") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Get Workflow Status'} description={'Get workflow status by external id and workflow type'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can get a workflow's status!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        let lis:string[]=[];
        if (this.state.payload === "result") {
            lis.push("Workflow Status: " + this.state.status);
        }
        return (
            <InitDomain.Consumer>
                {context => (
                    <div>
                        <FullPageTitle title={'Get Workflow Status'} description={'Get workflow status by external id and workflow type'}/>
                        <SpaceBetween size="l">
                            <form onSubmit={event => event.preventDefault()}>
                                <Form
                                    actions={
                                        <SpaceBetween direction="horizontal" size="xs">
                                            <Button variant="primary"
                                                    onClick={() => this.search(context.domain)}>
                                                Get Workflow Status
                                            </Button>
                                        </SpaceBetween>
                                    }
                                    errorIconAriaLabel="Error"
                                >
                                    <SpaceBetween size="l">
                                        <Container
                                            id="origin-panel"
                                            className="custom-screenshot-hide"
                                            header={<Header variant="h2">Get Workflow Status Info</Header>}>
                                                <SpaceBetween size="l">
                                                    <FormField
                                                        label="External ID"
                                                        description="The external id of the workflow to retrieve status for"
                                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                            <Input
                                                                placeholder="Please enter external id"
                                                                ariaRequired={true}
                                                                value={this.state.externalId}
                                                                onChange={(event) => this.setExternalId(event)}
                                                            />
                                                    </FormField>
                                                    <FormField
                                                        label="Workflow Type"
                                                        description="Choose workflow type"
                                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                            <Select
                                                                data-testid="workflowType-filter"
                                                                options={[
                                                                    {
                                                                        "value": "FuseBillingWorkflow"
                                                                    },
                                                                    {
                                                                        "value": "FUSENWorkflow"
                                                                    },
                                                                    // TODO: any other workflows we want to support?
                                                                ]}
                                                                selectedAriaLabel="Selected"
                                                                selectedOption={{
                                                                    "value": this.state.workflowType || "FuseBillingWorkflow"
                                                                }}
                                                                onChange={(event) => this.setWorkflowType(event)}
                                                                ariaDescribedby={''}
                                                                expandToViewport={true}
                                                            />
                                                    </FormField>
                                                </SpaceBetween>
                                        </Container>
                                    </SpaceBetween>
                                </Form>
                                {this.state.invalidInputs ? <div>
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Invalid input! External id field cannot be empty" type="error"></Alert>
                                    </Grid>
                                </div>: null}
                                {this.state.searched === true && this.state.payload === "init" ? (
                                    <Spinner size = "large"></Spinner>
                                ) : null}
                                {this.state.payload === "invalid" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="No workflow was found with these inputs!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "error" ? (
                                    <div style={{paddingLeft: '10px'}}>
                                        <Grid
                                            gridDefinition={[
                                                {colspan: {l: 7, xxs: 10}},
                                            ]}
                                        >
                                            <Alert header="Get workflow status timed out, please try again!" type="error"></Alert>
                                        </Grid>
                                    </div>
                                ) : null}
                                {this.state.payload === "result" ? (
                                    <div id="workflow-status-result">
                                        <ReminderBlock title="Result" lis={lis}/>
                                    </div>
                                ) : null}
                            </form>
                        </SpaceBetween>
                    </div>
                )}
            </InitDomain.Consumer>
        );
    }
}

export default AppWorkflowStatus;
