import React from 'react';
import {Alert, Grid, Spinner} from "@amzn/awsui-components-react";
import '../../pages/commons/common.styles.scss';

interface PayloadProps {
    searchResults: any,
}

export class PayloadDisplayManyArd extends React.Component <PayloadProps> {
    constructor(props: any) {
        super(props);
    }


    render () {
        const {searchResults} = this.props;
        if (searchResults != "init") {
            if (searchResults == "error" || searchResults == "invalid") {
                return(
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="Invalid search!" type="error"></Alert>
                        </Grid>
                    </div>
                );
            }
            return <pre className = "sourceInfo">{JSON.stringify(searchResults, null, 2)}</pre>

        } else {
            return <Spinner size = "large"></Spinner>;
        }
    }
}

export default PayloadDisplayManyArd;
