import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../commons'
import { learnMoreLinks } from "../commons/learn-more-links";

export function HelpPane() {
    return (
        <HelpPanel
            header={<h2>View Raga availabilities</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Check what buying customer country code + product + term is supported by Raga. </p>
        </HelpPanel>
    );
}
