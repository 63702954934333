import React, {ReactNode} from "react";
import {
    Box,
    CollectionPreferencesProps
} from "@amzn/awsui-components-react";
import {createTableSortLabelFn} from "src/i18n-strings/table";

const rawColumns = [
    {
        id: 'externalId',
        header: 'External ID',
        cell: (item: { externalId: any; }) => item.externalId,
        minWidth: 220,
        maxWidth: 220,
        sortingField: 'externalId'
    },
    {
        id: 'workflowType',
        header: 'Workflow Type',
        cell: (item: { workflowType: any; }) => item.workflowType,
        minWidth: 120,
        maxWidth: 120
    },
    {
        id: 'eventVersionOverride',
        header: 'Event Version Override',
        cell: (item: { eventVersionOverride: any; }) => item.eventVersionOverride,
        minWidth: 120,
        maxWidth: 120
    },
    {
        id: 'sentToRedriveSQS',
        header: 'Sent to Redrive SQS',
        cell: (item: { sentToRedriveSQS: any; }) => {
            if(item.sentToRedriveSQS != null) {
                return item.sentToRedriveSQS.toString()
            } else {
                return item.sentToRedriveSQS
            }
        },
        minWidth: 120,
        maxWidth: 120
    },
    {
        id: 'successfullySentToRedrive',
        header: 'Successfully Sent To Redrive',
        cell: (item: { successfullySentToRedrive: any; }) => {
            if(item.successfullySentToRedrive != null) {
                return item.successfullySentToRedrive.toString()
            } else {
                return item.successfullySentToRedrive
            }
        },
        minWidth: 120,
        maxWidth: 120
    },
    {
        id: 'sentToWorkflowStatusSQS',
        header: 'Sent to Workflow Status SQS',
        cell: (item: { sentToWorkflowStatusSQS: any; }) => {
            if(item.sentToWorkflowStatusSQS != null) {
                return item.sentToWorkflowStatusSQS.toString()
            } else {
                return item.sentToWorkflowStatusSQS
            }
        },
        minWidth: 120,
        maxWidth: 120
    },
    {
        id: 'workflowStatus',
        header: 'Workflow Status',
        cell: (item: { workflowStatus: any; }) => {
            if(item.workflowStatus == null) {
                return ''
            } else {
                return item.workflowStatus
            }
        },
        minWidth: 120,
        maxWidth: 120
    },
];

export const STATUS_COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const statusPaginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const STATUS_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    visibleContent: ['externalId', 'workflowType', 'eventVersionOverride', 'sentToRedriveSQS', 'successfullySentToRedrive', 'sentToWorkflowStatusSQS', 'workflowStatus'],
    wrapLines: false,
    custom: 'table'
};
