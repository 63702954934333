import React from 'react';
import { Domain } from 'src/config/BartLambdaConfig';
import { useNavigate } from 'react-router-dom';
import {Button, FormField, Grid, Input, Select} from '@amzn/awsui-components-react';
import { DateRangeTableFilter } from './daterangetablefilter';
import '../styles/table-select.scss';

interface Props {
  keyword: string;
  idType: string;
  idLabel: string;
  isTest: string;
  eventType: string;
  setEventType: Function;
  setIsTest: Function;
  setValue: Function;
  setChoice: Function;
  onClickSearch: Function;
  domain: Domain;
  setDateRange: Function;
  count: string;
  setCount: Function;
}

const SearchBar = ({
                     keyword,
                     idType,
                     idLabel,
                     isTest,
                     eventType,
                     setEventType,
                     setIsTest,
                     setValue,
                     setChoice,
                     onClickSearch,
                     domain,
                     setDateRange,
                     count,
                     setCount,
                   }: Props) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (idType && keyword) {
      navigate(`/transactionsearch/${idType}/${keyword}`);
      onClickSearch(domain);
    }
  };

  const handleDateRangeFilter = (startDate: string, endDate: string) => {
    setDateRange(startDate, endDate);
  };

  return (
        <div style={{paddingLeft: '10px'}}>
          <div className="input-container">
            <div className="input-filter">
              <Input
                  data-testid="input-filter"
                  type="search"
                  value={keyword}
                  onChange={(e: any)=> {
                    if (e.detail!=null) {
                      setValue(e.detail.value);
                    }
                  }
                }
                placeholder="Input external id, reseller id, or subscription id"
                clearAriaLabel="clear"
                ariaDescribedby={''}
            />
          </div>
          <div className="select-filter">
            <Select
                data-testid="idType-filter"
                options={[
                    {
                        value: 'external_id',
                        label: 'external id',
                    },
                    {
                        value: 'reseller_id',
                        label: 'reseller id',
                    },
                    {
                        value: 'subs_subscription_id',
                        label: 'subscription id',
                    },
                ]}
                selectedAriaLabel="Selected"
                selectedOption={{
                  value: idType || 'external_id',
                    label: idLabel || 'external id'
                }}
                onChange={(e: any) => {
                  setChoice(e.detail.selectedOption.value);
                }}
                ariaDescribedby={''}
                expandToViewport={true}
            />
          </div>
            {idType === 'external_id'? null :
                <>
                <div className="date-range-filter">
                  <DateRangeTableFilter onFilter={handleDateRangeFilter} />
                </div>
              <div className="count-filter">
                  <FormField constraintText="# of results (1-10)">
                      <Input
                      type={"number"}
                      inputMode={"numeric"}
                      data-testid="count-filter"
                      value={count}
                      onChange={(e: any) => {
                          if (e.detail.value>0 && e.detail.value<=10) {
                              setCount(e.detail.value);
                          }
                      }}
                      />
                  </FormField>
              </div>
                </>}
              {idType === ('reseller_id')?
                  <>
                      <div className="select-filter">
                          <FormField constraintText="is_test filter">
                              <Select
                                  data-testid="isTest-filter"
                                  options={[
                                      {
                                          value: 'no filter',
                                      },
                                      {
                                          value: 'true',
                                      },
                                      {
                                          value: 'false',
                                      },
                                  ]}
                                  selectedAriaLabel="Selected"
                                  selectedOption={{
                                      value: isTest || 'no filter',
                                  }}
                                  onChange={(e: any) => {
                                      setIsTest(e.detail.selectedOption.value);
                                  }}
                                  ariaDescribedby={''}
                                  expandToViewport={true}
                              />
                          </FormField>
                      </div>
                      <div className="select-filter">
                          <FormField constraintText="event_type filter">
                              <Select
                                  data-testid="eventType-filter"
                                  options={[
                                      {
                                          value: 'no filter',
                                      },
                                      {
                                          value: 'PeriodStart',
                                      },
                                      {
                                          value: 'Cancelled',
                                      },
                                      {
                                          value: 'Resubscribe',
                                      },
                                  ]}
                                  selectedAriaLabel="Selected"
                                  selectedOption={{
                                      value: eventType || 'no filter',
                                  }}
                                  onChange={(e: any) => {
                                      setEventType(e.detail.selectedOption.value);
                                  }}
                                  ariaDescribedby={''}
                                  expandToViewport={true}
                              />
                          </FormField>
                      </div>
                  </>: null}
          <Button onClick={onClick}>Search</Button>
        </div>
      </div>
  );
};

export default SearchBar;
