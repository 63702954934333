import {SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import React from "react";
import {FullPageTitle} from "src/components/header/title";

export class Loader extends React.Component {
    render () {
        return (
            <>
                <FullPageTitle title={'Re-Mapping ARD to Plan'} description={'Associates an ARD id to a Fuse Plan'}/>
                <SpaceBetween size = "l">
                    <Spinner size = "large"></Spinner>;
                </SpaceBetween>
            </>
        );
    }
}
