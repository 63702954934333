import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify';

export class AuthService {

    isLoggedIn: boolean = false;
    failed: boolean = false;
    given_name: string = '';
    ldap: string='';
    userId: string = '';

    constructor () {
        console.log("Listening...")
        //listens to any sign-in events
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    this.loginProcess();
                    break;
                case 'signIn_failure':
                    this.isLoggedIn = false;
                    break;
                default:
                    break;
            }
         });
    }

    //Authenticates user if current session does not exist
    async authenticate () {
        try {
            console.log('getting current session');
            await Auth.currentSession().then((session) => {
                this.given_name = session.getIdToken().decodePayload().given_name;
                this.userId = session.getIdToken().decodePayload()['identities'][0]['userId'];
                this.ldap = session.getIdToken().decodePayload()['custom:ldap'];
                this.isLoggedIn = true});
            await Auth.currentSession().then(() => this.isLoggedIn = true);
        }
        catch(err){
            if (!window.location.href.includes("code=")) {
                await Auth.federatedSignIn({customProvider: 'FederateOIDC'});
            } 
        }
    }

    //get tokens
    async getToken(): Promise<string> {
        return (await Auth.currentSession()).getIdToken().getJwtToken();
    }

    //get logged in boolean
    getIsLoggedIn(): boolean {
        return this.isLoggedIn;
    }

    // Sets isLoggedIn to be true if current session exists, i.e., authenticated
    async loginProcess() {
        await Auth.currentSession().then(session => {
            this.isLoggedIn = true
        });
    }
}

export default AuthService;
