import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";


export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>Re-Mapping ARD to Fuse Plan</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Re-maps an ARD id to a Fuse Plan.</p>
            <ul>
                <li>
                    Please fetch Fuse plan by plan ID first.
                </li>
                <li>
                    Click "Re-Map Ard to Plan" to review and confirm changes.
                </li>
                <li>
                    After confirm the changes will take effect.
                </li>
            </ul>
        </HelpPanel>
    );
}
