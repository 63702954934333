import React, {useRef, useState} from "react";
import {AppLayout, AppLayoutProps, NonCancelableCustomEvent} from '@amzn/awsui-components-react';
import { Navigation } from 'src/pages/commons';
import {URLS} from "src/components/constants/appConstants";
import {ToolsContent} from "src/pages/workflowStatus/components/common-components";
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
import AppWorkflowStatus from "src/components/workflowStatusPage/workflow-status-page";

export function WorkflowStatus(){
    const [navigationOpen, setNavigationOpen] = React.useState(false);
    const appLayout = useRef<AppLayoutProps.Ref>(null);
    const [toolsOpen, setToolsOpen] = useState(false);
    const loadPanelFn = () => {
        setToolsOpen(true);
        appLayout.current?.focusToolsClose();
    };

    const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
        setToolsOpen(detail.open);
    };
    return (
        <AppLayout
            disableContentPaddings
            content={<AppWorkflowStatus/>}
            navigation={<Navigation activeHref={URLS.GET_WORKFLOW_STATUS} />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            tools={<ToolsContent/>}
            toolsOpen={toolsOpen}
            onToolsChange={onToolsChangeFn}
            ariaLabels={appLayoutAriaLabels}
        />
    );
}
