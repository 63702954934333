import React, {useRef, useState} from "react";
import {AppLayout, AppLayoutProps, NonCancelableCustomEvent, Table} from '@amzn/awsui-components-react';
import {  Navigation } from 'src/pages/commons';
import {URLS} from "src/components/constants/appConstants";
import {ToolsContent} from "./components/common-components";
import '../commons/common.styles.scss'
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
import AppPutArd from "src/components/updateArdPage/put-ard-page";


export function UpdateFuseARD(){
  const [navigationOpen, setNavigationOpen] = React.useState(false);
  const appLayout = useRef<AppLayoutProps.Ref>(null);
  const [toolsOpen, setToolsOpen] = useState(false);
  const loadPanelFn = () => {
    setToolsOpen(true);
    appLayout.current?.focusToolsClose();
  };

  const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
    setToolsOpen(detail.open);
  };
  return (
      <AppLayout
          disableContentPaddings
          content={<AppPutArd/>}
          navigation={<Navigation activeHref={URLS.UPDATE_ARD} />}
          navigationOpen={navigationOpen}
          onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
          tools={<ToolsContent />}
          toolsOpen={toolsOpen}
          onToolsChange={onToolsChangeFn}
          ariaLabels={appLayoutAriaLabels}
      />
  );
}