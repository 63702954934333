import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {Auth} from "aws-amplify";
import Lambda from "aws-sdk/clients/lambda";

function invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda (requestId: string, redriveRequests: any[],
                                                                       domain: Domain, callback: Function) {
    const lambdaConfig = getBartLambdaConfig(domain)
    return Auth.currentCredentials()
        .then(credentials => {
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: lambdaConfig.awsRegion,
            });
            let param = {
                FunctionName: lambdaConfig.billingGatewayServiceBulkRedriveWorkflowLambdaARN,
                InvocationType: 'Event',
                Payload: JSON.stringify({"requestId": requestId, "redriveRequests": redriveRequests})
            };

            return lambda.invoke(param, function(err, data) {
                if (err) {
                    console.log(err);
                    console.log(redriveRequests);
                    return callback("error");
                }
                return callback(requestId);
            })
        });
}

export default invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda;
