import {Route, Routes} from "react-router-dom";
import React from "react";
import {Domain} from "src/config/BartLambdaConfig";
import {TransactionSearch} from "src/pages/transactionSearch/transactions";
import {FusePlan} from "src/pages/fusePlan/fusePlan";
import {FuseArd} from "src/pages/fuseArd/fuseArd";
import {FuseAllArds} from "src/pages/fuseAllArds/fuseAllArds";
import {UpdateFusePlan} from "src/pages/updateFusePlan/updateFusePlan";
import {UpdateFuseARD} from "src/pages/updateArd/updateArd";
import {RedriveWorkflow} from "src/pages/redriveWorkflow/redrives";
import {BulkRedriveWorkflow} from "src/pages/bulkRedriveWorkflow/bulkRedrives";
import {BulkRedriveStatus} from "src/pages/bulkRedriveStatus/bulkRedriveStatus";
import {BartAuditHistory} from "src/pages/bartAuditHistory/bartAuditHistory";
import { DlqMoverWorkflow } from "src/pages/dlqmoverWorkflow/dlqmover";
import {WorkflowStatus} from "src/pages/workflowStatus/workflowStatus";
import {LinkArdToAFusePlan} from "src/pages/remappingArdToPlan/linkArdToAFusePlan";
import {ArdNewPlanMapping} from "src/pages/ardNewPlanMapping/ardNewPlanMapping";
import { URLS } from "src/components/constants/appConstants";
import { RagaAvailabilities } from '../pages/raga-availabilities';

interface Props {
    domain: Domain;
}

export const  BartRoutes = (props: Props)=>{
    return(
    <Routes>
        <Route path ='/'>
            <Route path = "transactionsearch/:type/:id" element={<TransactionSearch domain={props.domain}/>}/>
            <Route path = "transactionsearch" element={<TransactionSearch domain={props.domain} />}/>
            <Route path = "" element={<TransactionSearch domain={props.domain} />}/>
        </Route>
        <Route path ='/getplan'>
            <Route path = ":id" element={<FusePlan domain={props.domain}/>}/>
            <Route path = "" element={<FusePlan  domain={props.domain}/>}/>
        </Route>
        <Route path ='/getard'>
            <Route path = ":id" element={<FuseArd domain={props.domain}/>}/>
            <Route path = "" element={<FuseArd domain={props.domain}/>}/>
        </Route>
        <Route path ='/updateplan' element={<UpdateFusePlan/>}/>
        <Route path ='/updateard' element={<UpdateFuseARD/>}/>
        <Route path ='/viewallards' element={<FuseAllArds/>}/>
        <Route path ={URLS.RAGA_AVAILABILITIES} element={<RagaAvailabilities/>}/>
        <Route path ='/redriveworkflow' element={<RedriveWorkflow/>}/>
        <Route path ='/bulkredriveworkflow' element={<BulkRedriveWorkflow/>}/>
        <Route path ='/bulkredrivestatus'>
            <Route path = ":id" element={<BulkRedriveStatus domain={props.domain}/>}/>
            <Route path = "" element={<BulkRedriveStatus domain={props.domain}/>}/>
        </Route>
        <Route path ='/bartaudithistory' element={<BartAuditHistory/>}/>
        <Route path ='/bartdlqmoverworkflow' element={<DlqMoverWorkflow/>}/>
        <Route path ='/getworkflowstatus' element={<WorkflowStatus/>}/>
        <Route path ='/remappingardtoplan' element={<LinkArdToAFusePlan/>}/>
        <Route path ='/ardnewplanmapping' element={<ArdNewPlanMapping/>}/>
    </Routes>
    )
}
