import React from 'react';
import {AuthService} from 'src/auth/auth-service';
import {
    Alert, Box,
    Button,
    Container,
    Form,
    FormField,
    Grid,
    Header,
    Input,
    Modal,
    NonCancelableCustomEvent,
    Select,
    SpaceBetween
} from '@amzn/awsui-components-react';
import "src/index.scss";
import {BaseArd} from "src/model/ard";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {InitDomain} from "src/context/domain/domainContext";
import {FullPageTitle} from "src/components/header/title";
import GetArd from "src/components/updateArdPage/get-ard";
import {ArdLoader} from "src/components/updateArdPage/ard-loader";
import Collapsible from "src/pages/commons/collapsible";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {inputValidation} from "src/commons/input-validation";

interface PutRequestParameters {
    ardIdKey: string;
    product: string;
    term?: string;
    template?: string;
    businessCountryCode?: string;
    buyingCustomerCountryCode?: string;
    functionalCurrency?: string;
    localCurrency?: string;
}

export class AppPutArd extends React.Component <{}, {
    ldap: string;
    ardIdKey: string;
    product: string;
    storeProduct: string;
    term: string;
    storeTerm?: string;
    template: string;
    storeTemplate?: string;
    businessCountryCode: string;
    storeBusinessCountryCode?: string;
    buyingCustomerCountryCode: string;
    functionalCurrency: string;
    storeFunctionalCurrency?: string;
    localCurrency: string;
    isModalOpen: boolean;
    keyword: string;
    searched: boolean;
    reasoning: string;
    payload: any}> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            ldap: "",
            ardIdKey: "",
            product: "",
            storeProduct: "",
            term: "",
            storeTerm: "",
            template: "",
            storeTemplate: "",
            businessCountryCode: "",
            storeBusinessCountryCode: "",
            buyingCustomerCountryCode: "",
            functionalCurrency: "",
            storeFunctionalCurrency: "",
            localCurrency: "",
            isModalOpen: false,
            keyword: "",
            searched: false,
            reasoning: "",
            payload: "init"
        };
        this.putArd = this.putArd.bind(this);
        this.setArdId = this.setArdId.bind(this);
        this.setProductType = this.setProductType .bind(this);
        this.setTerm = this.setTerm.bind(this);
        this.setTemplate = this.setTemplate.bind(this);
        this.setBusinessCountryCode = this.setBusinessCountryCode.bind(this);
        this.setBuyingCustomerCountryCode = this.setBuyingCustomerCountryCode.bind(this);
        this.setFunctionalCurrency = this.setFunctionalCurrency.bind(this);
        this.setLocalCurrency = this.setLocalCurrency.bind(this);
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
        this.callback = this.callback.bind(this);
    }

    callback (response: any) {
        if(JSON.parse(response).ard) {
            let ardData: BaseArd = JSON.parse(response).ard
            this.setState({
                ...this.state,
                ...ardData,
                storeProduct: ardData.product,
                storeTerm: ardData.term,
                storeTemplate: ardData.template,
                storeBusinessCountryCode: ardData.businessCountryCode,
                storeFunctionalCurrency: ardData.functionalCurrency,
                payload: ardData
            });
        } else if (response == "\"invalid-ard\"") {
            this.setState({
                ...this.state,
                payload: "invalid-ard"
            });
        } else {
            this.setState({
                ...this.state,
                payload: "error"
            });
        }
    }

    search(domain:Domain) {
        this.setState({
            searched: true,
            payload: "init"
        });
        const payload = {"methodName": "GET", "path": "/ard/{ardId}", "parameter": {"ardIdKey":this.state.keyword}}
        return invokeBartArdLambda(payload, this.callback, domain)
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }
    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    setArdId(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({ardIdKey: value})
    }

    setProductType(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({product: value})
    }

    setTerm(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({term: value})
    }

    setTemplate(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({template: value})
    }

    setBusinessCountryCode(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({businessCountryCode: value})
    }

    setBuyingCustomerCountryCode(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({buyingCustomerCountryCode: value})
    }

    setFunctionalCurrency(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({functionalCurrency: value})
    }

    setLocalCurrency(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({localCurrency: value})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        this.setState({['reasoning']: event.detail.value} as any)
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({ldap: this.authObj.ldap});
        }
        fetchData();
    }

    putArd(domain:Domain) {
        this.setCloseModal();
        this.setState({
            payload: "init"
        });

        const parameters: PutRequestParameters = {
            "ardIdKey":this.state.keyword,
            "product":this.state.product,
            "term":this.state.term,
            "template":this.state.template,
            "businessCountryCode": this.state.businessCountryCode,
            "buyingCustomerCountryCode":this.state.buyingCustomerCountryCode,
            "functionalCurrency":this.state.functionalCurrency,
            "localCurrency": this.state.localCurrency
        };

        const payload = {"methodName": "PUT", "path": "/ard/{ardId}", "parameter":
            parameters};
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "Update ARD",
                        additionalInfo: "ARD Id: " + this.state.keyword,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })
        return invokeBartArdLambda(payload, this.callback,domain)
    }

    render() {
        if (!(this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Update ARD'} description={'Update Fuse ARD info'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can update Fuse ARD Information!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        if (!this.state.searched) {
            return (
                <GetArd keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search}/>
            )
        }
        if (this.state.payload == "init") {
            return (
                <ArdLoader />);
        }
        if (this.state.payload == "invalid-ard") {
            return (
                <GetArd error ="invalid" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search} />);
        }
        if(this.state.payload == "error"){
            return (
                <GetArd error ="error" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search} />);
        }
        return (
            <InitDomain.Consumer>
                {context =>(
            <div>
                <FullPageTitle title={'Update ARD'} description={'Update Fuse ARD info'}/>
                    <SpaceBetween size = "l" >
                <form onSubmit={event => event.preventDefault()}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="primary"
                                        onClick={this.setOpenModal}
                                >Update ARD</Button>
                            </SpaceBetween>
                        }
                        errorIconAriaLabel="Error"
                    >
                        <SpaceBetween size="l">
                            <Container
                                id="origin-panel"
                                className="custom-screenshot-hide"
                                header={<Header variant="h2">Update Fuse ARD Info</Header>}
                            >
                                <SpaceBetween size="l">
                                    <FormField
                                        label="ARD ID"
                                        description="The Fuse ARD ID"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Input
                                            placeholder="Please enter ARD Id"
                                            ariaRequired={true}
                                            value={this.state.keyword}
                                            disabled={true}
                                            onChange={(e)=>this.setArdId(e)}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Product Type"
                                        description="Choose product type"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Select
                                            data-testid="productType-filter"

                                            options={[
                                                {
                                                    "value": "VIDEO"
                                                },
                                                {
                                                    "value": "PRIME"
                                                },
                                                {
                                                    "value": "KINDLE"
                                                },
                                                {
                                                    "value": "MUSIC"
                                                },
                                                {
                                                    "value": "PVGO"
                                                },
                                            ]}
                                            selectedAriaLabel="Selected"
                                            selectedOption={{
                                                "value": this.state.product
                                            }}
                                            onChange={(e)=>this.setProductType(e)}
                                            ariaDescribedby={''}
                                            expandToViewport={true}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Term"
                                        description="Choose term type"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Select
                                            data-testid="termType-filter"

                                            options={[
                                                {
                                                    "value": "MONTHLY"
                                                },
                                                {
                                                    "value": "ANNUAL"
                                                },
                                            ]}
                                            selectedAriaLabel="Selected"
                                            selectedOption={{
                                                "value": this.state.term
                                            }}
                                            onChange={(e)=>this.setTerm(e)}
                                            ariaDescribedby={''}
                                            expandToViewport={true}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Template Type"
                                        description="Choose template type"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Select
                                            data-testid="productType-filter"

                                            options={[
                                                {
                                                    "value": "STANDARD"
                                                },
                                                {
                                                    "value": "DEMO"
                                                },
                                                {
                                                    "value": "DEFERRAL_SPLIT_INTERCOMPANY"
                                                },
                                                {
                                                    "value": "DEFERRAL_NO_INTERCOMPANY"
                                                },
                                            ]}
                                            selectedAriaLabel="Selected"
                                            selectedOption={{
                                                "value": this.state.template
                                            }}
                                            onChange={(e)=>this.setTemplate(e)}
                                            ariaDescribedby={''}
                                            expandToViewport={true}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Business Country Code"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Input
                                            placeholder="Please enter business country  code"
                                            ariaRequired={true}
                                            value={this.state.businessCountryCode}
                                            onChange={(event)=>this.setBusinessCountryCode(event)}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Currency"
                                        description="The functional currency"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Input
                                            placeholder="Please enter currency"
                                            ariaRequired={true}
                                            value={this.state.functionalCurrency}
                                            onChange={(event)=>this.setFunctionalCurrency(event)}
                                        />
                                    </FormField>
                                    <FormField
                                        label="Update Reasoning"
                                        description="Why you are performing this update"
                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                    >
                                        <Input
                                            placeholder="Please enter your reason to perform a update"
                                            ariaRequired={true}
                                            value={this.state.reasoning}
                                            onChange={(event)=>this.setReasoning(event)}
                                        />
                                    </FormField>
                                </SpaceBetween>
                            </Container>
                        </SpaceBetween>
                    </Form>
                    <Modal
                        visible={this.state.isModalOpen}
                        onDismiss={this.setCloseModal}
                        header={'Review changes'}
                        closeAriaLabel="Close dialog"
                        footer={
                            <Box float="right">
                                <SpaceBetween direction="horizontal" size="xs">
                                    {inputValidation(this.state.reasoning) || inputValidation(this.state.businessCountryCode)
                                        || inputValidation(this.state.functionalCurrency) ?
                                        <Button variant="primary" onClick={this.setCloseModal}>Update Inputs</Button> :
                                        (<div>
                                            <Button variant="link" onClick={this.setCloseModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" onClick={() => this.putArd(context.domain)}
                                                    data-testid="submit">
                                                Update
                                            </Button>
                                        </div>)
                                    }
                                </SpaceBetween>
                            </Box>
                        }
                    >
                        <SpaceBetween size="m">
                            {inputValidation(this.state.reasoning) || inputValidation(this.state.businessCountryCode)
                            || inputValidation(this.state.functionalCurrency) ?
                                <Box variant="span">
                                    <Alert type="error" header="Invalid Input! Business country code, currency, and reasoning fields cannot be empty"></Alert>
                                </Box>
                                : <Box variant="span">
                                    Permanently delete distribution{' '}
                                    <Box variant="span" fontWeight="bold">
                                        <section>
                                            {this.state.storeProduct !== this.state.product && <p>
                                                Product type has been changed from {this.state.storeProduct}&nbsp;
                                                to {this.state.product}
                                            </p>}</section>
                                        <section>
                                            {this.state.storeTerm !== this.state.term && <p>
                                                Term has been changed from {this.state.storeTerm}&nbsp;
                                                to {this.state.term}
                                            </p>}
                                        </section>
                                        <section>
                                            {this.state.storeTemplate !== this.state.template && <p>
                                                Template type has been changed from {this.state.storeTemplate}&nbsp;
                                                to {this.state.template}
                                            </p>}
                                        </section>
                                        <section>
                                            {this.state.storeBusinessCountryCode !== this.state.businessCountryCode &&
                                                <p>
                                                    Business Country Code has been changed
                                                    from {this.state.storeBusinessCountryCode}&nbsp;
                                                    to {this.state.businessCountryCode}
                                                </p>}
                                        </section>
                                        <section>
                                            {this.state.storeFunctionalCurrency !== this.state.functionalCurrency && <p>
                                                Functional currency has been changed
                                                from {this.state.storeFunctionalCurrency}&nbsp;
                                                to {this.state.functionalCurrency}
                                            </p>}
                                        </section>
                                    </Box>
                                    <Alert statusIconAriaLabel="Info">
                                        You can’t undo this action.
                                    </Alert>
                                </Box>
                            }
                        </SpaceBetween>
                    </Modal>
                </form>
                <Collapsible title={"ARD Info Preview"} open = {false}>
                    <pre className = "sourceInfo ">{JSON.stringify(this.state.payload, null, 2)}</pre>
                </Collapsible>
                    </SpaceBetween>
            </div>)}
            </InitDomain.Consumer>
        );
    }
}

export default AppPutArd;

