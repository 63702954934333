
export const learnMoreLinks = [
    {
        href: 'https://w.amazon.com/bin/view/Fuse/Accounting/Raga/',
        text: 'Raga',
    },
    {
        href: 'https://w.amazon.com/bin/view/Fuse/Accounting/',
        text: 'Fuse Accounting',
    },
    {
        href: 'https://w.amazon.com/bin/view/Fuse/Accounting/BartAccountingPortal/',
        text: 'BART Wiki',
    },
    {
        href: 'https://t.corp.amazon.com/create/templates/c623e1ed-2587-431a-9e95-47f52ea7a8e7',
        text: 'BART Ticket Support',
    },
];
