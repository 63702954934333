import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";


export function ToolsContent() {
  return (
      <HelpPanel
          header={<h2>Update ARD Resource</h2>}
          footer={
            <ExternalLinkGroup
                items={learnMoreLinks}
            />
          }
      >
        <p>Update the ARD and related information.</p>
      </HelpPanel>
  );
}
