import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";


export function ToolsContent() {
  return (
      <HelpPanel
          header={<h2>Update FusePlan Resource</h2>}
          footer={
            <ExternalLinkGroup
                items={learnMoreLinks}
            />
          }
      >
        <p>Update the Plan and related information.</p>
        <ul>
          <li>
            Please fetch Fuse plan by plan ID first.
          </li>
          <li>
            Click "Update Plan" to review and confirm changes.
          </li>
          <li>
            After confirm the changes will take effect.
          </li>
          <li>
            Check the detail of plan in preview box.
          </li>
        </ul>
      </HelpPanel>
  );
}
