import React from 'react';
import {Domain} from "src/config/BartLambdaConfig";
import AuthService from "src/auth/auth-service";
import {FullPageTitle} from "src/components/header/title";
import {Alert, Grid, SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import BulkRedriveStatusSearchBar from "src/components/bulkRedriveStatusPage/bulk-redrive-status-search-bar";
import StatusTable from "src/components/bulkRedriveStatusPage/table-display";
import {API, graphqlOperation} from "aws-amplify";
import {getBulkRedriveWorkflowEntries} from "src/graphql/queries";
import {HistoryWorkflow} from "src/components/bulkRedriveStatusPage/index";
import {ReminderBlock} from "src/components/reminder-block";
import {inputValidation} from "src/commons/input-validation";

export class AppBulkRedriveStatus extends React.Component<any, {
    domain: Domain, authenticating: boolean, isLoggedIn: boolean, show: boolean, requestId: string,
    searched: boolean, payload: any, workflows: any[], workflowType: string, sentToRedriveSQS: string,
    successfullySentToRedrive: string, sentToWorkflowStatusSQS: string, workflowStatus: string, ldap: string, invalidInputs: boolean}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            domain: this.props.domain,
            authenticating: true,
            isLoggedIn: false,
            show: false,
            requestId: window.location.pathname.split("/")[2] || "",
            searched: false,
            payload: "init",
            workflows: [],
            workflowType: "no filter",
            sentToRedriveSQS: "no filter",
            successfullySentToRedrive: "no filter",
            sentToWorkflowStatusSQS: "no filter",
            workflowStatus: "no filter",
            ldap: "",
            invalidInputs: false,
        };
        this.setRequestId = this.setRequestId.bind(this);
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
        this.setWorkflowType = this.setWorkflowType.bind(this);
        this.setSentToRedriveSQS = this.setSentToRedriveSQS.bind(this);
        this.setSuccessfullySentToRedrive = this.setSuccessfullySentToRedrive.bind(this);
        this.setSentToWorkflowStatusSQS = this.setSentToWorkflowStatusSQS.bind(this);
        this.setWorkflowStatus = this.setWorkflowStatus.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap:this.authObj.ldap
            });
        }
        fetchData();
        if(this.state.requestId){
            this.search(this.state.domain);
        }
    }

    setRequestId (value: string){
        this.setState({requestId: value.trim()});
    }

    setWorkflowType (value: string){
        this.setState({workflowType: value})
    }

    setSentToRedriveSQS (value: string){
        this.setState({sentToRedriveSQS: value})
    }

    setSuccessfullySentToRedrive (value: string){
        this.setState({successfullySentToRedrive: value})
    }

    setSentToWorkflowStatusSQS (value: string){
        this.setState({sentToWorkflowStatusSQS: value})
    }

    setWorkflowStatus (value: string){
        this.setState({workflowStatus: value})
    }

    search (domain: Domain) {
        this.setState({
            invalidInputs: false,
            searched: false,
            payload: "init",
        });
        if (inputValidation(this.state.requestId)) {
            this.setState({
                invalidInputs: true,
            });
        } else {
            this.setState({
                searched: true,
                payload: "loading",
            });
            const graphqlAPI = async () => {
                const result = await API.graphql(graphqlOperation(
                    getBulkRedriveWorkflowEntries,
                    {requestId: this.state.requestId},
                    'API_KEY'
                ));
                this.callback(result);
            }
            graphqlAPI();
        }
    }

    callback (response: any) {
        this.setState({
            payload: "result",
            workflows: response['data']['getBulkRedriveWorkflowEntries']
        });
        let filteredWorkflows: HistoryWorkflow[] = response['data']['getBulkRedriveWorkflowEntries'];
        if (this.state.workflowType != "no filter") {
            filteredWorkflows = filteredWorkflows.filter(workflow => workflow.workflowType == this.state.workflowType)
        }
        if (this.state.sentToRedriveSQS != "no filter") {
            filteredWorkflows = filteredWorkflows.filter(workflow => workflow.sentToRedriveSQS.toString() == this.state.sentToRedriveSQS)
        }
        if (this.state.successfullySentToRedrive != "no filter") {
            filteredWorkflows = filteredWorkflows.filter(workflow => workflow.successfullySentToRedrive.toString() == this.state.successfullySentToRedrive)
        }
        if (this.state.sentToWorkflowStatusSQS != "no filter") {
            filteredWorkflows = filteredWorkflows.filter(workflow => workflow.sentToWorkflowStatusSQS.toString() == this.state.sentToWorkflowStatusSQS)
        }
        if (this.state.workflowStatus != "no filter") {
            filteredWorkflows = filteredWorkflows.filter(workflow => workflow.workflowStatus == this.state.workflowStatus)
        }
        this.setState({
            workflows: filteredWorkflows
        })
    }

    render() {
        if (!(this.state.ldap.includes("bart-redrives") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Get Bulk Redrive Request Status'} description={'Check the details of a bulk redrive request by request id'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can get bulk redrive request status!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        let lis:string[]=[];
        lis.push('Please input a bulk request id from a bulk redrive request');
        lis.push('You can select optional filter fields to filter out workflows from the bulk redrive status table');
        return (
          <>
              <FullPageTitle title={'Get Bulk Redrive Request Status'} description={'Check the details of a bulk redrive request by request id'}/>
              <div className={this.state.searched ? '' : 'page'}>
                  <SpaceBetween size="l">
                      <BulkRedriveStatusSearchBar requestId={this.state.requestId} setRequestId={this.setRequestId} onClickSearch={this.search}
                                                  workflowType={this.state.workflowType} sentToRedriveSQS={this.state.sentToRedriveSQS} successfullySentToRedrive={this.state.successfullySentToRedrive}
                                                  sentToWorkflowStatusSQS={this.state.sentToWorkflowStatusSQS} workflowStatus={this.state.workflowStatus}
                                                  setWorkflowType={this.setWorkflowType} setSentToRedriveSQS={this.setSentToRedriveSQS}
                                                  setSuccessfullySentToRedrive={this.setSuccessfullySentToRedrive} setWorkflowStatus={this.setWorkflowStatus}
                                                  setSentToWorkflowStatusSQS={this.setSentToWorkflowStatusSQS}/>
                      {this.state.searched ? null : <ReminderBlock title='Information' lis ={lis}/>}
                      {this.state.invalidInputs ? <div>
                          <Grid
                              gridDefinition={[
                                  {colspan: {l: 7, xxs: 10}},
                              ]}
                          >
                              <Alert header="Invalid input! Bulk redrive request id field cannot be empty" type="error"></Alert>
                          </Grid>
                      </div>: null}
                      {this.state.payload === "loading" ? <Spinner size = "large"></Spinner>: null}
                      {this.state.payload === "result" ? <StatusTable statusByRequestIdList={this.state.workflows}/>: null}
                  </SpaceBetween>
              </div>
          </>
        );
    }
}
