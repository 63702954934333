import React, {createContext, useState} from "react";
import {BartRoutes} from "src/routing/bartRoutes";
import  {InitDomain} from "../context/domain/domainContext"
import {Domain} from "src/config/BartLambdaConfig";
import {configureDomain} from "src/auth/config";
import {FullPageHeader} from "src/components/header/header";
export const RouteMap = ()=> {
    const [domain, setDomain] = useState<Domain>(configureDomain);
    const [initialLoading, setInitialLoading] = useState<boolean>(false);
    if (initialLoading) return <p>loading...</p>;

    return (
            <InitDomain.Provider
                value={{
                    domain,
                    setDomain
                }}
            >
              <FullPageHeader/>
              <BartRoutes domain = {domain}/>
            </InitDomain.Provider>
        )
}


