import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import ArdSearchBar from '../fuseArdPage/ard-search-bar';
import PayloadDisplayArd from 'src/lambda/ardServiceLambda/payload-display-ard';
import {Alert, Button, Grid, Header, SpaceBetween} from '@amzn/awsui-components-react';
import "src/index.scss";
import {BaseArd} from "src/model/ard";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Domain} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import {inputValidation} from "src/commons/input-validation";

export class AppArd extends React.Component <any, { domain: Domain, authenticating: boolean, isLoggedIn: boolean,
    show: boolean, keyword: string, searched: boolean, payload: any, ldap: string, invalidInputs: boolean}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            domain: this.props.domain,
            authenticating: true,
            isLoggedIn: false,
            show: false,
            keyword: window.location.pathname.split("/")[2]|| "",
            searched: false,
            payload: "init",
            ldap: "",
            invalidInputs: false,
        };
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
        if(this.state.keyword){
            this.search(this.state.domain);
        }
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    search(domain: Domain) {
        this.setState({
            invalidInputs: false,
            searched: false,
            payload: "init",
        });
        if (inputValidation(this.state.keyword)) {
            this.setState({
                invalidInputs: true,
            });
        } else {
            this.setState({
                searched: true,
                payload: "init"
            });

            const payload = {"methodName": "GET", "path": "/ard/{ardId}", "parameter": {"ardIdKey": this.state.keyword}}
            return invokeBartArdLambda(payload, this.callback, domain)
        }
    }

    callback (response: any) {
        let ardData: BaseArd = JSON.parse(response);
        this.setState({
            payload: ardData
        });
    }

    render() {
        if (!(this.state.ldap.includes("bart-read-only") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Get ARD Info'} description={'Check the detail of ARD information based on ARD ID'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can get ARD information!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        return (
            <>
                <FullPageTitle title={'Get ARD Info'} description={'Check the detail of ARD information based on ARD ID'}/>
                <div className={this.state.searched ? '' : 'page'}>
                <SpaceBetween size = "l">
                        <ArdSearchBar keyword = {this.state.keyword} setValue = {this.setValue} onClickSearch = {this.search}/>
                            {this.state.invalidInputs ? <div>
                                <Grid
                                    gridDefinition={[
                                        {colspan: {l: 7, xxs: 10}},
                                    ]}
                                >
                                    <Alert header="Invalid input! ARD id field cannot be empty" type="error"></Alert>
                                </Grid>
                            </div>: null}
                            {this.state.searched ?
                                <PayloadDisplayArd searchResults = {this.state.payload} searchId = {this.state.keyword}/>:
                                null
                            }
                    </SpaceBetween>
                </div>
            </>
        );
    }
}

export default AppArd;

