import React from 'react';
import {Domain, getBartLambdaConfig} from 'src/config/BartLambdaConfig';
import AuthService from "src/auth/auth-service";
import {FullPageTitle} from "src/components/header/title";
import {
    Alert,
    Box,
    Button, FormField,
    Grid, Input,
    Modal,
    NonCancelableCustomEvent,
    SpaceBetween,
    Spinner
} from "@amzn/awsui-components-react";
import InputBulkRedrive from "src/components/bulkRedriveWorkflowPage/input-bar";
import BulkRedriveTable from "src/components/bulkRedriveWorkflowPage/editable-table-display";
import {ReminderBlock} from "src/components/reminder-block";
import invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda
    from "src/lambda/bulkRedriveWorkflowLambda/invoke-lambda";
import {InitDomain} from "src/context/domain/domainContext";
import {v4 as uuid} from 'uuid';
import Logo from "src/resources/clipboard.png";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {inputValidation} from "src/commons/input-validation";
import {ExternalLinkGroup} from "src/pages/commons";
import {configureDomain} from "src/auth/config";

export class AppSortedBulkRedrive extends React.Component<any, { domain: Domain, authenticating: boolean,
    isLoggedIn: boolean, show: boolean, fileInfo: any[], previewed: boolean, payload: any, workflowType: string,
    eventVersionOverride: string, isModalOpen: boolean, requestId: string, unsuccessfulList: any[], ldap: string, reasoning: string, errored: string}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            domain: this.props.domain,
            authenticating: true,
            isLoggedIn: false,
            show: false,
            fileInfo: [],
            previewed: false,
            payload: "init",
            workflowType: "FuseBillingWorkflow",
            eventVersionOverride: "",
            isModalOpen: false,
            requestId: "",
            unsuccessfulList: [],
            ldap: "",
            reasoning: "",
            errored: "",
        };
        this.setFileInfo = this.setFileInfo.bind(this);
        this.setWorkflowType = this.setWorkflowType.bind(this);
        this.setEventVersionOverride = this.setEventVersionOverride.bind(this);
        this.preview = this.preview.bind(this);
        this.callback = this.callback.bind(this);
        this.submit = this.submit.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setFileInfo (value: any[]) {
        this.setState({fileInfo: value});
    }

    setWorkflowType (value: string) {
        this.setState({workflowType: value, previewed: false});

    }

    setEventVersionOverride (value: string) {
        this.setState({eventVersionOverride: value});
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }
    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        this.setState({['reasoning']: event.detail.value} as any)
    }

    preview (domain: Domain) {
        this.setState({
            previewed: true,
            payload: "preview"
        });
    }

    callback (response: any) {
        if (response === "error") {
            this.setState({
                ...this.state,
                errored: "There is at least one errored batch that failed to send to redrive, check console logs",
                payload: "result",
            });
        } else {
            this.setState({
                ...this.state,
                payload: "result",
                requestId: response,
                isModalOpen: true,
            });
        }
        console.log("finish")
    }

    batchCallback (response: any) {
        if (response === "error") {
            this.setState({
                ...this.state,
                errored: "There is at least one errored batch that failed to send to redrive, check console logs",
            })
        }
        console.log("batch")
    }

    submit (domain: Domain) {
        const requestIDUUID: string = uuid().toString();
        this.setState({
            payload: "waiting"
        });
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "Bulk Redrive",
                        additionalInfo: "Bulk Request Id: " + requestIDUUID,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })
        if(this.state.fileInfo.length == 0) {
            return invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda(requestIDUUID, this.state.fileInfo, domain, this.callback)
        }
        const batchSize = 1250;
        const remainderBatch = this.state.fileInfo.length % 1250;
        let numOfFullBatches = Math.floor(this.state.fileInfo.length / 1250);
        if(remainderBatch == 0) {
            numOfFullBatches -= 1;
        }
        let count = 0;
        for (let i = 0; i < this.state.fileInfo.length; i += batchSize) {
            const batch = this.state.fileInfo.slice(i, i + batchSize);
            if (count < numOfFullBatches) {
                count += 1;
                invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda(requestIDUUID, batch, domain, this.batchCallback)
            } else {
                return invokeFuseBartBillingGatewayServiceBulkRedriveWorkflowLambda(requestIDUUID, batch, domain, this.callback)
            }
        }
    }

    render() {
        if (!(this.state.ldap.includes("bart-redrives") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Bulk Redrive Workflow'} description={'Bulk redrive requests'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can perform a bulk redrive!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        const Domain = configureDomain;
        const bartLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://prod-jp.bart-portal.fuse.amazon.dev/bulkredrivestatus/';
                case 'prod-US':
                    return 'https://prod-us.bart-portal.fuse.amazon.dev/bulkredrivestatus/';
                case 'prod-EU':
                    return 'https://prod-eu.bart-portal.fuse.amazon.dev/bulkredrivestatus/';
            }
            return 'https://beta.bart-portal.fuse.amazon.dev/bulkredrivestatus/';
        })(Domain);
        let lis:string[]=[];
        if (this.state.payload === "result") {
            lis.push("Bulk Redrive Request Id: " + this.state.requestId);
        }
        if (this.state.errored !== "") {
            lis.push(this.state.errored);
        }
        const copyMessage = (val: string) => {
            navigator.clipboard.writeText(val);
        };
        return (
            <InitDomain.Consumer>
                {context =>(
                    <div>
                        <FullPageTitle title={'Bulk Redrive Workflow'} description={'Bulk redrive requests'}/>
                        <div className="page">
                            <SpaceBetween size="l">
                                {(this.state.previewed == false) ?
                                    <div>
                                        <SpaceBetween size="l">
                                            <InputBulkRedrive fileInfo={this.state.fileInfo} setFileInfo={this.setFileInfo}
                                                              workflowType={this.state.workflowType} setWorkflowType={this.setWorkflowType}
                                                              eventVersionOverride={this.state.eventVersionOverride} setEventVersionOverride={this.setEventVersionOverride}
                                                              onClickPreview={this.preview} reasoning={this.state.reasoning}/>
                                            <FormField
                                                label="Bulk Redrive Reasoning"
                                                description="Why you are performing this bulk redrive"
                                                i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                            >
                                                <Input
                                                    placeholder="Please enter your reason to perform a bulk redrive"
                                                    ariaRequired={true}
                                                    value={this.state.reasoning}
                                                    onChange={(event)=>this.setReasoning(event)}
                                                />
                                            </FormField>
                                            <ReminderBlock title='Information'
                                                           lis={['Please upload a csv file with no more than 500,000 external ids as the first column',
                                                               'Select a workflow type for the redrive',
                                                               'Event version override is an optional field for FUSEN workflows'
                                                           ]}/>
                                        </SpaceBetween>
                                    </div>
                                    : null}
                                {(this.state.previewed == true) ?
                                    <BulkRedriveTable fileInfo={this.state.fileInfo} setFileInfo={this.setFileInfo}
                                                      workflowType={this.state.workflowType} eventVersionOverride={this.state.eventVersionOverride}
                                                      domain={context.domain} onClickSubmit={this.submit} />
                                    : null}
                                {this.state.payload === "waiting" ? <Spinner size = "large"></Spinner>: null}
                                {(this.state.payload === "result") ? (
                                    <div id="redrive-result" >
                                        <ReminderBlock title="Bulk Redrive has been submitted" lis={lis}/>
                                        <Button onClick={() => copyMessage(this.state.requestId.toString())}><img src={Logo} width={10}/></Button>
                                        <div style={{paddingLeft: '10px'}}>
                                            <ExternalLinkGroup
                                                items={[
                                                    {
                                                        href: (bartLink+this.state.requestId),
                                                        text: 'Track bulk redrive status progress on BART',
                                                    },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                <Modal
                                    visible={this.state.isModalOpen}
                                    onDismiss={this.setCloseModal}
                                    header={'Bulk Redrive Request Information'}
                                    closeAriaLabel="Close dialog"
                                    footer={
                                        <Box float="right">
                                            <SpaceBetween direction="horizontal" size="xs">
                                                <Button variant="link" onClick={() => copyMessage(this.state.requestId.toString())}>Copy Request Id</Button>
                                                <Button variant="primary" onClick={this.setCloseModal}>Confirm</Button>
                                            </SpaceBetween>
                                        </Box>
                                    }
                                >
                                    <SpaceBetween size="m">
                                        <Box variant="span">
                                            {inputValidation(this.state.reasoning) ?
                                                <Alert statusIconAriaLabel="Info">You must input your reason for performing a bulk redrive.</Alert>
                                                : <Alert type="info" header={"Bulk Redrive Request Id: " + this.state.requestId}></Alert>
                                            }
                                        </Box>
                                    </SpaceBetween>
                                </Modal>
                            </SpaceBetween>
                        </div>
                    </div>)}
            </InitDomain.Consumer>
        );
    }
}

export default AppSortedBulkRedrive;
