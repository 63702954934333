import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";

export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>DLQ Mover Workflow Resource</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Move or Get the DLQ Messages with SQS Queue name.</p>
            <ul>
                <li>
                    Please input the SQS Queue name and operation to be done.
                </li>
            </ul>
        </HelpPanel>
    );
}
