import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig"; // npm install aws-sdk

function invokeDLQMoverWorkflowLambda (commandValue: string, queueName: string,
                                                                   domain: Domain, callback: Function) {

    // callback function have the response from the lambda
    // domain is to get the bart beta configs 
    const lambdaConfig = getBartLambdaConfig(domain)
    return Auth.currentCredentials()
        .then(credentials => {
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: lambdaConfig.awsRegion,
            }); // creating authentication
            let param = {
                FunctionName: lambdaConfig.bartDLQMoverLambdaARN,
                InvocationType: 'RequestResponse', // look at the aws doc for the invocation type of the lambda I'm using
                Payload: JSON.stringify({"command": commandValue, "queue": queueName}) // info req to call the api
            };

            return lambda.invoke(param, function(err, data) {
                //callback function
                console.log(data.Payload as string);
                // change it based on the error
                if ((data.Payload as string).includes("response")) {
                    return callback(JSON.parse(data.Payload as string)["response"]);
                } else if ((data.Payload as string).includes("NullPointerException")) {
                    return callback("NullPointerException");
                } else if ((data.Payload as string).includes("WorkItemNotClosedException") ||
                    (data.Payload as string).includes("Can not access the queue")) {
                    return callback("open");
                }
                return callback("error");
            })
        });
}

export default invokeDLQMoverWorkflowLambda; 
// when ever you invoke the function you have to export it so that other functions can use it
