import React, {useEffect, useRef, useState} from "react";
import {
    Alert,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    TextFilterProps
} from "@amzn/awsui-components-react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import { TableEmptyState, TableNoMatchState } from "../commons/common-components";
import { FuseArdServiceV2Client, ListRagaAvailabilitiesCommand, ListRagaAvailabilitiesCommandOutput, RagaAvailability } from '@amzn/fuse-ard-service-v2-client';
import { Auth } from "aws-amplify";
import { FUSE_ARD_SERVICE_V2_ENDPOINT } from "../../config/fuse-ard-service-v2-config";

type RagaAvailabilities = RagaAvailability[];

const COLUMN_DEFINITIONS = [
    {
        id: "buyingCustomerCountryCode",
        header: "Buying customer country code",
        cell: (item: RagaAvailability) => item.buyingCustomerCountryCode,
        width: 100,
        sortingField: "buyingCustomerCountryCode",
    },
    {
        id: "product",
        header: "Product",
        cell: (item: RagaAvailability) => item.product,
        width: 100,
        sortingField: "product",
    },
    {
        id: "term",
        header: "Term",
        cell: (item: RagaAvailability) => item.term,
        width: 100,
        sortingField: "term",
    },
    {
        id: "ragaEnabled",
        header: "Raga enabled in",
        cell: (item: RagaAvailability) => item.ragaEnabled,
        width: 100
    }
]

const RagaAvailabilityTable = () => {
    const [allItems, setAllItems] = useState<RagaAvailabilities>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);
    const [shouldFetchFromFlare, setShouldFetchFromFlare] = useState<boolean>(false);

    const [lastUpdatedOn, setLastUpdatedOn] = useState<Date>();
    const [cacheAge, setCacheAge] = useState<string>();

    const calculateAndSetCacheAge = (lastUpdatedOn: Date | undefined, setCacheAge: (age: string) => void) => {
        if (!lastUpdatedOn) {
            return;
        }
        const currentTime = new Date();
        const timeDifference = currentTime.getTime() - lastUpdatedOn.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        let age;
        if (days > 0) {
            age = `${days} day${days > 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            age = `${hours} hour${hours > 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            age = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        } else {
            age = `${seconds} second${seconds > 1 ? 's' : ''} ago`;
        }
        setCacheAge(age);
    };

    useEffect(() => {
        calculateAndSetCacheAge(lastUpdatedOn, setCacheAge);
        const interval = setInterval(() => calculateAndSetCacheAge(lastUpdatedOn, setCacheAge), 1000);

        return () => clearInterval(interval);
    }, [lastUpdatedOn, setCacheAge]);

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadItems = async () => {
        setLoading(true);
        setFetchError(null);
        await Auth.currentCredentials().then(credentials => {
            const client = new FuseArdServiceV2Client({
                endpoint: FUSE_ARD_SERVICE_V2_ENDPOINT,
                region: 'us-west-2',
                credentials: Auth.essentialCredentials(credentials),
            });

            // Add the Accept header in the request so that APIGW will transform the base64 encoded response body to CBOR
            client.middlewareStack.add((next: any) => (args: any) => {
                args.request.headers.accept = 'application/cbor';
                return next(args);
            }, { name: 'x', override: true, step: 'build' });

            client.send(new ListRagaAvailabilitiesCommand({forceFetchFromFlare: shouldFetchFromFlare}))
            .then((response: ListRagaAvailabilitiesCommandOutput) => {
                if (!response.ragaAvailabilities) {
                    console.error("ListRagaAvailabilitiesCommandOutput", response);
                    throw new Error("Malformed response!");
                }
                setLastUpdatedOn(response.lastUpdatedOn);
                calculateAndSetCacheAge(lastUpdatedOn, setCacheAge);
                setAllItems(response.ragaAvailabilities);
            }).catch((error: unknown) => {
                setFetchError(error instanceof Error ? error.message : String(error));
            }).finally(() => {
                setLoading(false);
            });
        });
    };

    useEffect(() => {
        loadItems();
    }, [shouldFetchFromFlare]);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                fields: ['buyingCustomerCountryCode'],
                empty: (<TableEmptyState resourceName="Raga availability" />),
                noMatch: (<TableNoMatchState onClearFilter={() => actions.setFiltering('')} />)
            },
            pagination: {
                pageSize: 100
            },
            sorting: {
                defaultState: {
                    sortingColumn: COLUMN_DEFINITIONS[0],
                }
            },
            selection: {}
        }
    );
    const selectedItems = collectionProps.selectedItems!;

    return (
        <SpaceBetween size="m">
            {lastUpdatedOn &&
                <Alert
                    action={
                        <Button
                            onClick={() => setShouldFetchFromFlare(true)}
                            disabled={loading}
                            disabledReason="Fetching from Flare"
                        >Fetch from Flare</Button>
                    }
                >
                    {`Last fetched from Flare on ${lastUpdatedOn} (${cacheAge}).`}
                </Alert>
            }
            <Table
                {...collectionProps}
                wrapLines={false}
                loading={loading}
                loadingText="Loading Raga availabilities"
                stickyHeaderVerticalOffset={1}
                variant='full-page'
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                        actions={null}
                    >
                        Raga availabilities
                    </Header>
                }
                columnDefinitions={COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        ref={textFilterRef}
                        countText={`${filteredItemsCount} match(es)`}
                        filteringPlaceholder="Filter by country code"
                    />
                }
            />
        </SpaceBetween>
    );
}

export default RagaAvailabilityTable;
