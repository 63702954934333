import React from 'react';
import {Alert, Grid, Spinner} from "@amzn/awsui-components-react";
import '../../pages/commons/common.styles.scss';

interface PayloadProps {
    searchResults: any,
    searchId: string
}

export class PayloadDisplayArd extends React.Component <PayloadProps, {searchId: string}> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchId: this.props.searchId,
        };
    }

    render () {
        const {searchResults, searchId} = this.props;
        if (searchResults != "init") {
            if (searchResults == "invalid-ard") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="No results found!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            if (searchResults == "error") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="Search timed out!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            return (
                <pre className = "sourceInfo">{JSON.stringify(searchResults, null, 2)}</pre>
            )
        } else {
            return <Spinner size = "large"></Spinner>;
        }
    }
}

export default PayloadDisplayArd;
