import { AppLayoutProps } from '@amzn/awsui-components-react';

export const appLayoutAriaLabels: AppLayoutProps.Labels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  notifications: 'Notifications',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel',
};
