import React, { useState } from "react";
import { AppLayout, AppLayoutProps, Container, ContentLayout, Header, NonCancelableCustomEvent } from "@amzn/awsui-components-react";
import { Navigation } from "../commons";
import { URLS } from "../../components/constants/appConstants";
import { HelpPane } from "./help-pane";
import RagaAvailabilityTable from "./raga-availability-table";

export function RagaAvailabilities() {
    const [navigationOpen, setNavigationOpen] = React.useState(true);
    const [toolsOpen, setToolsOpen] = useState(true);

    const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
        setToolsOpen(detail.open);
    };
    return (
        <AppLayout
            content={
                <ContentLayout
                    header={<Header variant="h1">View Raga availabilities</Header>}>
                    <Container>
                        <RagaAvailabilityTable/>
                    </Container>
                </ContentLayout>
            }
            navigation={<Navigation activeHref={URLS.RAGA_AVAILABILITIES} />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            tools={<HelpPane/>}
            toolsOpen={toolsOpen}
            onToolsChange={onToolsChangeFn}
        />
    );
}
