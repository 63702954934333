import React, {useContext} from 'react';
import {InitDomain} from "src/context/domain/domainContext";
import {Button} from "@amzn/awsui-components-react";
import {DateRangeTableFilter} from "src/components/daterangetablefilter";


interface Props {
    setValue: Function;
    setDateRange: Function;
    onClickSearch: Function;
}

const BartAuditHistorySearchBar = ({
    onClickSearch,
    setDateRange,
}: Props) => {
    const domainData = useContext(InitDomain);

    const handleDateRangeFilter = (startDate: string, endDate: string) => {
        startDate = startDate.substring(0, 4) + "-" + startDate.substring(5, 7) + "-" + startDate.substring(8, 10) + " " + startDate.substring(11, 19)
        endDate = endDate.substring(0, 4) + "-" + endDate.substring(5, 7) + "-" + endDate.substring(8, 10) + " " + endDate.substring(11, 19)
        setDateRange(startDate, endDate);
    };

    return (
        <div style={{paddingLeft: '10px'}}>
            <div className="input-container">
                <div className="date-range-filter">
                    <DateRangeTableFilter onFilter={handleDateRangeFilter} />
                </div>
                <Button onClick = {()=>onClickSearch(domainData.domain)}>View BART History</Button>
            </div>
        </div>
    );
}

export default BartAuditHistorySearchBar;
