import React, {useState} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Link} from "react-router-dom";

import './i18n';

import './index.scss';
import './styles/base.scss';
import {Amplify} from 'aws-amplify';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.
import {configureAmp} from 'src/auth/config';
import {RouteMap} from "src/routing/routes";
import 'bootstrap/dist/css/bootstrap.css';

Amplify.configure(configureAmp);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
          <BrowserRouter>
            <RouteMap />
          </BrowserRouter>
    </React.StrictMode>
);
