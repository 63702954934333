import React, {useRef, useState} from "react";
import {AppLayout, AppLayoutProps, NonCancelableCustomEvent} from '@amzn/awsui-components-react';
import {  Navigation } from 'src/pages/commons';
import {URLS} from "src/components/constants/appConstants";
import {ToolsContent} from "./components/common-components";
import '../commons/common.styles.scss'
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
import {AppArdNewPlanMapping} from "src/components/ardNewPlanMappingPage/ard-new-plan-mapping-page";

export function ArdNewPlanMapping(){
    const [navigationOpen, setNavigationOpen] = React.useState(false);
    const appLayout = useRef<AppLayoutProps.Ref>(null);
    const [toolsOpen, setToolsOpen] = useState(false);
    const loadPanelFn = () => {
        setToolsOpen(true);
        appLayout.current?.focusToolsClose();
    };

    const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
        setToolsOpen(detail.open);
    };
    return (
        <AppLayout
            disableContentPaddings
            content={<AppArdNewPlanMapping/>}
            navigation={<Navigation activeHref={URLS.ARD_NEW_PLAN_MAPPING}/>}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            tools={<ToolsContent />}
            toolsOpen={toolsOpen}
            onToolsChange={onToolsChangeFn}
            ariaLabels={appLayoutAriaLabels}
        />
    );
}
