import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import {
    Alert,
    Box,
    Button,
    Container,
    Form,
    FormField, Grid,
    Header,
    Input,
    Modal,
    NonCancelableCustomEvent,
    Select,
    SpaceBetween, Spinner
} from '@amzn/awsui-components-react';
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import invokeFuseBartBillingGatewayServiceAPIInvokeLambda from "src/lambda/fbgsAPIInvokeLambda/invoke-lambda";
import {InitDomain} from "src/context/domain/domainContext";
import {ReminderBlock} from "src/components/reminder-block";
import {configureDomain} from "src/auth/config";
import {ExternalLinkGroup} from "src/pages/commons";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {inputValidation} from "src/commons/input-validation";

export class AppRedrive extends React.Component<{}, {externalId: string, workflowType: string,
    eventVersionOverride: string, redriveLatestWorkItem: string, searched: boolean, isModalOpen: boolean, payload: any,
    message: any, objectId: any, ldap: string, reasoning: string}> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            externalId: "",
            workflowType: "FuseBillingWorkflow",
            eventVersionOverride: "",
            redriveLatestWorkItem: "false",
            searched: false,
            payload: "init",
            isModalOpen: false,
            message: "",
            objectId: "",
            ldap: "",
            reasoning: "",
        };
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
        this.setRedriveLatestWorkItem = this.setRedriveLatestWorkItem.bind(this);
        this.setEventVersionOverride = this.setEventVersionOverride.bind(this);
        this.setWorkflowType = this.setWorkflowType.bind(this);
        this.setExternalId = this.setExternalId.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({
                ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }
    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    setExternalId(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({externalId: value})
    }

    setWorkflowType(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({workflowType: value})
        console.log(this.state.workflowType);
    }

    setEventVersionOverride(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({eventVersionOverride: value})
    }

    setRedriveLatestWorkItem(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({redriveLatestWorkItem: value})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.value;
        this.setState({reasoning: value})
    }

    search (domain: Domain){
        this.setCloseModal();
        this.setState({
            searched: true,
            payload: "init"
        });
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "Singular Redrive",
                        additionalInfo: `External Id: ${this.state.externalId}, Workflow Type: ${this.state.workflowType}, Event Version Override: ${this.state.eventVersionOverride}`,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })
        const payload = {"methodName": "POST", "path": "/RedriveWorkflow", "parameter": {"externalId": this.state.externalId,
                "workflowType": this.state.workflowType, "eventVersionOverride": this.state.eventVersionOverride,
                "redriveLatestWorkItem": this.state.redriveLatestWorkItem}}
        invokeFuseBartBillingGatewayServiceAPIInvokeLambda(payload, domain, this.callback)
    }

    callback (response: any){
        if (response === "invalid") {
            this.setState({
                ...this.state,
                payload: "invalid"
            });
        } else if (response === "open") {
            this.setState({
                ...this.state,
                payload: "open"
            });
        } else if (response === "error") {
            this.setState({
                ...this.state,
                payload: "error"
            })
        } else {
            this.setState({
                ...this.state,
                payload: "result",
                message: response["message"],
                objectId: response["objectId"]
            });
        }
    }

    render() {
        if (!(this.state.ldap.includes("bart-redrives") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Redrive Workflow'} description={'Redrive a workflow by external id'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can perform a redrive!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        const Domain = configureDomain;
        const herdLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://us-west-2.studio.orca.amazon.dev/#/clients/fuse-billing/executions/';
                case 'prod-US':
                    return 'https://us-east-1.studio.orca.amazon.dev/#/clients/fuse-billing/executions/';
                case 'prod-EU':
                    return 'https://eu-west-1.studio.orca.amazon.dev/#/clients/fuse-billing/executions/';
            }
            return 'https://beta.us-west-2.studio.orca.amazon.dev/#/clients/fuse-billing/executions/';
        })(Domain);
        let lis:string[]=[];
        if (this.state.payload === "result") {
            lis.push("Redrive " + this.state.message);
            lis.push("ObjectId: " + this.state.objectId);
        }
        return (
            <InitDomain.Consumer>
                {context => (
                <div>
                    <FullPageTitle title={'Redrive Workflow'} description={'Redrive a workflow by external id'}/>
                    <SpaceBetween size="l">
                        <form onSubmit={event => event.preventDefault()}>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant="primary"
                                                onClick={this.setOpenModal}>
                                            Redrive Workflow
                                        </Button>
                                    </SpaceBetween>
                                }
                                errorIconAriaLabel="Error"
                            >
                                <SpaceBetween size="l">
                                    <Container
                                        id="origin-panel"
                                        className="custom-screenshot-hide"
                                        header={<Header variant="h2">Redrive Workflow Info</Header>}>
                                            <SpaceBetween size="l">
                                                <FormField
                                                    label="External ID"
                                                    description="The external id of the workflow that will be redrived"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                        <Input
                                                            placeholder="Please enter external id"
                                                            ariaRequired={true}
                                                            value={this.state.externalId}
                                                            onChange={(event) => this.setExternalId(event)}
                                                        />
                                                </FormField>
                                                <FormField
                                                    label="Workflow Type"
                                                    description="Choose workflow type"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                        <Select
                                                            data-testid="workflowType-filter"
                                                            options={[
                                                                {
                                                                    "value" : "FuseBillingWorkflow"
                                                                },
                                                                {
                                                                    "value" : "FUSENWorkflow"
                                                                },
                                                                // TODO: add reconciliation and revenue adjustment when redriveWorkflow API supports it
                                                            ]}
                                                            selectedAriaLabel="Selected"
                                                            selectedOption={{
                                                                "value": this.state.workflowType || "FuseBillingWorkflow"
                                                            }}
                                                            onChange={(event) => this.setWorkflowType(event)}
                                                            ariaDescribedby={''}
                                                            expandToViewport={true}
                                                        />
                                                </FormField>
                                                {this.state.workflowType === "FUSENWorkflow" ?
                                                    <FormField
                                                        label="Event Version Override"
                                                        description="Optional input for FUSEN workflow redrives"
                                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                            <Input
                                                                placeholder="Please enter integer value or keep blank for null"
                                                                ariaRequired={false}
                                                                value={this.state.eventVersionOverride}
                                                                onChange={(event) => this.setEventVersionOverride(event)}
                                                            />
                                                    </FormField>
                                                : null}
                                                <FormField
                                                    label="Redrive Latest Work Item"
                                                    description="Choose true to redrive the latest work item"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                        <Select
                                                            data-testid="redriveLatestWorkItem-filter"
                                                            options={[
                                                                {
                                                                    "value" : "true"
                                                                },
                                                                {
                                                                    "value" : "false"
                                                                },
                                                            ]}
                                                            selectedAriaLabel="Selected"
                                                            selectedOption={{
                                                                "value": this.state.redriveLatestWorkItem
                                                            }}
                                                            onChange={(event) => this.setRedriveLatestWorkItem(event)}
                                                            ariaDescribedby={''}
                                                            expandToViewport={true}
                                                        />
                                                </FormField>
                                                <FormField
                                                    label="Redrive Reasoning"
                                                    description="Why you are performing this redrive"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                >
                                                    <Input
                                                        placeholder="Please enter your reason to perform a redrive"
                                                        ariaRequired={true}
                                                        value={this.state.reasoning}
                                                        onChange={(event)=>this.setReasoning(event)}
                                                    />
                                                </FormField>
                                            </SpaceBetween>
                                    </Container>
                                </SpaceBetween>
                            </Form>
                            <Modal
                                visible={this.state.isModalOpen}
                                onDismiss={this.setCloseModal}
                                header={'Redrive Request'}
                                closeAriaLabel="Close dialog"
                                footer={
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="xs">
                                            {(inputValidation(this.state.reasoning) || inputValidation(this.state.externalId)) ?
                                                <Button variant="primary" onClick={this.setCloseModal}>Input the missing fields</Button> :
                                                (<div>
                                                    <Button variant="link" onClick={this.setCloseModal}>Cancel</Button>
                                                    <Button variant="primary" onClick={() => this.search(context.domain)}>Submit Redrive</Button>
                                                </div>)
                                            }
                                        </SpaceBetween>
                                    </Box>
                                }
                            >
                                <SpaceBetween size="m">
                                    <Box variant="span">
                                        {(inputValidation(this.state.externalId) || inputValidation(this.state.reasoning)) ?
                                            <Alert type="error">Invalid input! External id and redrive reasoning fields cannot be empty</Alert>
                                            : null}
                                        {(this.state.eventVersionOverride != "" && !inputValidation(this.state.reasoning) && !inputValidation(this.state.externalId)) ? (
                                            <Alert type="warning" header="Are you sure you want to redrive with an event version override?"></Alert>
                                        ) : null}
                                        {(!inputValidation(this.state.reasoning) && !inputValidation(this.state.externalId)) ? (
                                            <Alert type="info" header="This only submits a redrive, progress on redrive will not be tracked."></Alert>
                                        ) : null}
                                    </Box>
                                </SpaceBetween>
                            </Modal>
                            {this.state.searched === true && this.state.payload === "init" ? (
                                <Spinner size = "large"></Spinner>
                            ) : null}
                            {this.state.payload === "invalid" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="No workflow was found with these inputs!" type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "open" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Redrive cannot be performed right now; work item is not closed." type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "error" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Redrive timed out, please try again!" type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "result" ? (
                                <div id="redrive-result">
                                    <ReminderBlock title="Success!" lis={lis}/>
                                    <div style={{paddingLeft: '10px'}}>
                                        <ExternalLinkGroup
                                            items={[
                                                {
                                                    href: (herdLink+(this.state.objectId.split(":", 1))+"%3A"+this.state.externalId+":::"+this.state.workflowType+":::"+(this.state.objectId.split("_")[1])).toString(),
                                                    text: 'Track redrive progress on Herd',
                                                },
                                            ]}
                                        />
                                    </div>
                                </div>
                            ) : null}
                        </form>
                    </SpaceBetween>
                </div>)}
            </InitDomain.Consumer>
        );
    }
}

export default AppRedrive;
