import React from 'react';
import {Alert, Grid, Header, SpaceBetween, Spinner, Table} from "@amzn/awsui-components-react";
import '../../pages/commons/common.styles.scss';
import Collapsible from "src/pages/commons/collapsible";
import {configureDomain} from "src/auth/config";
interface PayloadProps {
    searchResults: any,
    searchId: string
}

export class PayloadDisplayPlan extends React.Component <PayloadProps, {searchId: string}> {
    constructor(props: any) {
        super(props);
        this.state = {
            searchId: this.props.searchId,
        };
    }

    render () {
        const {searchResults, searchId} = this.props;
        const Domain =configureDomain;
        const planIdFenderLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://fender.corp.amazon.com/domains/prod-FE/plans/';
                case 'prod-US':
                    return 'https://fender.corp.amazon.com/domains/prod-NA/plans/';
                case 'prod-EU':
                    return 'https://fender.corp.amazon.com/domains/prod-EU/plans/';
            }
            return 'https://fender.corp.amazon.com/domains/devo-FE/plans/';
        })(Domain);
        const ardIdLink =((domain) => {
            switch (domain) {
                case 'prod-FE':
                    return 'https://prod-jp.bart-portal.fuse.amazon.dev/getard/';
                case 'prod-US':
                    return 'https://prod-us.bart-portal.fuse.amazon.dev/getard/';
                case 'prod-EU':
                    return 'https://prod-eu.bart-portal.fuse.amazon.dev/getard/';
            }
            return 'https://beta.bart-portal.fuse.amazon.dev/getard/';
        })(Domain);
        if (searchResults != "init") {
            if (searchResults == "invalid-fusePlan") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="No results found!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            if (searchResults == "error") {
                return (
                    <div>
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="Search timed out!" type="error"></Alert>
                        </Grid>
                    </div>);
            }
            let planDefinitions: any[] = [];
            planDefinitions.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            planDefinitions.push({
                id: "planId",
                header: "plan id",
                cell: (item: any) => (
                    <a href={planIdFenderLink+item["plan"]["id"].toString()} target="_blank">{item["plan"]["id"].toString()}</a>
                ),
            });
            planDefinitions.push({
                id: "ardId",
                header: "ard id",
                cell: (item: any) => (
                    <a href={ardIdLink+item["plan"]["ardId"].toString()} target="_blank">{item["plan"]["ardId"].toString()}</a>
                ),
            });
            planDefinitions.push({
                id: "fusenEnabled",
                header: "fusen enabled",
                cell: (item:any) => (item["plan"]["fusenEnabled"]).toString(),
            });
            planDefinitions.push({
                id: "glLineDescriptionPartnerName",
                header: "gl line description partner name",
                cell: (item:any) => (item["plan"]["glLineDescriptionPartnerName"]),
            });
            planDefinitions.push({
                id: "fusenEnableDate",
                header: "fusen enabled date",
                cell: (item:any) => (item["plan"]["fusenEnableDate"]),
            });
            planDefinitions.push({
                id: "migrationTargetStage",
                header: "migration target stage",
                cell: (item:any) => (item["plan"]["migrationTargetStage"]),
            });
            planDefinitions.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            let table = (<Table
                columnDefinitions={planDefinitions}
                items={[searchResults]}
                wrapLines = {false}
                resizableColumns = {false}
                header = {<Header>Plan Information</Header>}
            ></Table>);
            let ragaControlParameters: any[] = [];
            ragaControlParameters.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            ragaControlParameters.push({
                id: "term",
                header: "term",
                cell: (item:any) => (item["plan"]["ard"]["term"])
            });
            ragaControlParameters.push({
                id: "product",
                header: "product",
                cell: (item: any) => (item["plan"]["ard"]["product"])
            });
            ragaControlParameters.push({
                id: "glLineDescriptionPartnerName",
                header: "gl line description partner name",
                cell: (item: any) => (item["plan"]["glLineDescriptionPartnerName"])
            });
            ragaControlParameters.push({
                id: "businessCountryCode",
                header: "business country code",
                cell: (item: any) => (item["plan"]["ard"]["businessCountryCode"])
            });
            ragaControlParameters.push({
                id: "buyingCustomerCountryCode",
                header: "buying customer country code",
                cell: (item:any) => (item["plan"]["ard"]["buyingCustomerCountryCode"])
            });
            ragaControlParameters.push({
                id: "fusenEnabled",
                header: "fusen enabled",
                cell: (item: any) => (item["plan"]["fusenEnabled"].toString())
            });
            ragaControlParameters.push({
                id: "fusenEnableDate",
                header: "fusen enable date",
                cell: (item: any) => (item["plan"]["fusenEnableDate"])
            });
            ragaControlParameters.push({
                id: "migrationTargetStage",
                header: "migration target stage",
                cell: (item: any) => (item["plan"]["migrationTargetStage"])
            });
            ragaControlParameters.push({
                id: "",
                header: "",
                cell: () => ("")
            });
            let ragaTable = (<Table
                columnDefinitions={ragaControlParameters}
                items={[searchResults]}
                wrapLines = {false}
                resizableColumns = {false}
                header = {<Header>Important Source Fields for Plan</Header>}
            ></Table>);
            let ardInfo: any[] = [];
            ardInfo.push({
                id: "",
                header: "",
                cell: () => ("")
            });
            ardInfo.push({
                id: "id",
                header: "id",
                cell: (item: any) => (
                    <a href={ardIdLink+item["plan"]["ardId"].toString()} target="_blank">{item["plan"]["ardId"].toString()}</a>
                ),
            });
            ardInfo.push({
                id: "product",
                header: "product",
                cell: (item: any) => (item["plan"]["ard"]["product"])
            });
            ardInfo.push({
                id: "term",
                header: "term",
                cell: (item: any) => (item["plan"]["ard"]["term"])
            });
            ardInfo.push({
                id: "template",
                header: "template",
                cell: (item: any) => (item["plan"]["ard"]["template"])
            });
            ardInfo.push({
                id: "businessCountryCode",
                header: "business country code",
                cell: (item: any) => (item["plan"]["ard"]["businessCountryCode"])
            });
            ardInfo.push({
                id: "buyingCustomerCountryCode",
                header: "buying customer country code",
                cell: (item: any) => (item["plan"]["ard"]["buyingCustomerCountryCode"])
            });
            ardInfo.push({
                id: "functionalCurrency",
                header: "functional currency",
                cell: (item: any) => (item["plan"]["ard"]["functionalCurrency"])
            });
            ardInfo.push({
                id: "localCurrency",
                header: "local currency",
                cell: (item: any) => (item["plan"]["ard"]["localCurrency"])
            });
            ardInfo.push({
                id: "",
                header: "",
                cell: () => (""),
            });
            let ardInfoTable = (<Table
                columnDefinitions={ardInfo}
                items={[searchResults]}
                wrapLines = {false}
                resizableColumns = {false}
                header = {<Header>ARD Information for Plan</Header>}
            ></Table>);
            let source =
                (<Collapsible
                    open = {false}
                    title = {"Full Source for Plan " + searchResults["plan"]["id"]}
                >
                    <pre className = "sourceInfo ">{JSON.stringify(searchResults, null, 2)}</pre>
                </Collapsible>);
            return(
                <div style={{paddingLeft: '10px'}}>
                    <SpaceBetween size = "l">
                        {table}
                        {ragaTable}
                        {ardInfoTable}
                        {source}
                    </SpaceBetween>
                </div>
            );
        } else {
            return <Spinner size = "large"></Spinner>;
        }
    }
}

export default PayloadDisplayPlan;
