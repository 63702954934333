import { CookieConsent } from './types';

declare global {
    interface Window {
        AwsUiConsent: CookieConsent;
    }
}

const hasConsent = () => {
    if (!process.env.EXTERNAL_SITE) {
        return true;
    }

    if (typeof window.AwsUiConsent === 'undefined') {
        return false;
    }

    const cookieConsent = window.AwsUiConsent.getConsentCookie();
    return cookieConsent?.functional ?? false;
};

export const save = (key: string, value: any) => {
    if (hasConsent()) {
        sessionStorage.setItem(key, JSON.stringify(value));
    }
};

export const remove = (key: string) => sessionStorage.removeItem(key);

export const load = (key: string) => {
    const value = sessionStorage.getItem(key);
    try {
        return value && JSON.parse(value);
    } catch (e) {
        console.warn(
            `⚠️ The ${key} value that is stored in sessionStorage is incorrect. Try to remove the value ${key} from sessionStorage and reload the page`
        );
        return undefined;
    }
}
