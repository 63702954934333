import React, {ReactNode} from "react";
import {
    Autosuggest,
    Box,
    CollectionPreferencesProps,
    Input
} from "@amzn/awsui-components-react";
import {createTableSortLabelFn} from "src/i18n-strings/table";

const rawColumns = [
    {
        id: 'externalId',
        header: 'External ID',
        cell: (item: { externalId: any; }) => item.externalId,
        minWidth: 300,
        maxWidth: 300,
        sortingField: 'externalId'
    },
    {
        id: 'workflowType',
        header: 'Workflow Type',
        cell: (item: { workflowType: any; }) => item.workflowType,
        minWidth: 150,
        maxWidth: 150
    },
    {
        id: 'eventVersionOverride',
        header: 'Event Version Override',
        cell: (item: { eventVersionOverride: any; }) => item.eventVersionOverride,
        minWidth: 200,
        maxWidth: 200
    }
];

export const COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const paginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    visibleContent: ['externalId', 'workflowType', 'eventVersionOverride'],
    wrapLines: false,
    custom: 'table'
};

const editableColumns : {[index: string]: any} = {
    workflowType: {
        minWidth: 150,
        editConfig: {
            ariaLabel: 'Edit workflow',
            errorIconAriaLabel: 'Workflow Validation Error',
            editIconAriaLabel: 'editable',
            editingCell: (item: any, { setValue, currentValue }: any) => {
                const options = [
                    { value: 'FuseBillingWorkflow', label: 'FuseBillingWorkflow' },
                    { value: 'FUSENWorkflow', label: 'FUSENWorkflow' }
                ];
                return (
                    <Autosuggest
                        autoFocus={true}
                        value={currentValue ?? item.workflowType}
                        onChange={event => setValue(event.detail.value)}
                        options={options}
                        expandToViewport={true}
                        ariaLabel="Workflow Type"
                        clearAriaLabel="clear"
                        placeholder="Select an workflow"
                    />
                );
            },
        },
        cell: (item: any) => {
            return item.workflowType;
        },
    },
    externalId: {
        minWidth: 300,
        editConfig: {
            ariaLabel: 'Edit external id',
            errorIconAriaLabel: 'Extenal Id Validation Error',
            editIconAriaLabel: 'editable',
            editingCell: (item: any, { setValue, currentValue }: any) => {
                return (
                    <Input
                        autoFocus={true}
                        ariaLabel="Edit external id"
                        value={currentValue ?? item.externalId}
                        onChange={event => {
                            setValue(event.detail.value);
                        }}
                        placeholder="Enter external id"
                    />
                );
            },
        },
        cell: (item: any) => {
            return item.externalId;
        },
    },
    eventVersionOverride: {
        minWidth: 200,
        editConfig: {
            ariaLabel: 'Edit event version override',
            errorIconAriaLabel: 'Event Version Override Validation Error',
            editIconAriaLabel: 'editable',
            editingCell: (item: any, { setValue, currentValue }: any) => {
                return (
                    <Input
                        autoFocus={true}
                        ariaLabel="Edit event version override"
                        value={currentValue ?? item.eventVersionOverride}
                        onChange={event => {
                            setValue(event.detail.value);
                        }}
                        placeholder="Enter event version override"
                    />
                );
            },
        },
        cell: (item: any) => {
            return item.eventVersionOverride;
        },
    },
};

export const EDITABLE_COLUMN_DEFINITIONS = COLUMN_DEFINITIONS.map(column => {
    if (editableColumns[column.id]) {
        return {
            ...column,
            minWidth: Math.max(column.minWidth || 0, 176),
            ...editableColumns[column.id],
        };
    }
    return column;
});
