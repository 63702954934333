import React, {ReactNode} from "react";
import {
    Box,
    CollectionPreferencesProps
} from "@amzn/awsui-components-react";
import {createTableSortLabelFn} from "src/i18n-strings/table";

const rawColumns = [
    {
        id: 'operationType',
        header: 'Operation Type',
        cell: (item: { operationType: any; }) => item.operationType,
        minWidth: 80,
        maxWidth: 80,
        sortingField: 'operationType'
    },
    {
        id: 'userId',
        header: 'User Id',
        cell: (item: { userId: any; }) => item.userId,
        minWidth: 60,
        maxWidth: 60
    },
    {
        id: 'requestTime',
        header: 'Request Time',
        cell: (item: { requestTime: any; }) => item.requestTime,
        minWidth: 100,
        maxWidth: 100
    },
    {
        id: 'reasoning',
        header: 'Operation Reasoning',
        cell: (item: { reasoning: any; }) => item.reasoning,
        minWidth: 175,
        maxWidth: 175
    },
    {
        id: 'additionalInfo',
        header: 'Additional Info',
        cell: (item: { additionalInfo: any; }) => item.additionalInfo,
        minWidth: 250,
        maxWidth: 250
    },
];

export const AUDIT_COLUMN_DEFINITIONS = rawColumns.map(column => ({ ...column, ariaLabel: createTableSortLabelFn(column) }));

export function getMatchesCountText(count: number) {
    return count === 1 ? `1 match` : `${count} matches`;
}

interface EmptyStateProps {
    title: string;
    subtitle: string;
    action: ReactNode;
}
export function EmptyState({ title, subtitle, action }: EmptyStateProps) {
    return (
        <Box textAlign="center" color="inherit">
            <Box variant="strong" textAlign="center" color="inherit">
                {title}
            </Box>
            <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                {subtitle}
            </Box>
            {action}
        </Box>
    );
}

export const auditPaginationLabels = {
    nextPageLabel: 'Next page',
    pageLabel: (pageNumber: number) => `Go to page ${pageNumber}`,
    previousPageLabel: 'Previous page'
};

export const AUDIT_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    visibleContent: ['operationType', 'userId', 'requestTime', 'reasoning', 'additionalInfo'],
    wrapLines: false,
    custom: 'table'
};
