import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig"; // npm install aws-sdk

interface InvokeBartArdLambdaPayload {
    methodName: string;
    path: string;
    parameter?: any;
}
function invokeBartArdLambda (payload: InvokeBartArdLambdaPayload, callback: Function,domain:Domain) {
    const payloadString = JSON.stringify(payload)
    const lambdaConfig = getBartLambdaConfig(domain)
    return Auth.currentCredentials()
        .then(credentials => {
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: lambdaConfig.awsRegion,
            });
            let paramArd = {
                FunctionName: lambdaConfig.ardServiceLambdaARN,
                InvocationType: 'RequestResponse',
                Payload: payloadString
            };

            return lambda.invoke(paramArd, function(err, data) {
                // callback function
                if ((data.Payload as string).includes("businessCountryCode")) {
                    return callback(data.Payload, payload.methodName);
                }
                if(JSON.stringify(JSON.parse(data.Payload as string)).includes("No planId found with id")) {
                    return callback(JSON.stringify("nonExistent-fusePlan"))
                }
                if (JSON.stringify(JSON.parse(data.Payload as string)).includes("No ARD found with id") ||
                    JSON.stringify(JSON.parse(data.Payload as string)).includes("Can not find ardId for ardId")) {
                    return callback(JSON.stringify("invalid-ard"));
                }
                if ((data.Payload as string).includes("amzn1.fuse.ard")) {
                    return callback(data.Payload, payload.methodName);
                }
                if (JSON.stringify(JSON.parse(data.Payload as string)).includes("FusePlan already exists with id") ||
                    JSON.stringify(JSON.parse(data.Payload as string)).includes("Can not find plandId for planId")) {
                    return callback(JSON.stringify("invalid-fusePlan"));
                }
                if (JSON.stringify(JSON.parse(data.Payload as string)).includes("Can not find")) {
                    return callback(JSON.stringify("invalid"));
                }
                return callback(JSON.stringify("error")); //callback handle error
            })
        });
}
export default invokeBartArdLambda;
