import {Box, Grid} from "@amzn/awsui-components-react";
import React from "react";

interface Props {
  title: string;
  description: string
}

export const FullPageTitle = (props:Props)=> {
  return (
  <Box margin={{bottom: 'l'}}>
    <div className="custom-home__header">
      <Box padding={{vertical: 'xxxl', horizontal: 's'}}>
        <Grid
            gridDefinition={[
              {offset: {l: 1, xxs: 1}, colspan: {l: 8, xxs: 10}},
              {colspan: {xl: 6, l: 5, s: 6, xxs: 10}, offset: {l: 2, xxs: 1}},
              {colspan: {xl: 2, l: 3, s: 4, xxs: 10}, offset: {s: 0, xxs: 1}},
            ]}
        >
          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
              {props.title}
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">{props.description}</span>
            </Box>
          </div>
        </Grid>
      </Box>
    </div>
  </Box>
  )
}