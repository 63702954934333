import React, {useState} from 'react';
import {Button, Container, Header} from "@amzn/awsui-components-react";
import CloseCollapsible from '../../resources/closeCollapsible.png';
import OpenCollapsible from '../../resources/openCollapsible.png';
import Logo from "src/resources/clipboard.png";
interface IProps {
    open?: boolean;
    title: string;
    children: any;
}

const Collapsible: React.FC<IProps> = ({ open, children, title }) => {
    const [isOpen, setIsOpen] = useState(open);

    const handleFilterOpening = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <>
            <Container header = {<Header>{title}
                <Button onClick={handleFilterOpening}>
                    {!isOpen ? (<img src={OpenCollapsible} width={10}/>) : (<img src={CloseCollapsible} width={10}/>)}
                </Button>
            </Header>}>
                <div>{isOpen && <div className="p-3">{children}</div>}</div>
            </Container>
        </>
    );
};

export default Collapsible;
