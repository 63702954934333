import React from 'react';
import AuthService from "src/auth/auth-service";
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Fuseplan} from "src/model/fuse-plan";
import {
    Alert, Box,
    Button,
    Container,
    Form, FormField,
    Grid, Header, Input, Modal,
    NonCancelableCustomEvent,
    SpaceBetween
} from "@amzn/awsui-components-react";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {FullPageTitle} from "src/components/header/title";
import GetPlan from "src/components/remappingArdToPlanPage/get-plan";
import {Loader} from "src/components/remappingArdToPlanPage/loader";
import {InitDomain} from "src/context/domain/domainContext";
import Collapsible from "src/pages/commons/collapsible";
import {inputValidation} from "src/commons/input-validation";

interface PutRequestParameters {
    planIdKey: string;
    planArd: string;
}

export class AppPutPlanArd extends React.Component<{}, {
    ldap: string;
    id: string;
    ardId: string;
    storeArd: string;
    keyword: string;
    searched: boolean;
    found: boolean;
    isModalOpen: boolean;
    reasoning: string;
    payload: any }> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            ldap: "",
            id: "",
            ardId: "",
            storeArd: "",
            keyword: "",
            searched: false,
            found: false,
            isModalOpen: false,
            reasoning: "",
            payload: "init"
        };
        this.put = this.put.bind(this);
        this.setArd = this.setArd.bind(this);
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
        this.callback = this.callback.bind(this);
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    search(domain:Domain) {
        this.setState({
            searched: true,
            payload: "init"
        });
        const payload = {"methodName": "GET", "path": "/fuseplan/{planId}",
            "parameter": {"planIdKey":this.state.keyword}}
        return invokeBartArdLambda(payload, this.callback,domain)
    }

    setOpenModal() {
        this.setState({isModalOpen: true})
    }
    setCloseModal() {
        this.setState({isModalOpen: false})
    }

    callback (response: any) {
        if(JSON.parse(response).plan) {
            let planData: Fuseplan = JSON.parse(response).plan
            console.log(planData)
            this.setState({
                ...this.state,
                ...planData,
                storeArd: planData.ardId,
                payload: planData
            });
            console.log(this.state.storeArd)
        } else if (response == "\"invalid-fusePlan\"") {
            if (!this.state.found) {
                this.setState({
                    ...this.state,
                    payload: "invalid"
                });
            } else {
                this.setState({
                    ...this.state,
                    payload: "invalid-ard"
                });
            }
        } else {
            this.setState({
                ...this.state,
                payload: "error"
            });
        }
    }

    setArd(event: NonCancelableCustomEvent<any>) {
        this.setState({['ardId']: event.detail.value} as any)
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        this.setState({['reasoning']: event.detail.value} as any)
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({ldap: this.authObj.ldap});
        }
        fetchData();
    }

    put(domain: Domain) {
        this.setState({
            found: true
        })
        this.setCloseModal();
        this.setState({
            payload: "init"
        })
        const parameters: PutRequestParameters = {
            "planIdKey":this.state.id,
            "planArd":this.state.ardId,
        };

        const payload = {"methodName": "PUT", "path": "/fuseplan/{planId}", "parameter":
            parameters};
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "Re-Mapping ARD to Plan",
                        additionalInfo: "Plan Id: " + this.state.id,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })
        return invokeBartArdLambda(payload, this.callback,domain)
    }

    render() {
        if (!(this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Re-Mapping ARD to Plan'} description={'Re-Maps the ARD of a Fuse Plan'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can re-map ARD to a plan!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        if (!this.state.searched) {
            return (
                <GetPlan keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search}/>
            )
        }
        if (this.state.payload == "init") {
            return (
                <Loader/>
            );
        }
        if (this.state.payload == "invalid") {
            return (
                <GetPlan error ="invalid" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search}/>
            );
        }
        if (this.state.payload == "error") {
            return (
                <GetPlan error ="error" keyword={this.state.keyword} setValue={this.setValue} onClickSearch={this.search}/>
            );
        }

        const {id, ardId} = this.state

        return (
            <InitDomain.Consumer>
                {context =>(
                    <>
                        <FullPageTitle title={'Re-Mapping ARD to Plan'} description={'Re-Maps the ARD of a Fuse Plan'}/>
                        <SpaceBetween size="l">
                            <form onSubmit={event => event.preventDefault()}>
                                <Form actions={<SpaceBetween size="xs" direction="horizontal">
                                    <Button variant="primary" onClick={this.setOpenModal}>Re-Map ARD to Plan</Button>
                                    </SpaceBetween> }
                                    errorIconAriaLabel="Error"
                                >
                                    <SpaceBetween size="l">
                                        <Container
                                            id="origin-panel"
                                            className="custom-screenshot-hide"
                                            header={<Header variant="h2">Link ARD to a Plan</Header>}
                                        >
                                            <SpaceBetween size="l">
                                                <FormField
                                                     label="Plan Id"
                                                     description="The Fuse Plan ID"
                                                     i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                >
                                                    <Input
                                                         placeholder="Please enter plan id"
                                                         ariaRequired={true}
                                                         value={id}
                                                         disabled={true}
                                                    />
                                                </FormField>
                                                <FormField
                                                     label="ARD Id"
                                                     description="The ARD ID associated with the plan"
                                                     i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                >
                                                     <Input
                                                          placeholder="Please enter ARD id"
                                                          ariaRequired={true}
                                                          value={ardId}
                                                          onChange={(event)=>this.setArd(event)}
                                                     />
                                                </FormField>
                                                <FormField
                                                    label="Re-mapping Reasoning"
                                                    description="Why you are performing this re-mapping"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                >
                                                    <Input
                                                        placeholder="Please enter your reason to perform a re-mapping"
                                                        ariaRequired={true}
                                                        value={this.state.reasoning}
                                                        onChange={(event)=>this.setReasoning(event)}
                                                    />
                                                </FormField>
                                            </SpaceBetween>
                                        </Container>
                                    </SpaceBetween>
                                </Form>
                                <Modal
                                    visible={this.state.isModalOpen}
                                    onDismiss={this.setCloseModal}
                                    header={'Review changes'}
                                    closeAriaLabel="Close dialog"
                                    footer={
                                        <Box float="right">
                                            <SpaceBetween size="xs" direction="horizontal">
                                                {inputValidation(this.state.reasoning) || inputValidation(this.state.ardId) ?
                                                    <Button variant="primary" onClick={this.setCloseModal}>Update Inputs</Button> :
                                                    (<div>
                                                        <Button variant="link" onClick={this.setCloseModal}>Cancel</Button>
                                                        <Button variant="primary" onClick={() => this.put(context.domain)} data-testid="submit">Update</Button>
                                                    </div>)
                                                }
                                            </SpaceBetween>
                                        </Box>
                                    }
                                >
                                    <SpaceBetween size="m">
                                        {inputValidation(this.state.reasoning) || inputValidation(this.state.ardId) ?
                                            <Box variant="span">
                                                <Alert type="error" header="Invalid Input! ARD id and reasoning fields cannot be empty"></Alert>
                                            </Box>
                                            : <Box variant="span">
                                                Permanently delete distribution{' '}
                                                <Box variant="span" fontWeight="bold">
                                                    <section>
                                                        {this.state.storeArd !== this.state.ardId && <p>
                                                            Plan ARD Id has been changed
                                                            from {this.state.storeArd}&nbsp;to {this.state.ardId}
                                                        </p>}
                                                    </section>
                                                </Box>
                                                <Alert statusIconAriaLabel="Info">
                                                    You can't undo this action.
                                                </Alert>
                                            </Box>
                                        }
                                    </SpaceBetween>
                                </Modal>
                            </form>
                            {this.state.payload === "invalid-ard" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Ard not found!" type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : <Collapsible title={"Plan Preview"} open={false}>
                                <pre className="sourceInfo">{JSON.stringify(this.state.payload, null, 2)}</pre>
                            </Collapsible>}
                        </SpaceBetween>
                    </>
                )}
            </InitDomain.Consumer>
        );
    }
}

export default AppPutPlanArd;
