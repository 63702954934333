import React, {useEffect, useRef, useState} from "react";
import {Workflow} from "src/components/bulkRedriveWorkflowPage/index";
import {
    DEFAULT_PREFERENCES, EDITABLE_COLUMN_DEFINITIONS,
    EmptyState, getMatchesCountText,
    paginationLabels
} from "src/components/bulkRedriveWorkflowPage/table-config";
import {
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    TextFilterProps
} from "@amzn/awsui-components-react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {useColumnWidths} from "src/commons/use-column-widths";
import {Domain} from "src/config/BartLambdaConfig";

interface BulkRedriveTableProps {
    fileInfo: any[],
    setFileInfo: Function,
    workflowType: string,
    eventVersionOverride: string,
    domain: Domain,
    onClickSubmit: Function,
}

const BulkRedriveTable = ({fileInfo, setFileInfo, workflowType, eventVersionOverride, domain, onClickSubmit}: BulkRedriveTableProps) => {
    const [allItems, setAllItems] = useState<Workflow[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);
    const [columnDefinitions, saveWidths] = useColumnWidths('React-TableEditable-Widths', EDITABLE_COLUMN_DEFINITIONS);
    const onClick = () => {
        onClickSubmit(domain);
    };

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadItems = async () => {
        setLoading(true);
        setFetchError(null);
        let workflows : Workflow[] = [];
        try {
            for(let value of fileInfo) {
                let workflow: Workflow = {
                    externalId: value,
                    workflowType: workflowType,
                    eventVersionOverride: eventVersionOverride
                }
                workflows.push(workflow);
            }
            setAllItems(workflows as Workflow[]);
            setFileInfo(workflows as Workflow[]);
        } catch (error: unknown) {
            setFetchError(error instanceof Error ? error.message : String(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No workflows"
                        subtitle="No workflows to display."
                        action={null}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: { defaultState: { sortingColumn: columnDefinitions[0] } },
            selection: {}
        }
    );
    const selectedItems = collectionProps.selectedItems!;

    const handleSubmit = async (currentItem: any, column: any, value: any) => {
        let fullCollection: any = allItems;

        const newItem = { ...currentItem, [column.id]: value };

        let newCollection = fullCollection.map((item: any) => ((item === currentItem) ? newItem : item)) as Workflow[];
        setAllItems(newCollection);
        setFileInfo(newCollection);
    };

    return (
        <SpaceBetween size="m">
            <Table
                {...collectionProps}
                visibleColumns={preferences.visibleContent}
                onColumnWidthsChange={saveWidths}
                wrapLines={preferences.wrapLines}
                loading={loading}
                loadingText="Loading workflows"
                selectionType="multi"
                submitEdit={handleSubmit}
                stickyHeaderVerticalOffset={1}
                resizableColumns={true}
                variant='full-page'
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                        actions={null}
                    >
                        Workflows
                    </Header>
                }
                columnDefinitions={columnDefinitions}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        ref={textFilterRef}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringPlaceholder="Search Workflows"
                        filteringAriaLabel="Filter Workflows"
                    />
                }
            />
            <Button variant="primary" onClick={onClick}>Submit</Button>
        </SpaceBetween>
    );
}

export default BulkRedriveTable;
