import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";


export function ToolsContent() {
  return (
      <HelpPanel
          header={<h2>View FusePlan Resource</h2>}
          footer={
            <ExternalLinkGroup
                items={learnMoreLinks}
            />
          }
      >
        <p>View the Plan and related information.</p>
        <ul>
          <li>
            Please input the planID.
          </li>
        </ul>
      </HelpPanel>
  );
}
