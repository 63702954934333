import { DateRangePicker, DateRangePickerProps } from "@amzn/awsui-components-react";
import React from "react";
import { dateRangeFilterLabels } from '../commons/labels';

interface DateRangeTableFilterProps {
  onFilter: (startDate: string, endDate: string) => void;
}

const DEFAULT_VALUE: DateRangePickerProps.Value = {
  amount: 3,
  unit: "day",
  key: 'last-3-days',
  type: 'relative'
};

export function DateRangeTableFilter(props: DateRangeTableFilterProps) {
  const [value, setValue] = React.useState<DateRangePickerProps.Value | null>(DEFAULT_VALUE);

  return (
      <DateRangePicker
          onChange={({ detail }) => {
            onRangeDateSelected(detail.value, props.onFilter);
            setValue(detail.value);
          }}
          value={value}
          isValidRange={() => ({ valid: true })}
          relativeOptions={[
            {
              key: "last-1-day",
              amount: 1,
              unit: "day",
              type: "relative"
            },
            {
              key: "last-3-days",
              amount: 3,
              unit: "day",
              type: "relative"
            },
            {
              key: "last-10-days",
              amount: 10,
              unit: "day",
              type: "relative"
            }
          ]}
          i18nStrings={dateRangeFilterLabels.i18nStrings}
          placeholder={dateRangeFilterLabels.placeholder}
      />
  )
}

function onRangeDateSelected(
    value: DateRangePickerProps.Value | null,
    onFilter: (startDate: string, endDate: string) => void
) {
  let startDate = '';
  let endDate = '';
  if (value?.type === 'absolute') {
    startDate = value.startDate;
    endDate = value.endDate;
  } else if (value?.type === 'relative') {
    const date = new Date();
    // We need to handle every single relative case
    switch (value.unit) {
      case 'second':
        date.setSeconds(date.getSeconds() - value.amount);
        break;
      case 'minute':
        date.setMinutes(date.getMinutes() - value.amount);
        break;
      case 'hour':
        date.setMinutes(date.getMinutes() - value.amount);
        break;
      case 'day':
        date.setDate(date.getDate() - value.amount);
        break;
      case 'week':
        date.setDate(date.getDate() - value.amount * 7);
        break;
      case 'month':
        date.setMonth(date.getMonth() - value.amount);
        break;
      case 'year':
        date.setFullYear(date.getFullYear() - value.amount);
        break;
    }
    startDate = date
        .toISOString()
        .split('T')
        .join(' ')
        .slice(0, date.toISOString.length - 5);
    endDate = new Date()
        .toISOString()
        .split('T')
        .join(' ')
        .slice(0, date.toISOString.length - 5);
  }
  onFilter(startDate, endDate);
}
