import * as React from 'react';

import { Navbar, NavbarBrand, Nav, NavItem } from 'reactstrap';
import { DomainSwitchDropdown } from './domain-switch-dropdown';
import {useContext, useEffect, useState} from "react";
import AuthService from "src/auth/auth-service";
import { Dropdown, DropdownToggle, DropdownItem, DropdownMenu } from 'reactstrap';
import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import Logo from '../../resources/bart_logo.png';
import {InitDomain} from "src/context/domain/domainContext";


export const FullPageHeader  = () => {
    const [ldap, setLdap] = useState<string>('');
    const [given_name, setGiven_name] = useState<string>('');
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const domainData = useContext(InitDomain);
    const authObj =new AuthService();
    const i18nStrings = {
      searchIconAriaLabel: 'Search',
      searchDismissIconAriaLabel: 'Close search',
      overflowMenuTriggerText: 'More',
      overflowMenuTitleText: 'All',
      overflowMenuBackIconAriaLabel: 'Back',
      overflowMenuDismissIconAriaLabel: 'Close menu',
    };
    useEffect(() => {
      const authen = async () => {
        await authObj.authenticate();
        setLdap(authObj.ldap);
        setGiven_name(authObj.given_name);
      }
      authen()
          .catch(console.error);
    }, []);

  const profileActions = [

    {
      type: 'menu-dropdown',
      id: 'support-group',
      text: 'Support',
      items: [
        {
          id: 'Pascal',
          text: 'Pascal Team',
          href: 'https://w.amazon.com/bin/view/Fuse/Dev/Pascal/Team/',
          external: true,
          externalIconAriaLabel: ' (opens in new tab)',
        },
      ],
    },
  ];

  const domainActions = [
    {
      type: 'menu-dropdown',
      id: 'domain-group',
      text: 'Domain',
      items: [
        {
          id: 'devo-FE',
          text: 'devo-FE',
          href: 'https://beta.bart-portal.fuse.amazon.dev/',
          external: false,
          externalIconAriaLabel: ' (opens in new tab)',
        },
        {
          id: 'prod-EU',
          text: 'prod-EU',
          href: 'https://prod-eu.bart-portal.fuse.amazon.dev/',
          external: false,
          externalIconAriaLabel: ' (opens in new tab)',
        },
        {
          id: 'prod-US',
          text: 'prod-US',
          href: 'https://prod-us.bart-portal.fuse.amazon.dev/',
          external: false,
          externalIconAriaLabel: ' (opens in new tab)',
        },
        {
          id: 'prod-FE',
          text: 'prod-FE',
          href: 'https://prod-jp.bart-portal.fuse.amazon.dev/',
          external: false,
          externalIconAriaLabel: ' (opens in new tab)',
        },
      ],
    },
  ];

  return (
        <TopNavigation
            i18nStrings={i18nStrings}
            identity={{
              href: '/',
              title: 'BART Portal',
              logo: { src: Logo, alt: 'Service name logo' },
            }}
            utilities={[
              {
                type: 'menu-dropdown',
                text: `Hello ${given_name}`,
                description: ldap,
                iconName: 'user-profile',
                items: profileActions,
              },
              {
                type: 'menu-dropdown',
                text: domainData.domain,
                description: ldap,
                iconName: 'settings',
                items: domainActions,
              },
            ]}
        />
    );

}
