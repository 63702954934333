import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import PlanSearchBar from '../fusePlanPage/plan-search-bar';
import {Alert, Grid, Header, SpaceBetween} from '@amzn/awsui-components-react';
import "src/index.scss";
import {Fuseplan} from "src/model/fuse-plan";
import PayloadDisplayPlan from "src/lambda/fuseplanServiceLambda/payload-display-plan";
import invokeBartArdLambda from "src/lambda/invoke-lambda";
import {Domain} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import {inputValidation} from "src/commons/input-validation";

export class AppPlan extends React.Component <any, {domain: Domain, authenticating: boolean, isLoggedIn: boolean,
    show: boolean, keyword: string, searched: boolean, payload: any, ldap: string, invalidInputs: boolean}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            domain: this.props.domain,
            authenticating: true,
            isLoggedIn: false,
            show: false,
            keyword: window.location.pathname.split("/")[2]|| "",
            searched: false,
            payload: "init",
            ldap: "",
            invalidInputs: false,
        };
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
        if(this.state.keyword){
            this.search(this.state.domain);
        }
    }

    setValue (value: string){
        this.setState({keyword: value});
    }

    search(domain: Domain) {
        this.setState({
            invalidInputs: false,
            searched: false,
            payload: "init",
        });
        if (inputValidation(this.state.keyword)) {
            this.setState({
                invalidInputs: true,
            });
        } else {
            this.setState({
                searched: true,
                payload: "init"
            });
            // let payload: string;
            const payload = {
                "methodName": "GET",
                "path": "/fuseplan/{planId}",
                "parameter": {"planIdKey": this.state.keyword}
            }
            invokeBartArdLambda(payload, this.callback, domain)
        }
    }

    callback (response: any) {
        let planData: Fuseplan = JSON.parse(response)
        this.setState({
            // payload: response
            payload: planData
        });
    }

    render() {
        if (!(this.state.ldap.includes("bart-read-only") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'Get FusePlan Info'} description={'Check the detail of Plan information based on planID'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can get plan information!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        return (
            <>
                <FullPageTitle title={'Get FusePlan Info'} description={'Check the detail of Plan information based on planID'}/>
                <div className={this.state.searched ? '' : 'page'}>
                    <SpaceBetween size = "l">
                        <PlanSearchBar keyword = {this.state.keyword} setValue = {this.setValue} onClickSearch = {this.search}/>
                        {this.state.invalidInputs ? <div>
                            <Grid
                                gridDefinition={[
                                    {colspan: {l: 7, xxs: 10}},
                                ]}
                            >
                                <Alert header="Invalid input! Plan id field cannot be empty" type="error"></Alert>
                            </Grid>
                        </div>: null}
                        {this.state.searched ?
                            <PayloadDisplayPlan searchResults = {this.state.payload} searchId = {this.state.keyword}/>:
                            null
                        }
                    </SpaceBetween>
                </div>
            </>
        );
    }
}

export default AppPlan;

