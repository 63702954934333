import {Card, CardBody, CardHeader} from "reactstrap";
import * as React from "react";
import {Alert, Grid} from "@amzn/awsui-components-react";

interface ReminderBlockPros {
    title:string;
    lis: string[];
}

export const ReminderBlock = (props:ReminderBlockPros)=>{
    return (
        <div className="alert-box">
          <Grid
              gridDefinition={[
                {colspan: {l: 7, xxs: 10}},
              ]}
          >
            <Alert statusIconAriaLabel="Info">
                <p><strong>{props.title}</strong></p>
                <ul>
                    {props.lis.map((li,index) => (<li key={index}>{li}</li>))}
                </ul>
            </Alert>
          </Grid>
        </div>
    )
}
