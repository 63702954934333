import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";

export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>Bart Audit History Resource</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Check the history of who has performed update ard, update plan, singular or bulk redrive. </p>
            <ul>
                <li>
                    The operation type can be update ard, update plan, singular redrive, or bulk redrive.
                </li>
                <li>
                    The user id is the amazon alias who has requested the operation.
                </li>
                <li>
                    The request time is when the requested operation was made.
                </li>
                <li>
                    The additional info details the ard id, plan id, workflow info, or bulk redrive request id depending on the requested operation.
                </li>
            </ul>
        </HelpPanel>
    );
}
