import React, {useContext, useState} from "react";
import {InitDomain} from "src/context/domain/domainContext";
import {
    Alert,
    Box,
    Button, Input, Modal,
    Select, SpaceBetween,
} from "@amzn/awsui-components-react";
import Papa from "papaparse";
import {inputValidation} from "src/commons/input-validation";

interface Props {
    fileInfo: any[];
    setFileInfo: Function;
    workflowType: string;
    setWorkflowType: Function;
    eventVersionOverride: string;
    setEventVersionOverride: Function;
    onClickPreview: Function;
    reasoning: string;
}

const InputBulkRedrive = ({fileInfo, setFileInfo, workflowType, setWorkflowType,
                              eventVersionOverride, setEventVersionOverride, onClickPreview, reasoning}: Props) => {
    const [values, setValues] = useState<any[]>([]);
    const [uploaded, setUploaded] = useState<boolean>(false);
    const [eventVersionOverrideInputted, setEventVersionOverrideInputted] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [modalSizeErrorOpen, setModalSizeErrorOpen] = useState<boolean>(false);
    const [csvSizeError, setCsvSizeError] = useState<boolean>(false);

    const changeHandler = (event: any) => {
        setCsvSizeError(false);
        setUploaded(false);
        setValues([]);
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (results: any) {
                const valuesArray: any[] = [];
                results.data.map((input: any) => {
                    valuesArray.push(Object.values(input)[0]);
                });
                if(valuesArray.length < 1 || valuesArray.length > 500000) {
                    setCsvSizeError(true);
                }
                setValues(valuesArray);
                setFileInfo(valuesArray);
                setUploaded(true);
            },
        });
    };

    const domainData = useContext(InitDomain);
    const onClick = () => {
        if (csvSizeError || inputValidation(reasoning)) {
            setModalSizeErrorOpen(true);
        }
        else if (eventVersionOverrideInputted && !modalOpen) {
            setModalOpen(true);
        } else {
            onClickPreview(domainData.domain);
        }
    }
    const setCloseInputModal = () => {
        setModalOpen(false);
    }
    const setCloseModalSizeError = () => {
        if (csvSizeError) {
            setUploaded(false);
            setCsvSizeError(false);
            setValues([]);
        }
        setModalSizeErrorOpen(false);
    }


    return (
        <div style={{paddingLeft: '10px'}}>
            <div className="input-container">
                <div className="input-filter">
                    <input
                        type="file"
                        name="file"
                        accept=".csv"
                        onChange={changeHandler}
                        style={{ display:"block", margin: "10px auto"}}
                    />
                </div>
                <div className="select-workflow-filter">
                    <Select
                        data-testid="workflowType-filter"
                        options={[
                            {
                                value: 'FuseBillingWorkflow',
                            },
                            {
                                value: 'FUSENWorkflow',

                            }
                            // TODO: add other workflow types when it is supported
                        ]}
                        selectedAriaLabel="Selected"
                        selectedOption={{
                            value: workflowType || 'FuseBillingWorkflow'
                        }}
                        onChange={(e: any) => {
                            setWorkflowType(e.detail.selectedOption.value);
                        }}
                        ariaDescribedby={''}
                        expandToViewport={true}
                    />
                </div>
                {workflowType === "FUSENWorkflow" ?
                    <div className="input-eventversionoverride">
                        <Input
                            data-testid="input-eventversionoverride"
                            type={"text"}
                            value={eventVersionOverride}
                            onChange={(e: any) => {
                                if (e.detail.value > 0 || e.detail.value == "") {
                                    setEventVersionOverride(e.detail.value);
                                }
                                if (e.detail.value > 0) {
                                    setEventVersionOverrideInputted(true);
                                }
                            }}
                            placeholder="Event version override"
                            clearAriaLabel="clear"
                            ariaDescribedby={''}
                        />
                        <span className="tooltip-text">Must be greater than 0 or empty</span>
                    </div>
                : null}
                {uploaded == true ? <Button onClick={onClick}>Preview</Button>: null}
                <Modal
                    visible={modalSizeErrorOpen}
                    onDismiss={setCloseModalSizeError}
                    header={'Bulk Redrive Request'}
                    closeAriaLabel="Close dialog"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                {csvSizeError && inputValidation(reasoning) ?
                                    <Button variant="primary" onClick={setCloseModalSizeError}>Upload New File and Input Bulk Redrive Reasoning</Button>
                                : null}
                                {csvSizeError && !inputValidation(reasoning) ?
                                    <Button variant="primary" onClick={setCloseModalSizeError}>Upload New File</Button>
                                : null}
                                {!csvSizeError && inputValidation(reasoning) ?
                                    <Button variant="primary" onClick={setCloseModalSizeError}>Input Bulk Redrive Reasoning</Button>
                                : null}
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <SpaceBetween size="m">
                        <Box variant="span">
                            {csvSizeError ?
                                <Alert type="error" header="File has either 0 or more than 500,000 external ids, please upload a file with at least 1, but no more than 500,000 external ids"></Alert>
                            : null}
                            {inputValidation(reasoning) ?
                                <Alert statusIconAriaLabel="Info">You must input your reason for a bulk redrive.</Alert>
                            : null}
                        </Box>
                    </SpaceBetween>
                </Modal>
                <Modal
                    visible={modalOpen}
                    onDismiss={setCloseInputModal}
                    header={'Bulk Redrive Request'}
                    closeAriaLabel="Close dialog"
                    footer={
                        <Box float="right">
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button variant="link" onClick={setCloseInputModal}>Cancel</Button>
                                <Button variant="primary" onClick={onClick}>Preview</Button>
                            </SpaceBetween>
                        </Box>
                    }
                >
                    <SpaceBetween size="m">
                        <Box variant="span">
                            <Alert type="warning" header="Are you sure you want to preview these workflow(s) with an event version override?"></Alert>
                        </Box>
                    </SpaceBetween>
                </Modal>
            </div>
        </div>
    );
}

export default InputBulkRedrive;
