import { useMemo } from 'react';
import {useLocalStorage} from "src/commons/use-local-storage";
import {addToColumnDefinitions, mapWithColumnDefinitionIds} from "src/commons/columnDefinitionsHelper";


export function useColumnWidths(storageKey: any, columnDefinitions: any) {
    const [widths, saveWidths] = useLocalStorage(storageKey);

    function handleWidthChange(event: any) {
        saveWidths(mapWithColumnDefinitionIds(columnDefinitions, 'width', event.detail.widths));
    }
    const memoDefinitions = useMemo(() => {
        return addToColumnDefinitions(columnDefinitions, 'width', widths);
    }, [widths, columnDefinitions]);

    return [memoDefinitions, handleWidthChange];
}
