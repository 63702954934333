import React from 'react';
import SideNavigation, { SideNavigationProps } from '@amzn/awsui-components-react/polaris/side-navigation';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'src/components/constants/appConstants';

const navHeader = {
  href: URLS.MAIN_PAGE,
  text: 'Fuse Bart'
};

export const navItems: SideNavigationProps['items'] = [
  { type: 'link', text: 'Transaction Search', href: URLS.MAIN_PAGE },
  { type: 'divider'},
  { type: 'section', text: 'Plans', items: [
          { type: 'link', text: 'Get Plan', href: URLS.GET_PLAN },
          { type: 'link', text: 'Update Plan', href: URLS.UPDATE_PLAN },
          { type: 'link', text: 'Re-Mapping ARD to Plan', href: URLS.LINK_ARD_TO_A_PLAN },
          { type: 'link', text: 'ARD New Plan Mapping', href: URLS.ARD_NEW_PLAN_MAPPING },
      ]
  },
  { type: 'divider'},
  { type: 'section', text: 'ARDs', items: [
          { type: 'link', text: 'Get ARD', href: URLS.GET_ARD },
          { type: 'link', text: 'Update ARD', href: URLS.UPDATE_ARD },
          { type: 'link', text: 'View all ARDs', href: URLS.LIST_ALL_ARDs },
          { type: 'link', text: 'View Raga availabilities', href: URLS.RAGA_AVAILABILITIES },
      ]
  },
  { type: 'divider'},
  { type: 'section', text: 'Redrives', items: [
          { type: 'link', text: 'Redrive Workflow', href: URLS.REDRIVE_WORKFLOW },
          { type: 'link', text: 'Get Workflow Status', href: URLS.GET_WORKFLOW_STATUS },
          { type: 'link', text: 'Bulk Redrive Workflow', href: URLS.BULK_REDRIVE_WORKFLOW },
          { type: 'link', text: 'Bulk Redrive Status', href: URLS.BULK_REDRIVE_STATUS },
      ]
  },
  { type: 'divider'},
  { type: 'link', text: 'DLQ Mover', href: URLS.DLQ_MOVER },
  { type: 'divider'},
  { type: 'link', text: 'Bart Audit History', href: URLS.BART_AUDIT_HISTORY },
];

const defaultOnFollowHandler: SideNavigationProps['onFollow'] = (event) => {
  // keep the locked href for our demo pages
  event.preventDefault();
};

interface NavigationProps {
  activeHref?: string;
  header?: SideNavigationProps['header'];
  items?: SideNavigationProps['items'];
  onFollowHandler?: SideNavigationProps['onFollow'];
}

/**
 *
 * @param root0
 * @param root0.activeHref
 * @param root0.header
 * @param root0.items
 * @param root0.onFollowHandler
 */
export function Navigation({
                             activeHref,
                             header = navHeader,
                             items = navItems,
                             onFollowHandler = defaultOnFollowHandler,
                           }: NavigationProps) {
  const navigate = useNavigate();
  return (
      <SideNavigation
          items={items}
          header={header}
          activeHref={activeHref}
          onFollow={(e) => {
            onFollowHandler && onFollowHandler(e);
            navigate(e.detail.href);
          }}
      />
  );
}
