import React, {useEffect, useRef, useState} from "react";
import {
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter,
    TextFilterProps
} from "@amzn/awsui-components-react";
import {useCollection} from "@amzn/awsui-collection-hooks";
import {HistoryWorkflow} from "src/components/bulkRedriveStatusPage/index";
import {
    EmptyState, getMatchesCountText,
    STATUS_COLUMN_DEFINITIONS,
    STATUS_DEFAULT_PREFERENCES, statusPaginationLabels
} from "src/components/bulkRedriveStatusPage/table-config";

interface StatusTableProps {
    statusByRequestIdList: any[]
}

const StatusTable = ({statusByRequestIdList}: StatusTableProps) => {
    const [allItems, setAllItems] = useState<HistoryWorkflow[]>();
    const [loading, setLoading] = useState<boolean>(false);
    const [fetchError, setFetchError] = useState<string | null>(null);
    const [preferences, setPreferences] = useState(STATUS_DEFAULT_PREFERENCES);
    const textFilterRef = useRef<TextFilterProps.Ref>(null);

    useEffect(() => {
        textFilterRef.current?.focus();
    }, []);

    const loadItems = async () => {
        setLoading(true);
        setFetchError(null);
        try {
            setAllItems(statusByRequestIdList as HistoryWorkflow[]);
        } catch (error: unknown) {
            setFetchError(error instanceof Error ? error.message : String(error));
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        loadItems();
    }, []);

    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        allItems || [],
        {
            filtering: {
                empty: (
                    <EmptyState
                        title="No workflows"
                        subtitle="No workflows to display."
                        action={null}
                    />
                ),
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle="We can’t find a match."
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                )
            },
            pagination: {
                pageSize: preferences.pageSize
            },
            sorting: {},
            selection: {}
        }
    );
    const selectedItems = collectionProps.selectedItems!;

    const exportToCsv = (filename: any) => {
        const csvData = arrayToCsv;
        downloadFile(csvData, filename);
    }

    const arrayToCsv = [
        [
            "external_id",
            "workflow_type",
            "event_version_override",
            "sent_to_redrive_sqs",
            "successfully_sent_to_redrive",
            "sent_to_workflow_status_sqs",
            "workflow_status"
        ],
        ...selectedItems.map(entry => [
            entry.externalId,
            entry.workflowType,
            entry.eventVersionOverride,
            entry.sentToRedriveSQS,
            entry.successfullySentToRedrive,
            entry.sentToWorkflowStatusSQS,
            entry.workflowStatus
        ])
    ].map(e => e.join(","))
        .join("\n");

    const downloadFile = (data: any, fileName: any) => {
        const blob = new Blob([data], { type: 'text/csv' })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    return (
        <SpaceBetween size="m">
            <Table
                {...collectionProps}
                visibleColumns={preferences.visibleContent}
                wrapLines={preferences.wrapLines}
                loading={loading}
                loadingText="Loading workflows"
                selectionType="multi"
                stickyHeaderVerticalOffset={1}
                variant='full-page'
                header={
                    <Header
                        variant="awsui-h1-sticky"
                        counter={
                            allItems &&
                            (selectedItems!.length ? `(${selectedItems!.length}/${allItems.length})` : `(${allItems.length})`)
                        }
                        actions={
                            <Button disabled={selectedItems.length === 0} onClick={() => exportToCsv("bulkredrivehistory")}>Download CSV</Button>
                        }
                    >
                        Workflows
                    </Header>
                }
                columnDefinitions={STATUS_COLUMN_DEFINITIONS}
                items={items}
                pagination={<Pagination {...paginationProps} ariaLabels={statusPaginationLabels} />}
                filter={
                    <TextFilter
                        {...filterProps}
                        ref={textFilterRef}
                        countText={getMatchesCountText(filteredItemsCount!)}
                        filteringPlaceholder="Search workflows"
                        filteringAriaLabel="Filter workflows"
                    />
                }
            />
        </SpaceBetween>
    );
}

export default StatusTable;
