import React, {useContext} from 'react';
import {InitDomain} from "src/context/domain/domainContext";
import {Button, Input} from "@amzn/awsui-components-react";


interface Props {
    setValue: Function;
    onClickSearch: Function;
}

const ManyArdSearchBar = ({onClickSearch}: Props) => {
    const domainData = useContext(InitDomain);
    return (
        <div style={{paddingLeft: '10px'}}>
            <Button onClick = {()=>onClickSearch(domainData.domain)}>View All ARDs</Button>
        </div>
    );
}

export default ManyArdSearchBar;
