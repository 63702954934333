import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";


export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>ARD New Plan Mapping</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Creates a new fuse plan with ard mapping and other necessary information.</p>
            <ul>
                <li>
                    Please enter the fuse plan id along with the ard id mapping.
                </li>
                <li>
                    Please enter the supplemental information regarding the partner name, fusen enabled status with the fusen start date, and migration target stage.
                </li>
            </ul>
        </HelpPanel>
    );
}
