import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig"; // npm install aws-sdk

interface InvokeBartFBGSLambdaPayload {
    methodName: string;
    path: string;
    parameter?: any;
}
function invokeFuseBartBillingGatewayServiceAPIInvokeLambda (payload: InvokeBartFBGSLambdaPayload,
                                                             domain: Domain, callback: Function) {
    const payloadString = JSON.stringify(payload)
    const lambdaConfig = getBartLambdaConfig(domain)
    return Auth.currentCredentials()
        .then(credentials => {
            const lambda = new Lambda({
                credentials: Auth.essentialCredentials(credentials),
                region: lambdaConfig.awsRegion,
            });
            let param = {
                FunctionName: lambdaConfig.billingGatewayServiceAPIInvokeLambdaARN,
                InvocationType: 'RequestResponse',
                Payload: payloadString
            };

            return lambda.invoke(param, function(err, data) {
                //callback function
                console.log(data.Payload as string);
                if ((data.Payload as string).includes("redriveWorkflowResponse")) {
                    return callback(JSON.parse(data.Payload as string)["redriveWorkflowResponse"]);
                } else if ((data.Payload as string).includes("workflowStatusResponse")) {
                    return callback(JSON.parse(data.Payload as string)["workflowStatusResponse"]);
                } else if ((data.Payload as string).includes("InvalidInputException")) {
                    return callback("invalid");
                } else if ((data.Payload as string).includes("WorkItemNotClosedException") ||
                    (data.Payload as string).includes("Can not open work item as there is an existing work item in error state with id")) {
                    return callback("open");
                }
                return callback("error");
            })
        });
}

export default invokeFuseBartBillingGatewayServiceAPIInvokeLambda;
