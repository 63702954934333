import React, {useContext} from 'react';
import {Button, FormField, Input, Select} from "@amzn/awsui-components-react";
import {InitDomain} from "src/context/domain/domainContext";
import {useNavigate} from "react-router-dom";

interface Props {
    requestId: string;
    setRequestId: Function;
    onClickSearch: Function;
    workflowType: string;
    sentToRedriveSQS: string;
    successfullySentToRedrive: string;
    sentToWorkflowStatusSQS: string;
    workflowStatus: string;
    setWorkflowType: Function;
    setSentToRedriveSQS: Function;
    setSuccessfullySentToRedrive: Function;
    setSentToWorkflowStatusSQS: Function;
    setWorkflowStatus: Function;
}

const bulkRedriveStatusSearchBar = ({requestId, setRequestId, onClickSearch, workflowType, sentToRedriveSQS,
                                         successfullySentToRedrive, sentToWorkflowStatusSQS, workflowStatus, setWorkflowType, setSentToRedriveSQS,
                                         setSuccessfullySentToRedrive, setSentToWorkflowStatusSQS, setWorkflowStatus}: Props) => {
    const domainData = useContext(InitDomain);
    const navigate = useNavigate();
    const onClick = () => {
        navigate('/bulkredrivestatus/' + requestId);
        onClickSearch(domainData.domain)
    }

    return (
        <div style={{paddingLeft: '10px'}}>
            <div className="input-container">
                <div className="input-filter">
                    <Input
                        data-testid="input-filter"
                        type="search"
                        value={requestId}
                        onChange={(e: any) => {
                            if (e.detail != null) {
                                setRequestId(e.detail.value);
                            }
                        }}
                        placeholder="Please enter bulk redrive request id"
                        clearAriaLabel="clear"
                        ariaDescribedby={''}
                    />
                </div>
                <div className="select-filter">
                    <FormField constraintText="workflowType">
                        <Select
                            data-testid="workflowType-filter"
                            options={[
                                {
                                    value: 'no filter',
                                },
                                {
                                    value: 'FuseBillingWorkflow',
                                },
                                {
                                    value: 'FUSENWorkflow',
                                },
                            ]}
                            selectedAriaLabel="Selected"
                            selectedOption={{
                                value: workflowType || 'no filter',
                            }}
                            onChange={(e: any) => {
                                setWorkflowType(e.detail.selectedOption.value);
                            }}
                            ariaDescribedby={''}
                            expandToViewport={true}
                        />
                    </FormField>
                </div>
                <div className="select-filter">
                    <FormField constraintText="sentToRedriveSQS">
                        <Select
                            data-testid="sentToRedriveSQS-filter"
                            options={[
                                {
                                    value: 'no filter',
                                },
                                {
                                    value: 'true',
                                },
                                {
                                    value: 'false',
                                },
                            ]}
                            selectedAriaLabel="Selected"
                            selectedOption={{
                                value: sentToRedriveSQS || 'no filter',
                            }}
                            onChange={(e: any) => {
                                setSentToRedriveSQS(e.detail.selectedOption.value);
                            }}
                            ariaDescribedby={''}
                            expandToViewport={true}
                        />
                     </FormField>
                </div>
                <div className="select-filter-expanded">
                    <FormField constraintText="successfullySentToRedrive">
                        <Select
                            data-testid="successfullySentToRedrive-filter"
                            options={[
                                {
                                    value: 'no filter',
                                },
                                {
                                    value: 'true',
                                },
                                {
                                    value: 'false',
                                },
                            ]}
                            selectedAriaLabel="Selected"
                            selectedOption={{
                                value: successfullySentToRedrive || 'no filter',
                            }}
                            onChange={(e: any) => {
                                setSuccessfullySentToRedrive(e.detail.selectedOption.value);
                            }}
                            ariaDescribedby={''}
                            expandToViewport={true}
                        />
                    </FormField>
                </div>
                <div className="select-filter-expanded">
                    <FormField constraintText="sentToWorkflowStatusSQS">
                        <Select
                            data-testid="sentToWorkflowStatusSQS-filter"
                            options={[
                                {
                                    value: 'no filter',
                                },
                                {
                                    value: 'true',
                                },
                                {
                                    value: 'false',
                                },
                            ]}
                            selectedAriaLabel="Selected"
                            selectedOption={{
                                value: sentToWorkflowStatusSQS || 'no filter',
                            }}
                            onChange={(e: any) => {
                                setSentToWorkflowStatusSQS(e.detail.selectedOption.value);
                            }}
                            ariaDescribedby={''}
                            expandToViewport={true}
                        />
                    </FormField>
                </div>
                <div className="select-filter">
                    <FormField constraintText="workflowStatus">
                        <Select
                            data-testid="workflowStatus-filter"
                            options={[
                                {
                                    value: 'no filter',
                                },
                                {
                                    value: 'opened',
                                },
                                {
                                    value: 'errored',
                                },
                                {
                                    value: 'successful',
                                },
                            ]}
                            selectedAriaLabel="Selected"
                            selectedOption={{
                                value: workflowStatus || 'no filter',
                            }}
                            onChange={(e: any) => {
                                setWorkflowStatus(e.detail.selectedOption.value);
                            }}
                            ariaDescribedby={''}
                            expandToViewport={true}
                        />
                    </FormField>
                </div>
                <Button onClick={onClick}>Get Bulk Redrive Request</Button>
            </div>
        </div>
    );
}

export default bulkRedriveStatusSearchBar;
