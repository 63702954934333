import React from 'react';
import AuthService from "src/auth/auth-service";
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import { FullPageTitle } from '../header/title';
import {Alert, Grid, SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import BartAuditHistorySearchBar from "src/components/bartAuditHistoryPage/bart-audit-history-search-bar";
import HistoryTable from './table-display';
import {DynamoDBClient} from '@aws-sdk/client-dynamodb'
import {DynamoDBDocumentClient, ScanCommand, ScanCommandOutput} from "@aws-sdk/lib-dynamodb";
import {Auth} from "aws-amplify";
import {AuditHistory} from "src/components/bartAuditHistoryPage/index";

export class AppBARTAuditHistory extends React.Component<{}, {
    ldap: string, authenticating: boolean, isLoggedIn: boolean, show: boolean, keyword: string, searched: boolean, payload: any, history: any[], startDate: string, endDate: string}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        const formatDate = (date: Date) => {
            const yyyy = date.getFullYear();
            const MM = String(date.getMonth() + 1).padStart(2, "0");
            const dd = String(date.getDate()).padStart(2, "0");
            const hh = String(date.getHours()).padStart(2, "0");
            const mm = String(date.getMinutes()).padStart(2, "0");
            const ss = String(date.getSeconds()).padStart(2, "0");

            return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
        };

        this.state = {
            ldap: "",
            authenticating: true,
            isLoggedIn: false,
            show: false,
            keyword: "",
            searched: false,
            payload: "init",
            history: [],
            startDate: formatDate(new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)),
            endDate: formatDate(new Date()),
        };
        this.setValue = this.setValue.bind(this);
        this.search = this.search.bind(this);
        this.setDateRange= this.setDateRange.bind(this);
        this.callback = this.callback.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
    }

    setValue (value: string) {
        this.setState({keyword: value});
    }

    setDateRange (startDate:string, endDate:string){
        this.setState({startDate});
        this.setState({endDate});
    }

    search (domain: Domain) {
        this.setState({
            searched: true,
            payload: "loading",
        });
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);

            const ddbScan = async () => {
                const command = new ScanCommand({
                    TableName: "BARTAuditHistoryTable",
                    ExpressionAttributeValues: {
                        ":startDate": this.state.startDate,
                        ":endDate":  this.state.endDate
                    },
                    FilterExpression: "requestTime BETWEEN :startDate AND :endDate"
                })

                const response: ScanCommandOutput = await docClient.send(command);
                this.callback(response.Items);
            }
            ddbScan();
        });
    }

    callback (response: any) {
        this.setState({
            payload: "result",
            history: response as AuditHistory[]
        });
    }

    render() {
        if (!(this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'View BART Audit History'} description={'Check the audit log of update ard, update plan, link ard to plan, singular and bulk redrives, dlq movers'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can audit BART history!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        return (
            <>
            <FullPageTitle title={'View BART Audit History'} description={'Check the audit log of update ard, update plan, link ard to plan, singular and bulk redrives, dlq movers'}/>
                <SpaceBetween size="l">
                    <BartAuditHistorySearchBar setValue={this.setValue} onClickSearch={this.search} setDateRange={this.setDateRange}/>
                    {this.state.payload === "loading" ? <Spinner size = "large"></Spinner>: null}
                    {this.state.payload === "result" ? <HistoryTable auditList={this.state.history}/> :null}
                </SpaceBetween>
            </>
        );
    }
}

export default AppBARTAuditHistory;
