import { nanoid } from '@reduxjs/toolkit';
import React from 'react';
import styles from './styles.module.scss';

interface SeparatedListProps {
    ariaLabel?: string;
    items: Array<React.ReactNode>;
}

/**
 *
 * @param root0
 * @param root0.ariaLabel
 * @param root0.items
 */
export function SeparatedList({ ariaLabel, items }: SeparatedListProps) {
    return (
        <ul aria-label={ariaLabel} className={styles.root}>
            {items.map((item) => (
                <li key={nanoid()}>{item}</li>
            ))}
        </ul>
    );
}
