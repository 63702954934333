import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import invokeLambda from 'src/lambda/transactionSearchLambda/invoke-lambda';
import SearchBar from './search-bar';
import PayloadDisplay from 'src/lambda/transactionSearchLambda/payload-display';
import {Alert, Grid, SpaceBetween} from '@amzn/awsui-components-react';
import { Auth } from 'aws-amplify';
import "src/index.scss";
import {InitDomain} from "src/context/domain/domainContext";
import {Domain} from "src/config/BartLambdaConfig";
import {ReminderBlock} from "src/components/reminder-block";
import {FullPageTitle} from "src/components/header/title";
export class App extends React.Component <any, { domain: Domain, authenticating: boolean, isLoggedIn: boolean,
    show: boolean, keyword: string, searched: boolean, payload: any, idType: string, idLabel: string, isTest:string,
    eventType:string, count: string, startDate: string, endDate: string, ldap: string}> {
    authObj: AuthService;
    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        const formatDate = (date: Date) => {
            const yyyy = date.getFullYear();
            const MM = String(date.getMonth() + 1).padStart(2, "0");
            const dd = String(date.getDate()).padStart(2, "0");
            const hh = String(date.getHours()).padStart(2, "0");
            const mm = String(date.getMinutes()).padStart(2, "0");
            const ss = String(date.getSeconds()).padStart(2, "0");

            return `${yyyy}-${MM}-${dd} ${hh}:${mm}:${ss}`;
        };

        this.state = {
            domain: this.props.domain,
            authenticating: true,
            isLoggedIn: false,
            show: false,
            keyword: window.location.pathname.split("/")[3]|| "",
            searched: false,
            payload: "init",
            idType:  window.location.pathname.split("/")[2] || "external_id",
            idLabel: this.setIdLabel(window.location.pathname.split("/")[2]),
            isTest: "no filter",
            eventType: "no filter",
            count: '10',
            startDate: formatDate(new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)),
            endDate: formatDate(new Date()),
            ldap: "",
        };
        this.setValue = this.setValue.bind(this);
        this.setCount = this.setCount.bind(this);
        this.setDateRange= this.setDateRange.bind(this);
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
        this.setChoice = this.setChoice.bind(this);
        this.setIsTest = this.setIsTest.bind(this);
        this.setEventType = this.setEventType.bind(this);
    }

    setIdLabel (value: string): string {
        if (value === "reseller_id"){
            return "reseller id";
        }
        if (value === "subs_subscription_id"){
            return "subscription id";
        }
        return "external id";
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            // set loading state to false after authentication completes. This will cause the page to re-render.
            this.setState({
                authenticating: false, isLoggedIn: this.authObj.getIsLoggedIn(), ldap: this.authObj.ldap
            });
        }
        fetchData();
        if(this.state.idType && this.state.keyword){
        this.search(this.state.domain)
        }
    }

    setDateRange (startDate:string, endDate:string){
        this.setState({startDate});
        this.setState({endDate});
    }

    setValue (value: string){
        this.setState({keyword: value.trim()});
    }

    setCount (value: string){
        this.setState({count: value.trim()});
    }

    setChoice (choice: string) {
        let choiceLabel: string = "";
        if(choice === "external_id") {
            choiceLabel = "external id";
        } else if(choice === "reseller_id") {
            choiceLabel = "reseller id";
        } else if(choice === "subs_subscription_id") {
            choiceLabel = "subscription id";
        }
        this.setState({idType: choice, idLabel: choiceLabel, searched: false});
    }

    setIsTest (value: string) {
        this.setState({isTest: value})
    }

    setEventType (value: string) {
        this.setState({eventType: value})
    }

    search(domain: Domain) {
        this.setState({
            searched: true,
            payload: "init"
        });
        invokeLambda(this.state.idType, this.state.keyword, this.state.startDate, this.state.endDate,this.state.count,
            this.state.isTest, this.state.eventType, domain, this.callback);
    }

    callback(result: any) {
        if (result.error) {
            // Handle error cases
            if (result.error === "error") {
                this.setState({ payload: "error" });
            } else if (result.error === "invalid") {
                this.setState({ payload: "invalid" });
            }
        } else {
            // Handle successful response
            const { fbtsResponse, ftsResponse } = result;
            const combinedResponse = {
                ...fbtsResponse,
                ftsResponse
            };
            this.setState({ payload: combinedResponse });
        }
    }


    render() {
        if (this.state.authenticating || !this.state.isLoggedIn) {
            // render loading spinner
            return (
            <div>
                Authenticating...
            </div>
            );
        }
        else {
            if (!(this.state.ldap.includes("bart-transaction") ||
                this.state.ldap.includes("bart-read-only") ||
                this.state.ldap.includes("bart-admin") ||
                this.state.ldap.includes("fuse-pascal"))) {
                return (
                    <div>
                        <FullPageTitle title={'Transaction Search'} description={'Check the detail of transactions by external id, reseller id, or subscription id'}/>
                        <SpaceBetween size = "l" />
                        <Grid
                            gridDefinition={[
                                {colspan: {l: 7, xxs: 10}},
                            ]}
                        >
                            <Alert header="Only authorized users can perform a transaction search!" type="error"></Alert>
                        </Grid>
                    </div>
                )
            }
            // render page if authentication has been processed and was successful
            let alias: string = "";
            let lis:string[]=[];
            if(this.state.idType === ""){
                lis.push('Please select search type first.');
            }
            else if(this.state.idType!== "external id"){
                lis.push('You can search by the prefix.');
                lis.push('Please select the date range and data count between 1 and 10 inclusive.')
            }
            lis.push('Search is case-sensitive.')
            Auth.currentAuthenticatedUser().then(creds=>{
                alias = creds["username"].split("_")["1"];
            });
            return (
                <InitDomain.Consumer>
                    {context =>(
                <>
                    <FullPageTitle title={'Transaction Search'} description={'Check the detail of transactions by external id, reseller id, or subscription id'}/>
                    <div className={this.state.searched ? '' : 'page'}>
                    <SpaceBetween size = "l">
                        <SearchBar keyword = {this.state.keyword} idType={this.state.idType} setValue = {this.setValue}
                                   setChoice = {this.setChoice} onClickSearch = {this.search} domain = {context.domain} setDateRange={ this.setDateRange}
                                   count ={this.state.count} setCount ={this.setCount} idLabel={this.state.idLabel}
                                   isTest={this.state.isTest} setIsTest={this.setIsTest}
                                   eventType={this.state.eventType} setEventType={this.setEventType}
                        />
                            {this.state.searched ? 
                                <PayloadDisplay searchResults = {this.state.payload} searchId = {this.state.keyword}/>:
                                <ReminderBlock title='Information' lis ={lis}/>
                            }
                    </SpaceBetween>
                    </div>
                </>)}
                </InitDomain.Consumer>
            );
        } 
    }
}

export default App;

