import Auth from '@aws-amplify/auth';
import Lambda from 'aws-sdk/clients/lambda';

import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig"; // npm install aws-sdk

function invokeLambda (type: String, value: String,startDate: string,endDate: string, count:string, isTest: String, eventType: String, domain:Domain, callback: Function ) {
    const lamdaConfig = getBartLambdaConfig(domain)
        return Auth.currentCredentials()
    .then(credentials => {
    const lambda = new Lambda({
        credentials: Auth.essentialCredentials(credentials), 
        region: lamdaConfig.awsRegion,
    });
    let param = {
        FunctionName: lamdaConfig.transactionSearchLambdaARN,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify({"idType": type, "value": value, startDate, endDate, count, "isTest": isTest, "eventType": eventType})
    };
    
    return lambda.invoke(param, function(err, data) {
        // callback function
        console.log("the value of data.Payload : ", data.Payload);
        if (err || data.FunctionError || data.StatusCode != 200 || data.Payload == "null") {
            return callback("error"); //callback handle error
        } else if ((JSON.parse(data.Payload as string)["fbtsResponse"] == "") || 
        (JSON.parse(data.Payload as string)["ftsResponse"] == "")) {
            return callback("invalid");
        }
        const responseData = JSON.parse(data.Payload as string);
        console.log("the value of responseData : ", responseData);
        const fbtsResponse = responseData["fbtsResponse"];
        console.log("the value of fbtsResponse : ", fbtsResponse);
        const ftsResponse = responseData["ftsResponse"];
        console.log("the value of ftsResponse : ", ftsResponse);

        return callback({fbtsResponse, ftsResponse});
    })
    });
} 
export default invokeLambda;
