import React from 'react';


export class TitlePlan extends React.Component {
    render () {
        return (
            <div className = "header">
                <div>
                    <h1>
                        Update FusePlan Resource
                    </h1>
                </div>
            </div>
        );
    }
}