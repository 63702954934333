export const appLayoutLabels = {
  navigation: 'Side navigation',
  navigationToggle: 'Open side navigation',
  navigationClose: 'Close side navigation',
  notifications: 'Notifications',
  tools: 'Help panel',
  toolsToggle: 'Open help panel',
  toolsClose: 'Close help panel'
};

export const generalLabels = {
  loading: 'Loading information',
  noResultsToDisplay: 'No test results to display.'
}

export const preferenceLabels = {
  title: 'Preferences',
  confirm: 'Confirm',
  cancel: 'cancel',
}

export const paginationLabels = {
  nextPageLabel: 'Next page',
  previousPageLabel: 'Previous page',
  pageSizePreferenceTitle: 'Select page size',
  pageLabel: (pageNumber: number) => `Page ${pageNumber} of all pages`
};

export function getMatchesCountText(count: number) {
  return count === 1 ? `1 match` : `${count} matches`;
}

export const dateRangeFilterLabels = {
  i18nStrings: {
    todayAriaLabel: "Today",
    nextMonthAriaLabel: "Next month",
    previousMonthAriaLabel: "Previous month",
    customRelativeRangeDurationLabel: "Duration",
    customRelativeRangeDurationPlaceholder:
        "Enter duration",
    customRelativeRangeOptionLabel: "Custom range",
    customRelativeRangeOptionDescription:
        "Set a custom range in the past",
    customRelativeRangeUnitLabel: "Unit of time",
    formatRelativeRange: (e: any) => {
      const t =
          1 === e.amount ? e.unit : e.unit + "s";
      return `Last ${e.amount} ${t}`;
    },
    formatUnit: (e: any, t: any) => (1 === t ? e : e + "s"),
    dateTimeConstraintText:
        "Range must be between 6 - 30 days. Use 24 hour format.",
    relativeModeTitle: "Relative range",
    absoluteModeTitle: "Absolute range",
    relativeRangeSelectionHeading: "Choose a range",
    startDateLabel: "Start date",
    endDateLabel: "End date",
    startTimeLabel: "Start time",
    endTimeLabel: "End time",
    clearButtonLabel: "Clear",
    cancelButtonLabel: "Cancel",
    applyButtonLabel: "Apply",
  },
  placeholder: "Filter by a date and time range"
}
