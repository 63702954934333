import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";

export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>Bulk Redrive Workflow Resource</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Perform bulk workflow redrives.</p>
            <ul>
                <li>
                    Please input a csv file with external ids in the first column id and select a workflow type.
                </li>
                <li>
                    In preview mode, you can edit any details you would like for each workflow prior to submission.
                </li>
                <li>
                    Event version override is an optional field for FUSEN workflows.
                </li>
            </ul>
        </HelpPanel>
    );
}
