import React, {useRef, useState} from "react";
import {AppLayout, AppLayoutProps, NonCancelableCustomEvent} from "@amzn/awsui-components-react";
import {Navigation} from "src/pages/commons";
import {URLS} from "src/components/constants/appConstants";
import {ToolsContent} from "src/pages/bulkRedriveWorkflow/components/common-components";
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
import {AppSortedBulkRedrive} from "src/components/bulkRedriveWorkflowPage/bulk-redrive-page";

export function BulkRedriveWorkflow(){
    const [navigationOpen, setNavigationOpen] = React.useState(false);
    const appLayout = useRef<AppLayoutProps.Ref>(null);
    const [toolsOpen, setToolsOpen] = useState(false);
    const loadPanelFn = () => {
        setToolsOpen(true);
        appLayout.current?.focusToolsClose();
    };

    const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
        setToolsOpen(detail.open);
    };
    return (
        <AppLayout
            disableContentPaddings
            content={<AppSortedBulkRedrive/>}
            navigation={<Navigation activeHref={URLS.BULK_REDRIVE_WORKFLOW} />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            tools={<ToolsContent/>}
            toolsOpen={toolsOpen}
            onToolsChange={onToolsChangeFn}
            ariaLabels={appLayoutAriaLabels}
        />
    );
}
