import React, {useRef, useState} from "react";
import {AppLayout, AppLayoutProps, NonCancelableCustomEvent} from "@amzn/awsui-components-react";
import {Navigation} from "src/pages/commons";
import {URLS} from "src/components/constants/appConstants";
import {appLayoutAriaLabels} from "src/i18n-strings/app-layout";
import {Domain} from "src/config/BartLambdaConfig";
import {AppBulkRedriveStatus} from "src/components/bulkRedriveStatusPage/bulk-redrive-status-page";
import {ToolsContent} from "src/pages/bulkRedriveStatus/components/common-components";
interface Props {
    domain: Domain;
}

export function BulkRedriveStatus(props : Props){
    const [navigationOpen, setNavigationOpen] = React.useState(false);
    const appLayout = useRef<AppLayoutProps.Ref>(null);
    const [toolsOpen, setToolsOpen] = useState(false);
    const loadPanelFn = () => {
        setToolsOpen(true);
        appLayout.current?.focusToolsClose();
    };

    const onToolsChangeFn = ({ detail }: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => {
        setToolsOpen(detail.open);
    };
    return (
        <AppLayout
            disableContentPaddings
            content={<AppBulkRedriveStatus domain={props.domain}/>
            }
            navigation={<Navigation activeHref={URLS.BULK_REDRIVE_STATUS} />}
            navigationOpen={navigationOpen}
            onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
            tools={<ToolsContent/>}
            toolsOpen={toolsOpen}
            onToolsChange={onToolsChangeFn}
            ariaLabels={appLayoutAriaLabels}
        />
    );
}
