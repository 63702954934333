import React, {useContext} from "react";
import {Alert, Button, Grid, Input, SpaceBetween} from "@amzn/awsui-components-react";
import {InitDomain} from "src/context/domain/domainContext";
import {FullPageTitle} from "src/components/header/title";
import {inputValidation} from "src/commons/input-validation";

interface Props {
    keyword: string;
    setValue: Function;
    onClickSearch: Function;
    error?:string;
}

const GetPlan = ({keyword, setValue, onClickSearch,error}: Props) => {
  const domainData = useContext(InitDomain);
  const onClick = () => {
      if(!inputValidation(keyword)) {
          onClickSearch(domainData.domain);
      }
  }
    return (
        <>
          <FullPageTitle title={'Update FusePlan Info'} description={'Update the info of Fuse Plan based on planID'}/>
            <div className="page">
          <SpaceBetween size="l">
            <div style={{paddingLeft: '10px'}}>
              <div className="input-container">
                <div className="input-filter">
                  <Input
                      data-testid="input-filter"
                      type="search"
                      value={keyword}
                      onChange={(e: any)=> {
                        if (e.detail!=null) {
                          setValue(e.detail.value);
                        }
                      }
                      }
                      placeholder="Please enter planId"
                      clearAriaLabel="clear"
                      ariaDescribedby={''}
                  />
                </div>
                <Button onClick = {onClick}>Get Plan</Button>
              </div>
            </div>
              {error === "invalid" ? (
                  <div className="alert-box">
                      <Grid
                          gridDefinition={[
                              {colspan: {l: 7, xxs: 10}},
                          ]}
                      >
                          <Alert header="No results found!" type="error"></Alert>
                      </Grid>
                  </div>
              ) : null}
              {error === "error" ? (
                  <div className="alert-box">
                      <Grid
                          gridDefinition={[
                              {colspan: {l: 7, xxs: 10}},
                          ]}
                      >
                          <Alert header="Search timed out!" type="error"></Alert>
                      </Grid>
                  </div>
              ) : null}
          </SpaceBetween>
            </div>
        </>
    );
}
export default GetPlan;


