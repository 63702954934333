import React from 'react';
import { HelpPanel } from '@amzn/awsui-components-react';
import { ExternalLinkGroup } from '../../commons';
import {learnMoreLinks} from "src/pages/commons/learn-more-links";

export function ToolsContent() {
    return (
        <HelpPanel
            header={<h2>Bulk Redrive Status Resource</h2>}
            footer={
                <ExternalLinkGroup
                    items={learnMoreLinks}
                />
            }
        >
            <p>Check the details of a bulk redrive request.</p>
            <ul>
                <li>
                    Please input a bulk redrive request id that was attached to a bulk redrive request to view its details.
                </li>
                <li>
                    You can add optional filter fields to filter out workflows from the table.
                </li>
                <li>
                    In the resulting table, you can search for individual workflows and select workflows to download and redrive as needed.
                </li>
            </ul>
        </HelpPanel>
    );
}
