import {SpaceBetween, Spinner} from "@amzn/awsui-components-react";
import React from "react";
import {FullPageTitle} from "src/components/header/title";

export class ArdLoader extends React.Component {
    render () {
        return (
            <>
                <FullPageTitle title={'Update ARD'} description={'Update Fuse ARD info'}/>
                <SpaceBetween size = "l">
                    <Spinner size = "large"></Spinner>;
                </SpaceBetween>
            </>
        );
    }
}
