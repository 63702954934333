import React from 'react';
import { AuthService } from 'src/auth/auth-service';
import {
    Alert,
    Box,
    Button,
    Container,
    Form,
    FormField, Grid,
    Header,
    Input,
    Modal,
    NonCancelableCustomEvent,
    Select,
    SpaceBetween, Spinner
} from '@amzn/awsui-components-react';
import {Domain, getBartLambdaConfig} from "src/config/BartLambdaConfig";
import {FullPageTitle} from "src/components/header/title";
import invokeDLQMoverWorkflowLambda from "src/lambda/dlqmoverWorkflowLambda/invoke-lambda";
import {InitDomain} from "src/context/domain/domainContext";
import {ReminderBlock} from "src/components/reminder-block";
import {Auth} from "aws-amplify";
import {DynamoDBClient} from "@aws-sdk/client-dynamodb";
import {DynamoDBDocumentClient, PutCommand} from "@aws-sdk/lib-dynamodb";
import {inputValidation} from "src/commons/input-validation";

export class DLQMover extends React.Component<{}, {commandValue: string, queueName: string,
     searched: boolean, isModalOpen: boolean, payload: any, message: any, ldap: string, reasoning: string}> {
    authObj: AuthService;

    constructor(props: any) {
        super(props);
        this.authObj = new AuthService();
        this.state = {
            commandValue: "GET_DLQ_COUNT",
            queueName: "FTOS_SQS",
            searched: false, // button clicked or not
            payload: "init", // input to call the lambda
            isModalOpen: false, // if the pop up is opened or not
            message: "",
            ldap: "",
            reasoning: "",
        };
        this.search = this.search.bind(this);
        this.callback = this.callback.bind(this);
        this.setqueueName = this.setqueueName.bind(this);
        this.setCommandValue = this.setCommandValue.bind(this);
        this.setOpenModal = this.setOpenModal.bind(this);
        this.setCloseModal = this.setCloseModal.bind(this);
    }

    componentDidMount() {
        const fetchData = async () => {
            // Run authentication
            await this.authObj.authenticate();
            this.setState({
                ldap: this.authObj.ldap
            });
        }
        fetchData(); // authenticate the bart website
    }

    setOpenModal() {
        this.setState({isModalOpen: true}) // pop up
    }
    setCloseModal() {
        this.setState({isModalOpen: false}) // pop up
    }

    setCommandValue(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({commandValue: value})
    }

    setqueueName(event: NonCancelableCustomEvent<any>) {
        const value = event.detail.selectedOption.value;
        this.setState({queueName: value})
    }

    setReasoning(event: NonCancelableCustomEvent<any>) {
        this.setState({['reasoning']: event.detail.value} as any)
    }

    search (domain: Domain){
        this.setCloseModal(); // pop up action item like are you sure you want to do this ?
        this.setState({
            searched: true, // to check if we clicked on yes button
            payload: "init"
        });
        
        // need to check with rohit to have the details in the audit history or not
        Auth.currentCredentials().then(credentials => {
            const client = new DynamoDBClient({
                credentials: Auth.essentialCredentials(credentials),
                region: getBartLambdaConfig(domain).awsRegion
            });

            const docClient = DynamoDBDocumentClient.from(client);
            const moment = require("moment");
            const ddbPut = async () => {
                const command = new PutCommand({
                    TableName: "BARTAuditHistoryTable",
                    Item: {
                        userId: this.authObj.userId,
                        requestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                        operationType: "DLQ Mover",
                        additionalInfo: `Command : ${this.state.commandValue}, Queue Name : ${this.state.queueName}`,
                        reasoning: this.state.reasoning,
                    }
                });
                await docClient.send(command);
            }
            ddbPut();
        })

        invokeDLQMoverWorkflowLambda(this.state.commandValue, this.state.queueName, domain, this.callback);
    }

    callback (response: any){ // based on the reponse 
        if (response === "invalid") {
            this.setState({
                ...this.state,
                payload: "invalid"
            });
        } else if (response === "open") {
            this.setState({
                ...this.state,
                payload: "open"
            });
        } else if (response === "error") {
            this.setState({
                ...this.state,
                payload: "error"
            })
        } else {
            this.setState({
                ...this.state,
                payload: "result",
                message: response,
            });
        }
    }

    render() {
        if (!(this.state.ldap.includes("bart-dlq") ||
            this.state.ldap.includes("bart-admin") ||
            this.state.ldap.includes("fuse-pascal"))) {
            return (
                <div>
                    <FullPageTitle title={'DLQ Mover Workflow'} description={'Move SQS DLQ messages to their Queue'}/>
                    <SpaceBetween size = "l" />
                    <Grid
                        gridDefinition={[
                            {colspan: {l: 7, xxs: 10}},
                        ]}
                    >
                        <Alert header="Only authorized users can perform a DLQ mover workflow!" type="error"></Alert>
                    </Grid>
                </div>
            )
        }
        let lis:string[]=[];
        if (this.state.payload === "result") {
            if(this.state.commandValue === "GET_DLQ_COUNT") {
                lis.push("Getting the DLQ message Count on " +this.state.queueName);
            } else {
                lis.push("Moving the DLQ message to FIFO queue on " +this.state.queueName);
            }
            lis.push("DLQ Mover response - " + this.state.message);
        }
        return (
            <InitDomain.Consumer>
                {context => (
                <div>
                    <FullPageTitle title={'DLQ Mover Workflow'} description={'Move SQS DLQ messages to their Queue'}/>
                    <SpaceBetween size="l">
                        <form onSubmit={event => event.preventDefault()}>
                            <Form
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button variant="primary"
                                                onClick={this.setOpenModal}>
                                            DLQ Mover Workflow
                                        </Button>
                                    </SpaceBetween>
                                }
                                errorIconAriaLabel="Error"
                            >
                                <SpaceBetween size="l">
                                    <Container
                                        id="origin-panel"
                                        className="custom-screenshot-hide"
                                        header={<Header variant="h2">DLQ Mover Workflow </Header>}>
                                            <SpaceBetween size="l">
                                                <FormField
                                                    label="Command"
                                                    description="Choose the command to perform the operation on queue"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                        <Select
                                                            data-testid="command-filter"
                                                            options={[
                                                                {
                                                                    "value" : "GET_DLQ_COUNT"
                                                                },
                                                                {
                                                                    "value" : "MOVE_DLQ_TOKENS"
                                                                },
                                                            ]}
                                                            selectedAriaLabel="Selected"
                                                            selectedOption={{
                                                                "value": this.state.commandValue || 'GET_DLQ_COUNT'
                                                            }}
                                                            onChange={(event) => this.setCommandValue(event)}
                                                            ariaDescribedby={''}
                                                            expandToViewport={true}
                                                        />
                                                </FormField>
                                                <FormField
                                                    label="Queue Name"
                                                    description="Choose Queue to perform the operation"
                                                    i18nStrings={{ errorIconAriaLabel: 'Error' }}>
                                                        <Select
                                                            data-testid="queueName-filter"
                                                            options={[
                                                                {
                                                                    "value" : "FTOS_SQS"
                                                                },
                                                                {
                                                                    "value" : "FTMS_SQS"
                                                                },
                                                                {
                                                                    "value" : "FBGS_NOTIFICATION_SQS"
                                                                },
                                                                {
                                                                    "value" : "FBGS_REDRIVE_SQS"
                                                                },
                                                            ]}
                                                            selectedAriaLabel="Selected"
                                                            selectedOption={{
                                                                "value": this.state.queueName || 'FTOS_SQS'
                                                            }}
                                                            onChange={(event) => this.setqueueName(event)}
                                                            ariaDescribedby={''}
                                                            expandToViewport={true}
                                                        />
                                                </FormField>
                                                {this.state.commandValue === "MOVE_DLQ_TOKENS" ?
                                                    <FormField
                                                        label="DLQ Mover Reasoning"
                                                        description="Why you are performing this dlq move"
                                                        i18nStrings={{ errorIconAriaLabel: 'Error' }}
                                                    >
                                                        <Input
                                                            placeholder="Please enter your reason to perform a DLQ move"
                                                            ariaRequired={true}
                                                            value={this.state.reasoning}
                                                            onChange={(event)=>this.setReasoning(event)}
                                                        />
                                                    </FormField>
                                                : null}
                                            </SpaceBetween>
                                    </Container>
                                </SpaceBetween>
                            </Form>
                            <Modal
                                visible={this.state.isModalOpen}
                                onDismiss={this.setCloseModal}
                                header={'DLQ Request'}
                                closeAriaLabel="Close dialog"
                                footer={
                                    <Box float="right">
                                        <SpaceBetween direction="horizontal" size="xs">
                                            {this.state.commandValue === "MOVE_DLQ_TOKENS" && inputValidation(this.state.reasoning) ?
                                                <Button variant="primary" onClick={this.setCloseModal}>Input DLQ Mover Reasoning</Button> :
                                                (<div>
                                                    <Button variant="link" onClick={this.setCloseModal}>Cancel</Button>
                                                    <Button variant="primary"
                                                            onClick={() => this.search(context.domain)}>Submit
                                                        Operation</Button>
                                                </div>)
                                            }
                                        </SpaceBetween>
                                    </Box>
                                }
                            >
                                <SpaceBetween size="m">
                                    <Box variant="span">
                                        {this.state.commandValue === "MOVE_DLQ_TOKENS" && inputValidation(this.state.reasoning) ?
                                            <Alert type="error" header="You must input your reason for moving DLQ."></Alert>
                                        : null}
                                        {this.state.commandValue === "MOVE_DLQ_TOKENS" && !inputValidation(this.state.reasoning) ? (
                                            <div>
                                                <Alert type="warning" header="Are you sure you want to move the messages from DLQ?"></Alert>
                                                <Alert type="info" header="Performs the action on DLQ queue."></Alert>
                                            </div>
                                        ) : null}
                                        {this.state.commandValue === "GET_DLQ_COUNT" ?
                                            <Alert type="info" header="Performs the action on DLQ queue."></Alert>
                                        : null}
                                    </Box>
                                </SpaceBetween>
                            </Modal>
                            {this.state.searched === true && this.state.payload === "init" ? (
                                <Spinner size = "large"></Spinner>
                            ) : null}
                            {this.state.payload === "NullPointerException" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="Please re-select the inputs!" type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "open" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="DLQ Mover operation cannot be performed right now." type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "error" ? (
                                <div className="alert-box">
                                    <Grid
                                        gridDefinition={[
                                            {colspan: {l: 7, xxs: 10}},
                                        ]}
                                    >
                                        <Alert header="DLQ Mover operation timed out, please try again!" type="error"></Alert>
                                    </Grid>
                                </div>
                            ) : null}
                            {this.state.payload === "result" ? (
                                <div id="dlqmover-result">
                                    <ReminderBlock title="Success!" lis={lis}/>
                                    <div style={{paddingLeft: '5px'}}>
                                    </div>
                                </div>
                            ) : null}
                        </form>
                    </SpaceBetween>
                </div>)}
            </InitDomain.Consumer>
        );
    }
}

export default DLQMover;
