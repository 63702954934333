export type Domain =
    'devo-FE' |
    'prod-EU' |
    'prod-US' |
    'prod-FE'

export const domains = {
    DEVOFE: 'devo-FE',
    PRODUS: 'prod-US',
    PRODEU: 'prod-EU',
    PRODFE: 'prod-FE',
}

interface LambdaConfig {
    domainId: Domain,
    awsRegion: string,
    transactionSearchLambdaARN: string,
    ardServiceLambdaARN: string,
    billingGatewayServiceAPIInvokeLambdaARN: string,
    billingGatewayServiceBulkRedriveWorkflowLambdaARN: string,
    bartDLQMoverLambdaARN:string,
}


const BartLambdaConfig: LambdaConfig[] = [
    {
        domainId: 'devo-FE',
        awsRegion: 'us-west-2',
        transactionSearchLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartTransactionSearchLambda-FE',
        ardServiceLambdaARN:'arn:aws:lambda:us-west-2:444552806502:function:FuseBartArdServiceLambda-FE',
        billingGatewayServiceAPIInvokeLambdaARN:'arn:aws:lambda:us-west-2:444552806502:function:FuseBartBillingGatewayServiceLambda-FE',
        billingGatewayServiceBulkRedriveWorkflowLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartFBGSBulkRedriveLambda-FE',
        bartDLQMoverLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartDLQMoverLambda-FE',
    },

    {
        domainId: 'prod-FE',
        awsRegion: 'us-west-2',
        transactionSearchLambdaARN: 'arn:aws:lambda:us-west-2:517990620071:function:FuseBartTransactionSearchLambda-FE',
        ardServiceLambdaARN:'arn:aws:lambda:us-west-2:517990620071:function:FuseBartArdServiceLambda-FE',
        billingGatewayServiceAPIInvokeLambdaARN:'arn:aws:lambda:us-west-2:517990620071:function:FuseBartBillingGatewayServiceLambda-FE',
        billingGatewayServiceBulkRedriveWorkflowLambdaARN: 'arn:aws:lambda:us-west-2:517990620071:function:FuseBartFBGSBulkRedriveLambda-FE',
        bartDLQMoverLambdaARN: 'arn:aws:lambda:us-west-2:517990620071:function:FuseBartDLQMoverLambda-FE',
    },

    {
        domainId: 'prod-US',
        awsRegion: 'us-east-1',
        transactionSearchLambdaARN: 'arn:aws:lambda:us-east-1:517990620071:function:FuseBartTransactionSearchLambda-NA',
        ardServiceLambdaARN:'arn:aws:lambda:us-east-1:517990620071:function:FuseBartArdServiceLambda-NA',
        billingGatewayServiceAPIInvokeLambdaARN:'arn:aws:lambda:us-east-1:517990620071:function:FuseBartBillingGatewayServiceLambda-NA',
        billingGatewayServiceBulkRedriveWorkflowLambdaARN: 'arn:aws:lambda:us-east-1:517990620071:function:FuseBartFBGSBulkRedriveLambda-NA',
        bartDLQMoverLambdaARN: 'arn:aws:lambda:us-east-1:517990620071:function:FuseBartDLQMoverLambda-NA',
    },

    {
        domainId: 'prod-EU',
        awsRegion: 'eu-west-1',
        transactionSearchLambdaARN: 'arn:aws:lambda:eu-west-1:517990620071:function:FuseBartTransactionSearchLambda-EU',
        ardServiceLambdaARN:'arn:aws:lambda:eu-west-1:517990620071:function:FuseBartArdServiceLambda-EU',
        billingGatewayServiceAPIInvokeLambdaARN:'arn:aws:lambda:eu-west-1:517990620071:function:FuseBartBillingGatewayServiceLambda-EU',
        billingGatewayServiceBulkRedriveWorkflowLambdaARN: 'arn:aws:lambda:eu-west-1:517990620071:function:FuseBartFBGSBulkRedriveLambda-EU',
        bartDLQMoverLambdaARN: 'arn:aws:lambda:eu-west-1:517990620071:function:FuseBartDLQMoverLambda-EU',
    },

];

export const getBartLambdaConfig = (domainId: Domain): LambdaConfig  => {
    return BartLambdaConfig.find(element => element.domainId === domainId) || {
        domainId: 'devo-FE',
        awsRegion: 'us-west-2',
        transactionSearchLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartTransactionSearchLambda-FE',
        ardServiceLambdaARN:'arn:aws:lambda:us-west-2:444552806502:function:FuseBartArdServiceLambda-FE',
        billingGatewayServiceAPIInvokeLambdaARN:'arn:aws:lambda:us-west-2:444552806502:function:FuseBartBillingGatewayServiceLambda-FE',
        billingGatewayServiceBulkRedriveWorkflowLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartFBGSBulkRedriveLambda-FE',
        bartDLQMoverLambdaARN: 'arn:aws:lambda:us-west-2:444552806502:function:FuseBartDLQMoverLambda-FE',
    };
}
