import React from 'react';
import Icon from '@amzn/awsui-components-react/polaris/icon';
import Link from '@amzn/awsui-components-react/polaris/link';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import { SeparatedList } from './separated-list';
import '../../styles/external-link-group.scss';
import { nanoid } from '@reduxjs/toolkit';

interface ExternalLinkItemProps {
    href: string;
    text: string;
}

interface ExternalLinkGroupProps {
    variant?: 'default' | 'container';
    header?: string;
    groupAriaLabel?: string;
    items: Array<ExternalLinkItemProps>;
}

const labelSuffix = 'Opens in a new tab';

/**
 *
 * @param root0
 * @param root0.href
 * @param root0.text
 */
function ExternalLinkItem({ href, text }: ExternalLinkItemProps) {
    return (
        <Link href={href} ariaLabel={`${text} ${labelSuffix}`} target="_blank">
            <div className="custom-text__proper-case">{text}</div>
        </Link>
    );
}

/**
 *
 * @param root0
 * @param root0.header
 * @param root0.groupAriaLabel
 * @param root0.items
 * @param root0.variant
 */
export function ExternalLinkGroup({
    header = 'Learn more',
    groupAriaLabel,
    items,
    variant = 'default',
}: ExternalLinkGroupProps) {
    const externalIcon = (
        <span role="img" aria-label="Icon external Link">
            <Icon name="external" size="inherit" />
        </span>
    );

    if (variant === 'container') {
        return (
            <Container
                header={
                    <Header>
                        <div className="custom-text__proper-case">
                            {header} {externalIcon}
                        </div>
                    </Header>
                }
            >
                <SeparatedList
                    ariaLabel={groupAriaLabel}
                    items={items.map((item) => (
                        <ExternalLinkItem key={nanoid()} href={item.href} text={item.text} />
                    ))}
                />
            </Container>
        );
    }

    return (
        <>
            <h3>
                {header} {externalIcon}
            </h3>
            <ul aria-label={groupAriaLabel}>
                {items.map((item) => (
                    <li key={nanoid()}>
                        <ExternalLinkItem href={item.href} text={item.text} />
                    </li>
                ))}
            </ul>
        </>
    );
}
